import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  key: 3
};
import { ButtonStateEnum } from '@/enums/ButtonStateEnum';

import { translate } from '/var/node/assets/js/utils/translate.ts';
export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonBootstrap',
  props: {
    onClick: {},
    defaultText: {
      "default": translate('messages', 'action.save')
    },
    buttonState: {
      "default": ButtonStateEnum.DEFAULT
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    successText: {
      "default": ''
    },
    errorText: {
      "default": translate('messages', 'error')
    }
  },
  setup: function setup(__props) {
    /**
     * This component abstracts an animated button (given the state, it will transition automatically)
     */
    return function (_ctx, _cache) {
      var _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
      return _openBlock(), _createElementBlock("button", {
        type: "button",
        "class": _normalizeClass(["btn btn-primary block", {
          disabled: _ctx.disabled
        }]),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.onClick();
        })
      }, [_ctx.buttonState === _unref(ButtonStateEnum).LOADING ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
        key: 0,
        "spinner-small": true
      })) : _ctx.buttonState === _unref(ButtonStateEnum).SUCCESS ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("i", {
        "class": "fas fa-check-circle mr-2"
      }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString(_ctx.successText), 1 /* TEXT */)])) : _ctx.buttonState === _unref(ButtonStateEnum).ERROR ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("i", {
        "class": "fas fa-exclamation-circle mr-2"
      }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString(_ctx.errorText), 1 /* TEXT */)])) : _ctx.buttonState === _unref(ButtonStateEnum).DEFAULT ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.defaultText), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
    };
  }
});