import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { ref, computed } from 'vue';
import { useThrottle } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
export default /*@__PURE__*/_defineComponent({
  __name: 'ApiCombobox',
  props: /*@__PURE__*/_mergeModels({
    queryKey: {},
    filterOptions: {
      type: Function,
      "default": function _default(options) {
        return options;
      }
    },
    mapOptions: {
      type: Function,
      "default": function _default(options) {
        return options;
      }
    },
    apiOptionTypeHint: {
      "default": undefined
    },
    name: {},
    label: {},
    size: {},
    placeholder: {},
    error: {},
    getOptionKey: {},
    getOptionLabel: {},
    getOptionIsDisabled: {},
    nullable: {
      type: Boolean
    }
  }, {
    "modelValue": {
      "default": null
    },
    "modelModifiers": {}
  }),
  emits: ["update:modelValue"],
  setup: function setup(__props) {
    var props = __props;
    var modelValue = _useModel(__props, "modelValue");
    var rawQuery = ref('');
    var query = useThrottle(rawQuery, 250, true);
    var queryKey = computed(function () {
      return typeof props.queryKey === 'function' ? props.queryKey(query.value) : props.queryKey;
    });
    var _useQuery = useQuery({
        queryKey: queryKey,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 1000 * 60 * 60,
        cacheTime: Infinity
      }),
      data = _useQuery.data;
    var options = computed(function () {
      var _data$value$hydraMem, _data$value;
      return props.mapOptions(props.filterOptions((_data$value$hydraMem = (_data$value = data.value) === null || _data$value === void 0 ? void 0 : _data$value['hydra:member']) !== null && _data$value$hydraMem !== void 0 ? _data$value$hydraMem : []));
    });
    var serverSideFiltering = computed(function () {
      var _data$value2, _data$value3, _data$value4;
      return typeof ((_data$value2 = data.value) === null || _data$value2 === void 0 ? void 0 : _data$value2['hydra:totalItems']) !== 'undefined' && ((_data$value3 = data.value) === null || _data$value3 === void 0 ? void 0 : _data$value3['hydra:totalItems']) > ((_data$value4 = data.value) === null || _data$value4 === void 0 ? void 0 : _data$value4['hydra:member'].length);
    });
    return function (_ctx, _cache) {
      var _component_Combobox = _resolveComponent("Combobox");
      return _openBlock(), _createBlock(_component_Combobox, _mergeProps({
        modelValue: modelValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return modelValue.value = $event;
        }),
        query: _unref(rawQuery),
        "onUpdate:query": _cache[1] || (_cache[1] = function ($event) {
          return _isRef(rawQuery) ? rawQuery.value = $event : null;
        }),
        options: _unref(options),
        "manual-filtering": _unref(serverSideFiltering)
      }, _ctx.$props), null, 16 /* FULL_PROPS */, ["modelValue", "query", "options", "manual-filtering"]);
    };
  }
});