import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["id"];
var _hoisted_2 = {
  "class": "invite-details"
};
var _hoisted_3 = {
  "class": "signer"
};
var _hoisted_4 = {
  "class": "email-infos"
};
var _hoisted_5 = {
  "class": "mb-0 px-4"
};
var _hoisted_6 = {
  "class": "p-6"
};
var _hoisted_7 = {
  "class": "form-group mb-0"
};
var _hoisted_8 = ["for", "innerHTML"];
var _hoisted_9 = ["id"];
var _hoisted_10 = {
  "class": "invalid-feedback"
};
var _hoisted_11 = ["disabled", "onClick"];
import Modal from '@/components/Modal.vue';

import { ref, computed, onMounted } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'SignerToInvite'
}), {}, {
  __name: 'SignerToInvite',
  props: {
    signer: {
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var initialText = ref('');
    var showModal = ref(false);
    var openModal = function openModal() {
      showModal.value = true;
    };
    var hasIndividualText = computed(function () {
      return props.signer.emailText !== initialText.value;
    });
    var isTextValid = computed(function () {
      return props.signer.emailText.length >= 20;
    });
    onMounted(function () {
      props.signer.emailText = props.signer.emailText.replaceAll('<br>', '\n');
      initialText.value = props.signer.emailText;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        id: "signer-invite-".concat(_ctx.signer.personId),
        "class": "signer-invite"
      }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h5", null, _toDisplayString(_ctx.signer.name), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString(_ctx.signer.email), 1 /* TEXT */)]), _cache[3] || (_cache[3] = _createElementVNode("div", {
        "class": "horizontal-divider"
      }, " ", -1 /* HOISTED */)), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', _unref(hasIndividualText) ? 'e_signature.individual_text' : 'e_signature.standard_text')), 1 /* TEXT */), _createElementVNode("a", {
        "class": "show",
        onClick: openModal
      }, _cache[2] || (_cache[2] = [_createElementVNode("i", {
        "class": "fa fa-pencil"
      }, null, -1 /* HOISTED */)]))])])], 8 /* PROPS */, _hoisted_1), _createVNode(Modal, {
        modelValue: _unref(showModal),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _isRef(showModal) ? showModal.value = $event : null;
        }),
        title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.individual_text'),
        "hide-close-button": true
      }, {
        footer: _withCtx(function (_ref) {
          var close = _ref.close;
          return [_createElementVNode("button", {
            type: "button",
            "class": _normalizeClass(["btn btn-primary", !_unref(isTextValid) ? 'disabled' : '']),
            disabled: !_unref(isTextValid),
            onClick: close
          }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'action.save')), 11 /* TEXT, CLASS, PROPS */, _hoisted_11)];
        }),
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("label", {
            "for": "individual_text_".concat(_ctx.signer.personId),
            innerHTML: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.individual_text_info', {
              '%name%': _ctx.signer.name
            })
          }, null, 8 /* PROPS */, _hoisted_8), _withDirectives(_createElementVNode("textarea", {
            id: "individual_text_".concat(_ctx.signer.personId),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.signer.emailText = $event;
            }),
            "class": _normalizeClass([!_unref(isTextValid) ? 'is-invalid' : '', "form-control"]),
            rows: "3"
          }, null, 10 /* CLASS, PROPS */, _hoisted_9), [[_vModelText, _ctx.signer.emailText]]), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.length_of_text_too_short')), 1 /* TEXT */)])])])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "title"])], 64 /* STABLE_FRAGMENT */);
    };
  }
}));