import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { cloneDeep } from 'lodash-es';
import FunctionAndSignatureForm from '@/components/personForm/FunctionAndSignatureForm.vue';

import { useVModel } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
import { watch, computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'JurPersonSignerForm',
  props: {
    modelValue: {},
    errors: {}
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var internalJurPersonSigner = useVModel(props, 'modelValue', emit, {
      clone: cloneDeep,
      deep: true,
      passive: true
    });
    var _useQuery = useQuery({
        queryKey: ['/api/jur_person_signers/function_and_signature_choices']
      }),
      functionAndSignatureChoices = _useQuery.data,
      loadingFunctions = _useQuery.isLoading;
    watch(functionAndSignatureChoices, function (newValue) {
      if (newValue !== undefined) {
        if (internalJurPersonSigner.value.functions === undefined) {
          internalJurPersonSigner.value.functions = newValue.defaultFunctions.map(function (f) {
            return {
              "function": f
            };
          });
          if (internalJurPersonSigner.value.functions.length === 0) {
            internalJurPersonSigner.value.functions.push({
              "function": ''
            });
          }
        }
        if (internalJurPersonSigner.value.signature === undefined) {
          internalJurPersonSigner.value.signature = newValue.defaultSignature;
        }
      }
    }, {
      immediate: true
    });
    var currentFunctions = computed({
      get: function get() {
        var _internalJurPersonSig, _internalJurPersonSig2;
        return (_internalJurPersonSig = (_internalJurPersonSig2 = internalJurPersonSigner.value.functions) === null || _internalJurPersonSig2 === void 0 ? void 0 : _internalJurPersonSig2.map(function (f) {
          return f["function"];
        })) !== null && _internalJurPersonSig !== void 0 ? _internalJurPersonSig : [];
      },
      set: function set(value) {
        internalJurPersonSigner.value.functions = value.map(function (functionText) {
          return {
            "function": functionText
          };
        });
      }
    });
    var possibleFunctions = computed(function () {
      var _functionAndSignature, _functionAndSignature2;
      return (_functionAndSignature = (_functionAndSignature2 = functionAndSignatureChoices.value) === null || _functionAndSignature2 === void 0 ? void 0 : _functionAndSignature2.functionChoices) !== null && _functionAndSignature !== void 0 ? _functionAndSignature : [];
    });
    var possibleSignatures = computed(function () {
      var _functionAndSignature3, _functionAndSignature4;
      return (_functionAndSignature3 = (_functionAndSignature4 = functionAndSignatureChoices.value) === null || _functionAndSignature4 === void 0 ? void 0 : _functionAndSignature4.signatureChoices) !== null && _functionAndSignature3 !== void 0 ? _functionAndSignature3 : [];
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(FunctionAndSignatureForm, {
        functions: _unref(currentFunctions),
        "onUpdate:functions": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(currentFunctions) ? currentFunctions.value = $event : null;
        }),
        signature: _unref(internalJurPersonSigner).signature,
        "onUpdate:signature": _cache[1] || (_cache[1] = function ($event) {
          return _unref(internalJurPersonSigner).signature = $event;
        }),
        "possible-functions": _unref(possibleFunctions),
        "possible-signatures": _unref(possibleSignatures),
        errors: _ctx.errors,
        loading: _unref(loadingFunctions)
      }, null, 8 /* PROPS */, ["functions", "signature", "possible-functions", "possible-signatures", "errors", "loading"]);
    };
  }
});