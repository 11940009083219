import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  "class": "group/table flow-root"
};
var _hoisted_2 = {
  "class": "overflow-x-auto"
};
var _hoisted_3 = {
  "class": "inline-block min-w-full align-middle"
};
var _hoisted_4 = {
  "class": "relative"
};
var _hoisted_5 = {
  "class": "overflow-hidden drop-shadow-lg"
};
var _hoisted_6 = {
  "class": "min-w-full divide-y-2 divide-gray-divider border-b border-b-gray-divider"
};
var _hoisted_7 = ["colSpan"];
var _hoisted_8 = {
  "class": "group relative inline-flex justify-between items-baseline"
};
var _hoisted_9 = ["aria-label", "onClick", "onKeydown"];
var _hoisted_10 = {
  "class": "ml-0.5 leading-none align-top inline-flex justify-center items-center aspect-square shrink-0 w-6 rounded text-gray transition-colors group-hover/button:bg-blue-dark/5"
};
var _hoisted_11 = {
  "class": "divide-y divide-gray-divider bg-white"
};
var _hoisted_12 = {
  "class": "hidden"
};
var _hoisted_13 = ["colSpan"];
var _hoisted_14 = {
  "class": "relative rounded-b-lg flex flex-wrap justify-between items-center gap-4 bg-white drop-shadow-lg px-7 py-4"
};
var _hoisted_15 = {
  "class": "flex items-center gap-x-4"
};
var _hoisted_16 = {
  "class": "text-base font-bold text-blue-dark pb-0 mb-0"
};
var _hoisted_17 = {
  "class": "w-4 aspect-square"
};
var _hoisted_18 = {
  "class": "flex items-center gap-1.5"
};
var _hoisted_19 = {
  "class": "space-x-1.5"
};
var _hoisted_20 = ["aria-label", "disabled"];
var _hoisted_21 = ["aria-label", "disabled"];
var _hoisted_22 = {
  "class": "flex items-center gap-2.5"
};
var _hoisted_23 = ["aria-label", "disabled"];
var _hoisted_24 = ["aria-label", "disabled", "onClick"];
var _hoisted_25 = ["aria-label", "disabled"];
var _hoisted_26 = {
  "class": "space-x-1.5"
};
var _hoisted_27 = ["aria-label", "disabled"];
var _hoisted_28 = ["aria-label", "disabled"];
var _hoisted_29 = ["value"];
import { FlexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useVueTable } from '@tanstack/vue-table';

import { computed, ref, toRefs, h, watch } from 'vue';
import { notEmpty } from '/var/node/assets/js/utils/helpers.ts';
export default /*@__PURE__*/_defineComponent({
  __name: 'TrusteeTable',
  props: /*@__PURE__*/_mergeModels({
    tableOptions: {},
    pageSizes: {
      "default": function _default() {
        return [10, 20, 50, 100];
      }
    },
    totalItems: {
      "default": undefined
    },
    isFetching: {
      type: Boolean,
      "default": false
    },
    compensateForContainerPadding: {
      type: Boolean,
      "default": true
    }
  }, {
    "sorting": {
      local: true,
      "default": function _default() {
        return [];
      }
    },
    "sortingModifiers": {},
    "columnFilters": {
      local: true,
      "default": function _default() {
        return [];
      }
    },
    "columnFiltersModifiers": {},
    "pagination": {
      local: true,
      "default": function _default() {
        return {
          pageIndex: 0,
          pageSize: 10
        };
      }
    },
    "paginationModifiers": {},
    "columnVisibility": {
      local: true
    },
    "columnVisibilityModifiers": {},
    "columnOrder": {
      local: true
    },
    "columnOrderModifiers": {}
  }),
  emits: /*@__PURE__*/_mergeModels(["update:table"], ["update:sorting", "update:columnFilters", "update:pagination", "update:columnVisibility", "update:columnOrder"]),
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var sorting = _useModel(__props, 'sorting');
    var columnFilters = _useModel(__props, 'columnFilters');
    var pagination = _useModel(__props, 'pagination');
    var pageSizeOptions = computed(function () {
      return props.pageSizes.map(function (pageSize) {
        return {
          id: pageSize
        };
      });
    });
    var paginationItems = ref([]);
    var columnVisibility = _useModel(__props, 'columnVisibility');
    var columnOrder = _useModel(__props, 'columnOrder');
    var _toRefs = toRefs(props),
      tableOptions = _toRefs.tableOptions;
    var table = useVueTable(_objectSpread(_objectSpread({
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      enableSortingRemoval: false,
      debugTable: true
    }, tableOptions.value), {}, {
      defaultColumn: _objectSpread({
        sortDescFirst: false,
        size: 9.5,
        minSize: 0,
        cell: function cell(_cell) {
          var _cell$column$columnDe, _cell$column$columnDe7, _cell$column$columnDe8;
          if ((_cell$column$columnDe = _cell.column.columnDef.meta) !== null && _cell$column$columnDe !== void 0 && _cell$column$columnDe.component) {
            var _cell$column$columnDe2, _cell$column$columnDe3, _cell$column$columnDe4, _cell$column$columnDe5, _cell$column$columnDe6;
            return h((_cell$column$columnDe2 = _cell.column.columnDef.meta) === null || _cell$column$columnDe2 === void 0 ? void 0 : _cell$column$columnDe2.component, {
              meta: typeof ((_cell$column$columnDe3 = _cell.column.columnDef.meta) === null || _cell$column$columnDe3 === void 0 ? void 0 : _cell$column$columnDe3.props) === 'function' ? (_cell$column$columnDe4 = _cell.column.columnDef.meta) === null || _cell$column$columnDe4 === void 0 ? void 0 : _cell$column$columnDe4.props(_cell) : (_cell$column$columnDe5 = (_cell$column$columnDe6 = _cell.column.columnDef.meta) === null || _cell$column$columnDe6 === void 0 ? void 0 : _cell$column$columnDe6.props) !== null && _cell$column$columnDe5 !== void 0 ? _cell$column$columnDe5 : undefined,
              value: _cell.getValue(),
              cell: _cell
            }, {
              "default": function _default() {
                return _cell.getValue();
              }
            });
          }
          return h('div', {
            title: _cell.getValue(),
            "class": ((_cell$column$columnDe7 = (_cell$column$columnDe8 = _cell.column.columnDef.meta) === null || _cell$column$columnDe8 === void 0 ? void 0 : _cell$column$columnDe8.truncate) !== null && _cell$column$columnDe7 !== void 0 ? _cell$column$columnDe7 : true) ? 'truncate' : ''
          }, {
            "default": function _default() {
              return _cell.getValue();
            }
          });
        }
      }, tableOptions.value.defaultColumn),
      state: _objectSpread({
        get sorting() {
          return sorting.value;
        },
        get columnFilters() {
          return columnFilters.value;
        },
        get pagination() {
          return pagination.value;
        },
        get columnVisibility() {
          return columnVisibility.value;
        },
        get columnOrder() {
          return columnOrder.value;
        }
      }, tableOptions.value.state),
      onSortingChange: function onSortingChange(updaterOrValue) {
        var _props$tableOptions$o, _props$tableOptions;
        sorting.value = typeof updaterOrValue === 'function' ? updaterOrValue(sorting.value) : updaterOrValue;
        (_props$tableOptions$o = (_props$tableOptions = props.tableOptions).onSortingChange) === null || _props$tableOptions$o === void 0 || _props$tableOptions$o.call(_props$tableOptions, sorting.value);
      },
      onColumnFiltersChange: function onColumnFiltersChange(updaterOrValue) {
        var _props$tableOptions$o2, _props$tableOptions2;
        columnFilters.value = typeof updaterOrValue === 'function' ? updaterOrValue(columnFilters.value) : updaterOrValue;
        (_props$tableOptions$o2 = (_props$tableOptions2 = props.tableOptions).onColumnFiltersChange) === null || _props$tableOptions$o2 === void 0 || _props$tableOptions$o2.call(_props$tableOptions2, columnFilters.value);
      },
      onPaginationChange: function onPaginationChange(updaterOrValue) {
        var _props$tableOptions$o3, _props$tableOptions3;
        pagination.value = typeof updaterOrValue === 'function' ? updaterOrValue(pagination.value) : updaterOrValue;
        (_props$tableOptions$o3 = (_props$tableOptions3 = props.tableOptions).onPaginationChange) === null || _props$tableOptions$o3 === void 0 || _props$tableOptions$o3.call(_props$tableOptions3, pagination.value);
      },
      onColumnVisibilityChange: function onColumnVisibilityChange(updaterOrValue) {
        var _columnVisibility$val, _props$tableOptions$o4, _props$tableOptions4;
        columnVisibility.value = typeof updaterOrValue === 'function' ? updaterOrValue((_columnVisibility$val = columnVisibility.value) !== null && _columnVisibility$val !== void 0 ? _columnVisibility$val : {}) : updaterOrValue;
        (_props$tableOptions$o4 = (_props$tableOptions4 = props.tableOptions).onColumnVisibilityChange) === null || _props$tableOptions$o4 === void 0 || _props$tableOptions$o4.call(_props$tableOptions4, columnVisibility.value);
      },
      onColumnOrderChange: function onColumnOrderChange(updaterOrValue) {
        var _columnOrder$value, _props$tableOptions$o5, _props$tableOptions5;
        columnOrder.value = typeof updaterOrValue === 'function' ? updaterOrValue((_columnOrder$value = columnOrder.value) !== null && _columnOrder$value !== void 0 ? _columnOrder$value : []) : updaterOrValue;
        (_props$tableOptions$o5 = (_props$tableOptions5 = props.tableOptions).onColumnOrderChange) === null || _props$tableOptions$o5 === void 0 || _props$tableOptions$o5.call(_props$tableOptions5, columnOrder.value);
      }
    }));
    if (!columnVisibility.value) {
      columnVisibility.value = Object.fromEntries(table.getAllLeafColumns().map(function (column) {
        return column.id ? [column.id, true] : null;
      }).filter(notEmpty));
    }
    if (!columnOrder.value) {
      columnOrder.value = table.getAllLeafColumns().filter(function (column) {
        var _column$columnDef$met;
        return ((_column$columnDef$met = column.columnDef.meta) === null || _column$columnDef$met === void 0 ? void 0 : _column$columnDef$met.enableOrdering) !== false;
      }).map(function (column) {
        return column.id;
      }).filter(notEmpty);
    }
    emit('update:table', table);
    watch(function () {
      return props.tableOptions;
    }, function (newOptions) {
      var _newOptions$pageCount;
      if (newOptions.debugTable || newOptions.debugAll) {
        console.log('Table.watch.tableOptions() : tableOptions have changed :=', newOptions);
      }
      var pages = Math.max((_newOptions$pageCount = newOptions.pageCount) !== null && _newOptions$pageCount !== void 0 ? _newOptions$pageCount : 1, 1);
      if (table.getState().pagination.pageIndex >= pages) {
        table.setPageIndex(pages - 1);
      }
      table.setOptions(function (oldOptions) {
        return _objectSpread(_objectSpread({}, oldOptions), newOptions);
      });
    }, {
      deep: true
    });
    watch([function () {
      return table.getState().pagination.pageIndex;
    }, function () {
      return props.tableOptions;
    }], function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 1),
        pageIndex = _ref3[0];
      var from = pageIndex;
      var to = from + 2;
      if (from <= 0) {
        to++;
      }
      if (to > table.getPageCount()) {
        from--;
      }
      var items = Array.from({
        length: to - from + 1
      }, function (_, i) {
        return from + i;
      });
      paginationItems.value = items.filter(function (item) {
        return item > 1 && item < table.getPageCount();
      });
    });
    return function (_ctx, _cache) {
      var _ctx$totalItems, _unref$find;
      var _component_SortIcon = _resolveComponent("SortIcon");
      var _component_ColumnFilter = _resolveComponent("ColumnFilter");
      var _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon");
      var _component_DropdownField = _resolveComponent("DropdownField");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("table", _hoisted_6, [_createElementVNode("thead", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(table).getHeaderGroups(), function (headerGroup) {
        return _openBlock(), _createElementBlock("tr", {
          key: headerGroup.id,
          "class": "[&:not(:last-child)]:hidden bg-blue-zebra"
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headerGroup.headers, function (header) {
          var _columnOrder$value2, _header$column$column, _header$column$column2, _columnOrder$value3, _columnOrder$value4, _header$column$column3, _header$column$column4, _columnOrder$value5, _header$column$column5, _header$column$column6, _columnOrder$value6, _columnOrder$value7, _header$column$column7, _header$column$column8;
          return _openBlock(), _createElementBlock("th", {
            key: header.id,
            colSpan: header.colSpan,
            "class": _normalizeClass(["pl-0 py-4 first:pl-8 last:pr-8 text-left align-top", [header.column.parent ? 'pr-2.5' : 'pr-10']]),
            style: _normalizeStyle([header.column.parent ? {
              minWidth: ((_columnOrder$value2 = columnOrder.value) === null || _columnOrder$value2 === void 0 ? void 0 : _columnOrder$value2.indexOf(header.id)) === 0 ? "".concat(((_header$column$column = header.column.columnDef.minSize) !== null && _header$column$column !== void 0 ? _header$column$column : 0) + 0.625 + 2.5, "rem") : "".concat(((_header$column$column2 = header.column.columnDef.minSize) !== null && _header$column$column2 !== void 0 ? _header$column$column2 : 0) + 0.625, "rem"),
              width: ((_columnOrder$value3 = columnOrder.value) === null || _columnOrder$value3 === void 0 ? void 0 : _columnOrder$value3.indexOf(header.id)) === 0 ? "".concat(header.getSize() + 0.625 + 2.5, "rem") : "".concat(header.getSize() + 0.625, "rem"),
              maxWidth: ((_columnOrder$value4 = columnOrder.value) === null || _columnOrder$value4 === void 0 ? void 0 : _columnOrder$value4.indexOf(header.id)) === 0 ? "".concat(((_header$column$column3 = header.column.columnDef.maxSize) !== null && _header$column$column3 !== void 0 ? _header$column$column3 : 0) + 0.625 + 2.5, "rem") : "".concat(((_header$column$column4 = header.column.columnDef.maxSize) !== null && _header$column$column4 !== void 0 ? _header$column$column4 : 0) + 0.625, "rem")
            } : {
              minWidth: ((_columnOrder$value5 = columnOrder.value) === null || _columnOrder$value5 === void 0 ? void 0 : _columnOrder$value5.indexOf(header.id)) === 0 ? "".concat(((_header$column$column5 = header.column.columnDef.minSize) !== null && _header$column$column5 !== void 0 ? _header$column$column5 : 0) + 2.5 + 2, "rem") : "".concat(((_header$column$column6 = header.column.columnDef.minSize) !== null && _header$column$column6 !== void 0 ? _header$column$column6 : 0) + 2.5, "rem"),
              width: ((_columnOrder$value6 = columnOrder.value) === null || _columnOrder$value6 === void 0 ? void 0 : _columnOrder$value6.indexOf(header.id)) === 0 ? "".concat(header.getSize() + 2.5 + 2, "rem") : "".concat(header.getSize() + 2.5, "rem"),
              maxWidth: ((_columnOrder$value7 = columnOrder.value) === null || _columnOrder$value7 === void 0 ? void 0 : _columnOrder$value7.indexOf(header.id)) === 0 ? "".concat(((_header$column$column7 = header.column.columnDef.maxSize) !== null && _header$column$column7 !== void 0 ? _header$column$column7 : 0) + 2.5 + 2, "rem") : "".concat(((_header$column$column8 = header.column.columnDef.maxSize) !== null && _header$column$column8 !== void 0 ? _header$column$column8 : 0) + 2.5, "rem")
            }])
          }, [!header.isPlaceholder ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createElementVNode("div", _hoisted_8, [header.column.getCanSort() ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            "aria-label": header.column.getNextSortingOrder() === 'asc' ? ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.sort.asc') : header.column.getNextSortingOrder() === 'desc' ? ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.sort.desc') : ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.sort.reset'),
            "class": "group/button text-left inline-flex justify-between items-baseline",
            onClick: function onClick($event) {
              var _header$column$getTog;
              return (_header$column$getTog = header.column.getToggleSortingHandler()) === null || _header$column$getTog === void 0 ? void 0 : _header$column$getTog($event);
            },
            onKeydown: [_withKeys(_withModifiers(function ($event) {
              return header.column.toggleSorting(false);
            }, ["prevent"]), ["up"]), _withKeys(_withModifiers(function ($event) {
              return header.column.toggleSorting(true);
            }, ["prevent"]), ["down"])]
          }, [_createVNode(_unref(FlexRender), {
            render: header.column.columnDef.header,
            props: header.getContext()
          }, null, 8 /* PROPS */, ["render", "props"]), _createElementVNode("span", _hoisted_10, [_createVNode(_component_SortIcon, {
            order: header.column.getIsSorted()
          }, null, 8 /* PROPS */, ["order"])])], 40 /* PROPS, NEED_HYDRATION */, _hoisted_9)) : (_openBlock(), _createBlock(_unref(FlexRender), {
            key: 1,
            render: header.column.columnDef.header,
            props: header.getContext()
          }, null, 8 /* PROPS */, ["render", "props"]))]), header.column.getCanFilter() ? (_openBlock(), _createBlock(_component_ColumnFilter, {
            key: 0,
            "model-value": header.column.getFilterValue(),
            header: header,
            "class": "mt-3",
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return header.column.setFilterValue($event);
            }
          }, null, 8 /* PROPS */, ["model-value", "header", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 14 /* CLASS, STYLE, PROPS */, _hoisted_7);
        }), 128 /* KEYED_FRAGMENT */))]);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("tbody", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(table).getRowModel().rows, function (row) {
        return _openBlock(), _createElementBlock("tr", {
          key: row.id,
          "class": "even:bg-blue-zebra"
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.getVisibleCells(), function (cell) {
          var _columnOrder$value8, _cell$column$columnDe9, _cell$column$columnDe10, _columnOrder$value9, _columnOrder$value10, _cell$column$columnDe11, _cell$column$columnDe12, _columnOrder$value11, _cell$column$columnDe13, _cell$column$columnDe14, _columnOrder$value12, _columnOrder$value13, _cell$column$columnDe15, _cell$column$columnDe16;
          return _openBlock(), _createElementBlock("td", {
            key: cell.id,
            "class": _normalizeClass(["pl-0 h-16 first:pl-8 last:pr-8", [cell.column.parent ? 'pr-2.5' : 'pr-10']]),
            style: _normalizeStyle([cell.column.parent ? {
              minWidth: ((_columnOrder$value8 = columnOrder.value) === null || _columnOrder$value8 === void 0 ? void 0 : _columnOrder$value8.indexOf(cell.column.id)) === 0 ? "".concat(((_cell$column$columnDe9 = cell.column.columnDef.minSize) !== null && _cell$column$columnDe9 !== void 0 ? _cell$column$columnDe9 : 0) + 0.625 + 2.5, "rem") : "".concat(((_cell$column$columnDe10 = cell.column.columnDef.minSize) !== null && _cell$column$columnDe10 !== void 0 ? _cell$column$columnDe10 : 0) + 0.625, "rem"),
              width: ((_columnOrder$value9 = columnOrder.value) === null || _columnOrder$value9 === void 0 ? void 0 : _columnOrder$value9.indexOf(cell.column.id)) === 0 ? "".concat(cell.column.getSize() + 0.625 + 2.5, "rem") : "".concat(cell.column.getSize() + 0.625, "rem"),
              maxWidth: ((_columnOrder$value10 = columnOrder.value) === null || _columnOrder$value10 === void 0 ? void 0 : _columnOrder$value10.indexOf(cell.column.id)) === 0 ? "".concat(((_cell$column$columnDe11 = cell.column.columnDef.maxSize) !== null && _cell$column$columnDe11 !== void 0 ? _cell$column$columnDe11 : 0) + 0.625 + 2.5, "rem") : "".concat(((_cell$column$columnDe12 = cell.column.columnDef.maxSize) !== null && _cell$column$columnDe12 !== void 0 ? _cell$column$columnDe12 : 0) + 0.625, "rem")
            } : {
              minWidth: ((_columnOrder$value11 = columnOrder.value) === null || _columnOrder$value11 === void 0 ? void 0 : _columnOrder$value11.indexOf(cell.column.id)) === 0 ? "".concat(((_cell$column$columnDe13 = cell.column.columnDef.minSize) !== null && _cell$column$columnDe13 !== void 0 ? _cell$column$columnDe13 : 0) + 2.5 + 2, "rem") : "".concat(((_cell$column$columnDe14 = cell.column.columnDef.minSize) !== null && _cell$column$columnDe14 !== void 0 ? _cell$column$columnDe14 : 0) + 2.5, "rem"),
              width: ((_columnOrder$value12 = columnOrder.value) === null || _columnOrder$value12 === void 0 ? void 0 : _columnOrder$value12.indexOf(cell.column.id)) === 0 ? "".concat(cell.column.getSize() + 2.5 + 2, "rem") : "".concat(cell.column.getSize() + 2.5, "rem"),
              maxWidth: ((_columnOrder$value13 = columnOrder.value) === null || _columnOrder$value13 === void 0 ? void 0 : _columnOrder$value13.indexOf(cell.column.id)) === 0 ? "".concat(((_cell$column$columnDe15 = cell.column.columnDef.maxSize) !== null && _cell$column$columnDe15 !== void 0 ? _cell$column$columnDe15 : 0) + 2.5 + 2, "rem") : "".concat(((_cell$column$columnDe16 = cell.column.columnDef.maxSize) !== null && _cell$column$columnDe16 !== void 0 ? _cell$column$columnDe16 : 0) + 2.5, "rem")
            }])
          }, [_createVNode(_unref(FlexRender), {
            render: cell.column.columnDef.cell,
            props: cell.getContext()
          }, null, 8 /* PROPS */, ["render", "props"])], 6 /* CLASS, STYLE */);
        }), 128 /* KEYED_FRAGMENT */))]);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("tfoot", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(table).getFooterGroups(), function (footerGroup) {
        return _openBlock(), _createElementBlock("tr", {
          key: footerGroup.id
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(footerGroup.headers, function (header) {
          return _openBlock(), _createElementBlock("th", {
            key: header.id,
            colSpan: header.colSpan
          }, [!header.isPlaceholder ? (_openBlock(), _createBlock(_unref(FlexRender), {
            key: 0,
            render: header.column.columnDef.footer,
            props: header.getContext()
          }, null, 8 /* PROPS */, ["render", "props"])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_13);
        }), 128 /* KEYED_FRAGMENT */))]);
      }), 128 /* KEYED_FRAGMENT */))])])])])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("p", _hoisted_16, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.results')) + ": " + _toDisplayString((_ctx$totalItems = _ctx.totalItems) !== null && _ctx$totalItems !== void 0 ? _ctx$totalItems : _unref(table).getFilteredRowModel().rows.length), 1 /* TEXT */), _createElementVNode("div", _hoisted_17, [_createVNode(_Transition, {
        name: "appear",
        persisted: ""
      }, {
        "default": _withCtx(function () {
          return [_withDirectives(_createVNode(_component_FontAwesomeIcon, {
            icon: ['far', 'spinner-third'],
            spin: "",
            "class": "block"
          }, null, 512 /* NEED_PATCH */), [[_vShow, _ctx.isFetching]])];
        }),
        _: 1 /* STABLE */
      })])]), _unref(tableOptions).showPagination !== false ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("button", {
        type: "button",
        "aria-label": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.pagination.open_first'),
        disabled: !_unref(table).getCanPreviousPage(),
        "class": "inline-flex justify-center items-center aspect-square w-6 rounded transition-colors enabled:hover:bg-blue-dark/5 disabled:opacity-25",
        onClick: _cache[0] || (_cache[0] = function () {
          return _unref(table).setPageIndex(0);
        })
      }, [_createVNode(_component_FontAwesomeIcon, {
        icon: ['far', 'chevrons-left'],
        size: "sm"
      })], 8 /* PROPS */, _hoisted_20), _createElementVNode("button", {
        type: "button",
        "aria-label": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.pagination.open_prev'),
        disabled: !_unref(table).getCanPreviousPage(),
        "class": "inline-flex justify-center items-center aspect-square shrink-0 w-6 rounded transition-colors enabled:hover:bg-blue-dark/5 disabled:opacity-25",
        onClick: _cache[1] || (_cache[1] = function () {
          return _unref(table).previousPage();
        })
      }, [_createVNode(_component_FontAwesomeIcon, {
        icon: ['far', 'chevron-left'],
        size: "sm"
      })], 8 /* PROPS */, _hoisted_21)]), _createElementVNode("div", _hoisted_22, [_unref(table).getPageCount() >= 1 ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: "button",
        "aria-label": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.pagination.open_page', {
          $page: 1
        }),
        disabled: 0 === _unref(table).getState().pagination.pageIndex,
        "class": _normalizeClass([[{
          'font-bold': 0 === _unref(table).getState().pagination.pageIndex
        }], "text-sm inline-flex justify-center items-center aspect-square w-8 border-1.5 border-blue-dark rounded font-semibold transition-colors enabled:hover:bg-blue-dark/5 disabled:bg-blue-dark disabled:text-white"]),
        onClick: _cache[2] || (_cache[2] = function () {
          return _unref(table).setPageIndex(0);
        })
      }, _toDisplayString(1), 10 /* CLASS, PROPS */, _hoisted_23)) : _createCommentVNode("v-if", true), _unref(table).getPageCount() > 4 && _unref(table).getState().pagination.pageIndex + 1 > 3 ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
        key: 1,
        icon: ['far', 'ellipsis'],
        "class": "self-end"
      })) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paginationItems), function (page) {
        return _openBlock(), _createElementBlock("button", {
          key: page,
          type: "button",
          "aria-label": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.pagination.open_page', {
            $page: page
          }),
          disabled: page - 1 === _unref(table).getState().pagination.pageIndex,
          "class": _normalizeClass([[{
            'font-bold': page - 1 === _unref(table).getState().pagination.pageIndex
          }, page < 100 ? 'text-sm' : page < 10000 ? 'text-xs' : 'text-2xs'], "inline-flex justify-center items-center aspect-square w-8 border-1.5 border-blue-dark rounded font-semibold transition-colors enabled:hover:bg-blue-dark/5 disabled:bg-blue-dark disabled:text-white"]),
          onClick: function onClick() {
            return _unref(table).setPageIndex(page - 1);
          }
        }, _toDisplayString(page), 11 /* TEXT, CLASS, PROPS */, _hoisted_24);
      }), 128 /* KEYED_FRAGMENT */)), _withDirectives(_createVNode(_component_FontAwesomeIcon, {
        icon: ['far', 'ellipsis'],
        "class": "self-end"
      }, null, 512 /* NEED_PATCH */), [[_vShow, _unref(table).getPageCount() > 4 && _unref(table).getState().pagination.pageIndex + 1 < _unref(table).getPageCount() - 2]]), _unref(table).getPageCount() > 1 ? (_openBlock(), _createElementBlock("button", {
        key: 2,
        type: "button",
        "aria-label": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.pagination.open_page', {
          $page: _unref(table).getPageCount()
        }),
        disabled: _unref(table).getPageCount() - 1 === _unref(table).getState().pagination.pageIndex,
        "class": _normalizeClass([[{
          'font-bold': _unref(table).getPageCount() - 1 === _unref(table).getState().pagination.pageIndex
        }, _unref(table).getPageCount() < 100 ? 'text-sm' : _unref(table).getPageCount() < 10000 ? 'text-xs' : 'text-2xs'], "inline-flex justify-center items-center aspect-square w-8 border-1.5 border-blue-dark rounded font-semibold transition-colors enabled:hover:bg-blue-dark/5 disabled:bg-blue-dark disabled:text-white"]),
        onClick: _cache[3] || (_cache[3] = function () {
          return _unref(table).setPageIndex(_unref(table).getPageCount() - 1);
        })
      }, _toDisplayString(_unref(table).getPageCount()), 11 /* TEXT, CLASS, PROPS */, _hoisted_25)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_26, [_createElementVNode("button", {
        type: "button",
        "aria-label": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.pagination.open_next'),
        disabled: !_unref(table).getCanNextPage(),
        "class": "inline-flex justify-center items-center aspect-square w-6 rounded transition-colors enabled:hover:bg-blue-dark/5 disabled:opacity-25",
        onClick: _cache[4] || (_cache[4] = function () {
          return _unref(table).nextPage();
        })
      }, [_createVNode(_component_FontAwesomeIcon, {
        icon: ['far', 'chevron-right'],
        size: "sm"
      })], 8 /* PROPS */, _hoisted_27), _createElementVNode("button", {
        type: "button",
        "aria-label": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.pagination.open_last'),
        disabled: !_unref(table).getCanNextPage(),
        "class": "inline-flex justify-center items-center aspect-square shrink-0 w-6 rounded transition-colors enabled:hover:bg-blue-dark/5 disabled:opacity-25",
        onClick: _cache[5] || (_cache[5] = function () {
          return _unref(table).setPageIndex(_unref(table).getPageCount() - 1);
        })
      }, [_createVNode(_component_FontAwesomeIcon, {
        icon: ['far', 'chevrons-right'],
        size: "sm"
      })], 8 /* PROPS */, _hoisted_28)])]), _createElementVNode("div", null, [_createVNode(_component_DropdownField, {
        "model-value": (_unref$find = _unref(pageSizeOptions).find(function (o) {
          return o.id === _unref(table).getState().pagination.pageSize;
        })) === null || _unref$find === void 0 ? void 0 : _unref$find.id,
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages.table.results_per_page'),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function (size) {
          _unref(table).setPageSize(size);
        })
      }, {
        options: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageSizeOptions), function (pageSize) {
            return _openBlock(), _createElementBlock("option", {
              key: pageSize.id,
              value: pageSize.id
            }, _toDisplayString(pageSize.id), 9 /* TEXT, PROPS */, _hoisted_29);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model-value", "label"])])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)])]);
    };
  }
});