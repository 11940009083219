function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  "class": "upper"
};
var _hoisted_2 = {
  "class": "flex flex-row items-center"
};
var _hoisted_3 = {
  "class": "me-4"
};
var _hoisted_4 = ["href"];
var _hoisted_5 = {
  "class": "flex justify-center"
};
var _hoisted_6 = ["data-bs-target", "aria-controls"];
var _hoisted_7 = ["id"];
var _hoisted_8 = {
  "class": "lower"
};
var _hoisted_9 = {
  "class": "mb-1"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = {
  key: 0,
  "class": "text-danger"
};
var _hoisted_12 = {
  key: 1,
  "class": "mb-0"
};
import { formatDateTime, translate } from '@/js/frontend/filters';

import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'EsignCard'
}), {}, {
  __name: 'EsignCard',
  props: {
    process: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var totalSignatures = computed(function () {
      return props.process.eSigDocs.reduce(function (acc, doc) {
        return acc + doc.totalSigners;
      }, 0);
    });
    var currentSignatures = computed(function () {
      return props.process.eSigDocs.reduce(function (acc, doc) {
        return acc + doc.totalSignedSigners;
      }, 0);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(_ctx.process.name), 1 /* TEXT */), _createElementVNode("h5", null, _toDisplayString(_unref(translate)('process', "company_process.type.".concat(_ctx.process.type))), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(_unref(translate)('messages', 'successful_esig', {
        '%currentSignatures%': _unref(currentSignatures).toString(),
        '%totalSignatures%': _unref(totalSignatures).toString()
      })), 1 /* TEXT */), _createElementVNode("a", {
        "class": "card-layout",
        href: "/process/".concat(_ctx.process.id)
      }, _cache[0] || (_cache[0] = [_createElementVNode("i", {
        "class": "far fa-eye"
      }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_4)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("h5", {
        "class": "btn btn-primary btn-small position-absolute collapsible collapsed",
        "data-bs-toggle": "collapse",
        "data-bs-target": "#process-".concat(_ctx.process.id),
        "aria-expanded": "false",
        "aria-controls": "#process-".concat(_ctx.process.id),
        type: "button",
        style: {
          "margin-top": "-0.6rem",
          "padding": "0.1rem 0.3rem"
        }
      }, _cache[1] || (_cache[1] = [_createElementVNode("i", {
        "class": "far fa-chevron-down"
      }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_6)]), _createElementVNode("div", {
        id: "process-".concat(_ctx.process.id),
        "class": "collapse"
      }, [_createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.process.eSigDocs, function (doc, index) {
        return _openBlock(), _createElementBlock("div", {
          key: doc,
          "class": "flex flex-row justify-between"
        }, [_createElementVNode("div", {
          "class": _normalizeClass(index === _ctx.process.eSigDocs.length - 1 ? '' : 'mb-3')
        }, [_createElementVNode("h6", _hoisted_9, _toDisplayString(_unref(translate)('document', "type.".concat(doc.documentType))), 1 /* TEXT */), doc.initialisationDate ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(formatDateTime)(doc.initialisationDate, true)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), doc.signingDeclined ? (_openBlock(), _createElementBlock("h5", _hoisted_11, _toDisplayString(_unref(translate)('process', 'e_signature_signing_phase.signing_declined')), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_unref(translate)('process', 'e_signature_signing_phase.documents_text', {
          '%numberOfPeople%': doc.totalSignedSigners,
          '%totalNumberOfPeople%': doc.totalSigners
        })), 1 /* TEXT */))]);
      }), 128 /* KEYED_FRAGMENT */))])], 8 /* PROPS */, _hoisted_7)], 64 /* STABLE_FRAGMENT */);
    };
  }
}));