import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "flex flex-col"
};
var _hoisted_2 = {
  "class": "self-center"
};
import ImageUpload from '@/components/natPersonForm/ImageUpload.vue';
import ImageUploadType from '@/enums/ImageUploadType';
import { getLocalizedUrl } from '@/js/frontend/helpers';

import { ref, computed, onMounted } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'InheritanceUpload'
}), {}, {
  __name: 'InheritanceUpload',
  props: {
    isInheritanceCertificateUpload: {
      type: Boolean,
      "default": true
    },
    token: {
      "default": ''
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var shouldBeMounted = ref(true);
    var type = computed(function () {
      return props.isInheritanceCertificateUpload ? ImageUploadType.INHERITANCE_CERTIFICATE : ImageUploadType.EXECUTOR_IDENTIFICATION;
    });
    var translationKey = computed(function () {
      return props.isInheritanceCertificateUpload ? 'info.inheritance_certificate' : 'info.executor_identification';
    });
    var getUrl = function getUrl(action) {
      return getLocalizedUrl("/person/public/image/".concat(action, "/").concat(props.token, "?imageType=").concat(type.value));
    };
    onMounted(function () {
      $('#main_modal').one('hidden.bs.modal', function () {
        shouldBeMounted.value = false;
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h5", _hoisted_2, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', _unref(translationKey) + '.title')), 1 /* TEXT */), _unref(shouldBeMounted) ? (_openBlock(), _createBlock(ImageUpload, {
        key: 0,
        title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', _unref(translationKey) + '.short'),
        "get-url-with-parameters": getUrl('get'),
        "upload-url-with-parameters": getUrl('upload'),
        "delete-url-with-parameters": getUrl('delete'),
        "is-inheritance-upload": true
      }, null, 8 /* PROPS */, ["title", "get-url-with-parameters", "upload-url-with-parameters", "delete-url-with-parameters"])) : _createCommentVNode("v-if", true)]);
    };
  }
}));