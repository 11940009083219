import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { computed, resolveComponent } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'DynLink',
  props: {
    to: {},
    forceATag: {
      type: Boolean
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var isLink = computed(function () {
      return props.to;
    });
    var isAbsoluteLink = computed(function () {
      return isLink.value && typeof props.to === 'string' && /^https?:\/\//i.test(props.to);
    });
    // determine whether to load RouterLink, a or button
    var buttonAOrRouterLink = computed(function () {
      if (isLink.value) {
        if (isAbsoluteLink.value || props.forceATag) {
          return 'a';
        }
        return resolveComponent('RouterLink');
      }
      return 'button';
    });
    var linkProps = computed(function () {
      if (isLink.value) {
        if (isAbsoluteLink.value || props.forceATag) {
          return {
            href: props.to
          };
        }
        return {
          to: props.to
        };
      }
      return {};
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_resolveDynamicComponent(_unref(buttonAOrRouterLink)), _normalizeProps(_guardReactiveProps(_unref(linkProps))), {
        "default": _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3 /* FORWARDED */
      }, 16 /* FULL_PROPS */);
    };
  }
});