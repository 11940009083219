import { getFirstError as __unimport_getFirstError } from '/var/node/assets/js/utils/validation.ts';
import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  "class": "row"
};
var _hoisted_2 = {
  "class": "col"
};
var _hoisted_3 = {
  "class": "col"
};
var _hoisted_4 = ["value"];
var _hoisted_5 = {
  key: 0,
  "class": "row"
};
var _hoisted_6 = {
  "class": "col"
};
var _hoisted_7 = ["value"];
var _hoisted_8 = {
  "class": "col"
};
var _hoisted_9 = ["value"];
import { cloneDeep } from 'lodash-es';
import { keepPreviousData } from '@tanstack/vue-query';
import TextField from '@/components/TextField.vue';
import DropdownField from '@/components/DropdownField.vue';

import { useVModel, useDebounce } from '@vueuse/core';
import { ref, computed, watch, nextTick } from 'vue';
import { useQuery } from '@tanstack/vue-query';
export default /*@__PURE__*/_defineComponent({
  __name: 'Seat',
  props: {
    isSwitzerland: {
      type: Boolean
    },
    errors: {
      "default": undefined
    },
    seatAsJson: {
      "default": '{}'
    },
    modelValue: {
      "default": function _default() {
        return {
          plz: '',
          ort: '',
          sitz: '',
          kanton: ''
        };
      }
    },
    translationDomain: {
      "default": 'nat_person'
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var model = useVModel(props, 'modelValue', emit, {
      clone: cloneDeep,
      deep: true,
      passive: true
    });
    var ortOptions = ref([]);
    var sitzOptions = ref([]);
    var kantonOptions = ref([]);
    var formPrefix = ref('');
    var ortUrl = computed(function () {
      var _model$value, _model$value2;
      if (!((_model$value = model.value) !== null && _model$value !== void 0 && _model$value.plz)) return null;
      return "/de/plz/request?plz=".concat((_model$value2 = model.value) === null || _model$value2 === void 0 ? void 0 : _model$value2.plz);
    });
    var debouncedOrtUrl = useDebounce(ortUrl, 500);
    var enableOrtValuesQuery = computed(function () {
      return props.isSwitzerland && debouncedOrtUrl.value !== null;
    });
    var ortValuesQuery = useQuery({
      queryKey: [debouncedOrtUrl],
      placeholderData: keepPreviousData,
      enabled: enableOrtValuesQuery
    });
    var sitzUrl = computed(function () {
      var _model$value3, _model$value4;
      return "/de/plz/request?plz=".concat((_model$value3 = model.value) === null || _model$value3 === void 0 ? void 0 : _model$value3.plz, "&ort=").concat((_model$value4 = model.value) === null || _model$value4 === void 0 ? void 0 : _model$value4.ort);
    });
    var sitzValuesQuery = useQuery({
      queryKey: [sitzUrl],
      enabled: false // lazy loading, this query is called only if isSwitzerland AND ort was fetched
    });
    watch(ortValuesQuery.data, function (d) {
      if (d !== undefined) {
        ortOptions.value = d.result.map(function (result) {
          return result.ort;
        });
        if (props.isSwitzerland && d.result.length > 0) {
          var _model$value$ort;
          if (!ortOptions.value.includes((_model$value$ort = model.value.ort) !== null && _model$value$ort !== void 0 ? _model$value$ort : '')) {
            var _ortOptions$value = _slicedToArray(ortOptions.value, 1);
            model.value.ort = _ortOptions$value[0];
          }
          sitzValuesQuery.refetch();
        }
      }
    }, {
      immediate: true
    });
    watch(sitzValuesQuery.data, function (d) {
      if (d !== undefined) {
        sitzOptions.value = d.result.map(function (result) {
          return result.seat;
        });
        // make values for kanton unique
        kantonOptions.value = _toConsumableArray(new Set(d.result.map(function (result) {
          return result.kanton;
        })));
        if (d.result.length > 0) {
          var _model$value$sitz, _model$value$kanton;
          var _model$value5 = model.value,
            sitz = _model$value5.sitz,
            kanton = _model$value5.kanton;
          if (!sitzOptions.value.includes((_model$value$sitz = model.value.sitz) !== null && _model$value$sitz !== void 0 ? _model$value$sitz : '')) {
            var _sitzOptions$value = _slicedToArray(sitzOptions.value, 1);
            sitz = _sitzOptions$value[0];
          }
          if (!kantonOptions.value.includes((_model$value$kanton = model.value.kanton) !== null && _model$value$kanton !== void 0 ? _model$value$kanton : '')) {
            var _kantonOptions$value = _slicedToArray(kantonOptions.value, 1);
            kanton = _kantonOptions$value[0];
          }
          // because of the useQuery cache this watcher might be executed in the same tick as the watcher before and this breaks useVModel updates
          nextTick(function () {
            model.value = _objectSpread(_objectSpread({}, model.value), {}, {
              sitz: sitz,
              kanton: kanton
            });
          });
        }
      }
    }, {
      deep: true,
      immediate: true
    });
    var setNewOrt = function setNewOrt(newOrt) {
      var _model$value6;
      if (props.isSwitzerland && typeof newOrt === 'string' && newOrt !== ((_model$value6 = model.value) === null || _model$value6 === void 0 ? void 0 : _model$value6.ort)) {
        model.value = _objectSpread(_objectSpread({}, model.value), {}, {
          ort: newOrt
        });
        sitzValuesQuery.refetch();
      }
    };
    var setNewPlz = function setNewPlz(newPlz) {
      if (typeof newPlz === 'string') {
        model.value = {
          plz: newPlz
        };
      }
    };
    return function (_ctx, _cache) {
      var _ctx$errors, _ctx$errors2, _ctx$errors3, _ctx$errors4, _ctx$errors5;
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(TextField, {
        "model-value": _unref(model).plz,
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors = _ctx.errors) === null || _ctx$errors === void 0 ? void 0 : _ctx$errors.plz),
        name: _unref(formPrefix) ? "".concat(_unref(formPrefix), "[plz]") : 'plz',
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))(_ctx.translationDomain, _ctx.isSwitzerland ? 'fields.postal_code' : 'fields.postal_code_optional'),
        autocomplete: "plz",
        "onUpdate:modelValue": setNewPlz
      }, null, 8 /* PROPS */, ["model-value", "error", "name", "label"])]), _createElementVNode("div", _hoisted_3, [_ctx.isSwitzerland ? (_openBlock(), _createBlock(DropdownField, {
        key: 0,
        "model-value": _unref(model).ort,
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors2 = _ctx.errors) === null || _ctx$errors2 === void 0 ? void 0 : _ctx$errors2.ort),
        name: _unref(formPrefix) ? "".concat(_unref(formPrefix), "[ort]") : 'ort',
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))(_ctx.translationDomain, 'fields.address_locality'),
        autocomplete: "ort",
        readonly: _unref(ortOptions).length < 2,
        "onUpdate:modelValue": setNewOrt
      }, {
        options: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ortOptions), function (ort) {
            return _openBlock(), _createElementBlock("option", {
              key: ort,
              value: ort
            }, _toDisplayString(ort), 9 /* TEXT, PROPS */, _hoisted_4);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model-value", "error", "name", "label", "readonly"])) : (_openBlock(), _createBlock(TextField, {
        key: 1,
        modelValue: _unref(model).ort,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(model).ort = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors3 = _ctx.errors) === null || _ctx$errors3 === void 0 ? void 0 : _ctx$errors3.ort),
        name: _unref(formPrefix) ? "".concat(_unref(formPrefix), "[ort]") : 'ort',
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))(_ctx.translationDomain, 'fields.address_locality'),
        autocomplete: "ort"
      }, null, 8 /* PROPS */, ["modelValue", "error", "name", "label"]))])]), _ctx.isSwitzerland ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(DropdownField, {
        modelValue: _unref(model).sitz,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _unref(model).sitz = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors4 = _ctx.errors) === null || _ctx$errors4 === void 0 ? void 0 : _ctx$errors4.sitz),
        name: _unref(formPrefix) ? "".concat(_unref(formPrefix), "[sitz]") : 'sitz',
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))(_ctx.translationDomain, 'fields.seat_of_address_locality'),
        autocomplete: "sitz",
        readonly: _unref(sitzOptions).length < 2
      }, {
        options: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sitzOptions), function (sitz) {
            return _openBlock(), _createElementBlock("option", {
              key: sitz,
              value: sitz
            }, _toDisplayString(sitz), 9 /* TEXT, PROPS */, _hoisted_7);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "error", "name", "label", "readonly"])]), _createElementVNode("div", _hoisted_8, [_createVNode(DropdownField, {
        modelValue: _unref(model).kanton,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return _unref(model).kanton = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors5 = _ctx.errors) === null || _ctx$errors5 === void 0 ? void 0 : _ctx$errors5.kanton),
        name: _unref(formPrefix) ? "".concat(_unref(formPrefix), "[kanton]") : 'kanton',
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))(_ctx.translationDomain, 'fields.kanton'),
        autocomplete: "kanton",
        readonly: _unref(kantonOptions).length < 2
      }, {
        options: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(kantonOptions), function (kanton) {
            return _openBlock(), _createElementBlock("option", {
              key: kanton,
              value: kanton
            }, _toDisplayString(kanton), 9 /* TEXT, PROPS */, _hoisted_9);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "error", "name", "label", "readonly"])])])) : _createCommentVNode("v-if", true)]);
    };
  }
});