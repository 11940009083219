function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  id: "vue-modal",
  "class": "bg-white rounded-2xl w-fit relative m-auto min-w-[40em]"
};
var _hoisted_2 = {
  "class": "border-bottom border-gray-border-dark"
};
var _hoisted_3 = {
  "class": "px-6 py-4"
};
var _hoisted_4 = {
  "class": "grow mb-0"
};
var _hoisted_5 = {
  key: 0,
  "class": "vue-modal-footer border-top border-gray-border-dark flex justify-end py-4 px-6"
};
var _hoisted_6 = ["onClick"];
import { Dialog } from '@teamnovu/kit-components';

import { useVModel, useScrollLock, syncRef } from '@vueuse/core';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'Modal'
}), {}, {
  __name: 'Modal',
  props: {
    title: {
      "default": ''
    },
    hideCloseButton: {
      type: Boolean,
      "default": false
    },
    modelValue: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['update:modalShow'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var show = useVModel(props, 'modelValue', emit, {
      passive: true
    });
    var locked = useScrollLock(document.documentElement);
    syncRef(show, locked, {
      direction: 'ltr'
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_Transition, {
        name: "fade"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_unref(Dialog), {
            show: _unref(show),
            "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
              return _isRef(show) ? show.value = $event : null;
            }),
            "class": "bg-transparent overflow-y-auto p-6 w-screen h-screen max-w-full max-h-full flex [&::backdrop]:bg-black [&::backdrop]:bg-opacity-30",
            modal: ""
          }, {
            "default": _withCtx(function (_ref2) {
              var close = _ref2.close;
              return [_unref(show) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({
                close: close
              })), function () {
                return [_createElementVNode("div", _hoisted_3, [_createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1 /* TEXT */)])];
              })]), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
                close: close
              }))), _ctx.$slots.footer ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps({
                close: close
              })))])) : _createCommentVNode("v-if", true)]), !_ctx.hideCloseButton ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                "class": "btn-close absolute top-5 end-6",
                "aria-label": "Close",
                onClick: close
              }, null, 8 /* PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)];
            }),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["show"])];
        }),
        _: 3 /* FORWARDED */
      });
    };
  }
}));