import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "content-wrapper"
};
var _hoisted_2 = {
  "class": "content col-md-16"
};
var _hoisted_3 = {
  "class": "my-4"
};
var _hoisted_4 = {
  key: 1,
  "class": "content-wrapper"
};
var _hoisted_5 = {
  "class": "content-table"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  key: 2
};
var _hoisted_9 = ["title", "href"];
var _hoisted_10 = {
  "class": "content"
};
var _hoisted_11 = {
  "class": "btn btn-primary mt-4",
  target: "_blank",
  rel: "noopener noreferrer",
  href: "/trustee/invoice-extract"
};
import { currencyCHF, formatDateTime } from '@/js/frontend/filters';
import PaginatedTable from '../PaginatedTable.vue';

import { useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'InvoicesView'
}), {}, {
  __name: 'InvoicesView',
  props: {
    hasFreeProcess: {
      type: Boolean
    }
  },
  setup: function setup(__props) {
    var tableColumns = ['table.type', 'table.id', 'table.process', 'table.company_name', 'table.date', 'table.price', ''];
    var _useQuery = useQuery({
        queryKey: ['/de/trustee/invoices'],
        initialData: []
      }),
      isSuccess = _useQuery.isSuccess,
      tableRows = _useQuery.data;
    var rowsFetched = computed(function () {
      return isSuccess.value || new Error();
    });
    return function (_ctx, _cache) {
      return _ctx.hasFreeProcess ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h4", _hoisted_3, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('trustee', 'view_disabled')), 1 /* TEXT */)])])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(PaginatedTable, {
        "table-name": "invoices",
        "translation-file": "invoice",
        "is-paginated": true,
        "table-rows": _unref(tableRows),
        "table-columns": tableColumns,
        "rows-fetched": _unref(rowsFetched),
        "filter-on": ['table.process', 'table.company_name'],
        "place-holder-text": "Filtern nach Prozess, Firmenname"
      }, {
        tableRow: _withCtx(function (_ref) {
          var tableRow = _ref.tableRow;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableColumns.slice(0, 6), function (tableColumn, index) {
            return _openBlock(), _createElementBlock("td", {
              key: tableColumn
            }, [index === 4 ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(formatDateTime)(Object.values(tableRow)[index])), 1 /* TEXT */)) : index === 5 ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(currencyCHF)(Object.values(tableRow)[index])), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(Object.values(tableRow)[index]), 1 /* TEXT */))]);
          }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("td", null, [_createElementVNode("a", {
            "data-toggle": "tooltip",
            title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'action.download_invoice'),
            href: tableRow['url'],
            target: "_blank rel=noopener noreferrer"
          }, _cache[0] || (_cache[0] = [_createElementVNode("i", {
            "class": "fal fa-file-pdf"
          }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_9)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["table-rows", "rows-fetched"])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("h4", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('invoice', 'invoice.account_statement')), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('invoice', 'invoice.account_statement_info_text')), 1 /* TEXT */), _createElementVNode("a", _hoisted_11, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('admin', 'action.download')), 1 /* TEXT */)])]));
    };
  }
}));