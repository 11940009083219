import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";

import { computed } from 'vue';
import { useFormattedDate } from '/var/node/assets/js/composables/useFormattedDate.ts';
var _hoisted_1 = ["datetime", "title"];
export default /*@__PURE__*/_defineComponent({
  __name: 'TableFormattedDate',
  props: {
    value: {},
    cell: {},
    meta: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var date = computed(function () {
      return props.value;
    });
    var options = computed(function () {
      var _props$meta$additiona, _props$meta;
      return (_props$meta$additiona = (_props$meta = props.meta) === null || _props$meta === void 0 ? void 0 : _props$meta.additionalFormatOptions) !== null && _props$meta$additiona !== void 0 ? _props$meta$additiona : undefined;
    });
    var _useFormattedDate = useFormattedDate(date, options),
      formattedDate = _useFormattedDate.formattedDate;
    return function (_ctx, _cache) {
      return _ctx.value ? (_openBlock(), _createElementBlock("time", {
        key: 0,
        datetime: _ctx.value,
        title: _unref(formattedDate),
        "class": "block truncate"
      }, _toDisplayString(_unref(formattedDate)), 9 /* TEXT, PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true);
    };
  }
});