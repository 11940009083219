import { getFirstError as __unimport_getFirstError } from '/var/node/assets/js/utils/validation.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  "class": "flex justify-between items-center"
};
var _hoisted_2 = {
  "class": "form-group"
};
var _hoisted_3 = {
  key: 0,
  "class": "form-group"
};
var _hoisted_4 = ["for"];
var _hoisted_5 = ["data-bs-content"];
var _hoisted_6 = {
  key: 1,
  "class": "invalid-feedback block"
};
var _hoisted_7 = {
  "class": "form-error-message"
};
var _hoisted_8 = ["id", "name", "required", "disabled"];
var _hoisted_9 = {
  "class": "row"
};
var _hoisted_10 = {
  "class": "col-12"
};
var _hoisted_11 = {
  "class": "form-group"
};
var _hoisted_12 = ["for"];
var _hoisted_13 = {
  key: 0
};
var _hoisted_14 = ["id", "name"];
var _hoisted_15 = {
  key: 0,
  value: ""
};
var _hoisted_16 = {
  key: 1,
  value: "identity_card"
};
var _hoisted_17 = {
  key: 2,
  value: "foreigner_identification"
};
var _hoisted_18 = {
  value: "passport"
};
import { cloneDeep } from 'lodash-es';
import IdentityImage from './IdentityImage.vue';
import { translate } from '@/js/frontend/filters';
import Countries from '../Countries.vue';

import { useVModel } from '@vueuse/core';
import { ref, computed, onUpdated } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'PersonOrigin'
}), {}, {
  __name: 'PersonOrigin',
  props: {
    modelValue: {
      "default": function _default() {
        return {
          nationality: 'CH',
          hometown: undefined,
          identificationMethod: undefined
        };
      }
    },
    token: {},
    index: {
      "default": 0
    },
    personFormPrefix: {
      "default": ''
    },
    formValidation: {
      "default": function _default() {
        return [];
      }
    },
    uploadOnly: {
      type: Boolean,
      "default": false
    },
    isESignature: {
      type: Boolean,
      "default": false
    },
    errors: {
      "default": undefined
    }
  },
  emits: ['deleteOrigin', 'update:modelValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var origin = useVModel(props, 'modelValue', emit, {
      clone: cloneDeep,
      deep: true,
      passive: true
    });
    var isHometownValid = ref(true);
    var isSwissNationality = computed(function () {
      return origin.value.nationality === 'CH';
    });
    var checkHometown = function checkHometown() {
      var _origin$value$hometow;
      isHometownValid.value = !!((_origin$value$hometow = origin.value.hometown) !== null && _origin$value$hometow !== void 0 && _origin$value$hometow.match(/.+\s[A-Z]{2}$/));
    };
    var deleteOrigin = function deleteOrigin(index) {
      emit('deleteOrigin', index);
    };
    onUpdated(function () {
      $('[data-bs-toggle="popover"]').each(function () {
        $(this).popover();
      });
    });
    return function (_ctx, _cache) {
      var _ctx$errors, _ctx$errors2;
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("h5", _hoisted_1, [_createTextVNode(_toDisplayString(_unref(translate)('nat_person', 'fields.nationality')) + " ", 1 /* TEXT */), _ctx.index !== 0 ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        "class": "remove-collection-widget collection-widget delete inline-flex",
        onClick: _cache[0] || (_cache[0] = function () {
          return deleteOrigin(_ctx.index);
        })
      }, _cache[5] || (_cache[5] = [_createElementVNode("i", {
        "class": "far fa-trash-alt m-0 trash-icon"
      }, null, -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [_createVNode(Countries, {
        id: "".concat(_ctx.personFormPrefix, "_person_origins_").concat(_ctx.index, "_nationality"),
        modelValue: _unref(origin).nationality,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _unref(origin).nationality = $event;
        }),
        name: "".concat(_ctx.personFormPrefix, "[origins][").concat(_ctx.index, "][nationality]"),
        disabled: _ctx.uploadOnly
      }, null, 8 /* PROPS */, ["id", "modelValue", "name", "disabled"])]), _unref(isSwissNationality) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("label", {
        "for": "".concat(_ctx.personFormPrefix, "_person_origins_").concat(_ctx.index, "_hometown")
      }, _toDisplayString(_unref(translate)('nat_person', 'fields.hometown')), 9 /* TEXT, PROPS */, _hoisted_4), !_ctx.uploadOnly ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        "class": "help-label",
        "data-bs-toggle": "popover",
        "data-bs-html": "true",
        "data-bs-content": "".concat(_unref(translate)('nat_person', 'info.hometown')),
        "data-bs-title": "Informationen"
      }, _cache[6] || (_cache[6] = [_createElementVNode("i", {
        "class": "fa fa-info-circle"
      }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true), !_unref(isHometownValid) || ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors = _ctx.errors) === null || _ctx$errors === void 0 ? void 0 : _ctx$errors.hometown) !== undefined ? (_openBlock(), _createElementBlock("span", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors2 = _ctx.errors) === null || _ctx$errors2 === void 0 ? void 0 : _ctx$errors2.hometown) || _unref(translate)('messages', 'error_person_controller.hometown_invalid_format')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _withDirectives(_createElementVNode("input", {
        id: "".concat(_ctx.personFormPrefix, "_person_origins_").concat(_ctx.index, "_hometown"),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return _unref(origin).hometown = $event;
        }),
        type: "text",
        name: "".concat(_ctx.personFormPrefix, "[origins][").concat(_ctx.index, "][hometown]"),
        required: _unref(isSwissNationality),
        disabled: _ctx.uploadOnly,
        "class": "form-control",
        onFocusout: _cache[3] || (_cache[3] = function ($event) {
          return checkHometown();
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8), [[_vModelText, _unref(origin).hometown]])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("label", {
        "for": "".concat(_ctx.personFormPrefix, "_person_origins_").concat(_ctx.index, "_identificationMethod")
      }, [_createTextVNode(_toDisplayString(_unref(translate)('nat_person', 'fields.identification_method')) + " ", 1 /* TEXT */), !_ctx.isESignature ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(translate)('nat_person', 'fields.optional')), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_12), _withDirectives(_createElementVNode("select", {
        id: "".concat(_ctx.personFormPrefix, "_person_origins_").concat(_ctx.index, "_identificationMethod"),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return _unref(origin).identificationMethod = $event;
        }),
        name: "".concat(_ctx.personFormPrefix, "[origins][").concat(_ctx.index, "][identificationMethod]"),
        "class": "form-select"
      }, [!_ctx.isESignature ? (_openBlock(), _createElementBlock("option", _hoisted_15)) : _createCommentVNode("v-if", true), _unref(isSwissNationality) ? (_openBlock(), _createElementBlock("option", _hoisted_16, _toDisplayString(_unref(translate)('nat_person', 'choices.identification_method.identity_card')), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !_unref(isSwissNationality) ? (_openBlock(), _createElementBlock("option", _hoisted_17, _toDisplayString(_unref(translate)('nat_person', 'choices.identification_method.foreigner_identification')), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("option", _hoisted_18, _toDisplayString(_unref(translate)('nat_person', 'choices.identification_method.passport')), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_14), [[_vModelSelect, _unref(origin).identificationMethod]])])])]), _createVNode(IdentityImage, {
        "class": "bordered",
        token: _ctx.token,
        nationality: _unref(origin).nationality,
        hometown: _unref(origin).hometown,
        "identification-method": _unref(origin).identificationMethod
      }, null, 8 /* PROPS */, ["token", "nationality", "hometown", "identification-method"])], 64 /* STABLE_FRAGMENT */);
    };
  }
}));