function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import TabNav from '../TabNav.vue';
import ProfileDetails from '@/components/profile/Details.vue';

import { computed, ref } from 'vue';
import { translate } from '/var/node/assets/js/utils/translate.ts';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'Profile'
}), {}, {
  __name: 'Profile',
  setup: function setup(__props) {
    var navItems = computed(function () {
      return [{
        component: ProfileDetails,
        label: translate('trustee', 'navigation.details')
      }];
    });
    var navItemTitles = computed(function () {
      return navItems.value.map(function (item) {
        return item.label;
      });
    });
    var activeNavItemIndex = ref(0);
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(TabNav, {
        "nav-items": _unref(navItemTitles),
        onSelectedItemIndex: _cache[0] || (_cache[0] = function (index) {
          return activeNavItemIndex.value = index;
        })
      }, null, 8 /* PROPS */, ["nav-items"]), (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navItems)[_unref(activeNavItemIndex)].component)))], 64 /* STABLE_FRAGMENT */);
    };
  }
}));