import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { computed } from 'vue';
import { translate } from '/var/node/assets/js/utils/translate.ts';
export default /*@__PURE__*/_defineComponent({
  __name: 'TableYesOrNo',
  props: {
    value: {
      type: Boolean
    },
    cell: {},
    meta: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var yesOrNo = computed(function () {
      if (typeof props.value !== 'boolean') {
        return undefined;
      }
      return props.value ? translate('messages.yes') : translate('messages.no');
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("span", null, _toDisplayString(_unref(yesOrNo)), 1 /* TEXT */);
    };
  }
});