import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, isRef as _isRef, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  key: 1,
  "class": "px-6 py-4"
};
var _hoisted_2 = {
  "class": "pe-3 w-100 flex align-items-center"
};
var _hoisted_3 = ["label"];
var _hoisted_4 = ["value"];
var _hoisted_5 = ["value"];
var _hoisted_6 = ["value"];
var _hoisted_7 = ["onClick"];
var _hoisted_8 = ["value"];
import DropdownField from '@/components/DropdownField.vue';
import ShabexFunction from '@/enums/ShabexFunction';
import { getFirstError } from '@/js/utils/validation';

import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'FunctionAndSignatureForm',
  props: {
    functions: {
      "default": function _default() {
        return [];
      }
    },
    possibleFunctions: {},
    signature: {
      "default": undefined
    },
    possibleSignatures: {},
    errors: {
      "default": undefined
    },
    loading: {
      type: Boolean
    }
  },
  emits: ['update:functions', 'update:signature'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var internalFunctions = useVModel(props, 'functions', emit, {
      passive: true
    });
    var internalSignature = useVModel(props, 'signature', emit, {
      passive: true
    });
    function addFunction() {
      internalFunctions.value = internalFunctions.value.concat(undefined);
    }
    function updateFunction(newValue, index) {
      var copy = internalFunctions.value.slice();
      copy[index] = newValue;
      internalFunctions.value = copy;
    }
    function removeFunction(index) {
      var copy = internalFunctions.value.slice();
      copy.splice(index, 1);
      internalFunctions.value = copy;
    }
    var groupFunctions = computed(function () {
      return !Array.isArray(props.possibleFunctions);
    });
    var functionIsGesellschafter = function functionIsGesellschafter(functionString) {
      return functionString === ShabexFunction.GESELLSCHAFTER;
    };
    return function (_ctx, _cache) {
      var _unref2, _ctx$errors2;
      var _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
      return _ctx.loading ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(internalFunctions), function (f, index) {
        var _ctx$errors;
        return _openBlock(), _createElementBlock("div", {
          key: f
        }, [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock(DropdownField, {
          key: index,
          "model-value": _ctx.functions[index],
          error: _unref(getFirstError)((_ctx$errors = _ctx.errors) === null || _ctx$errors === void 0 || (_ctx$errors = _ctx$errors.functions) === null || _ctx$errors === void 0 || (_ctx$errors = _ctx$errors.at(index)) === null || _ctx$errors === void 0 ? void 0 : _ctx$errors["function"]),
          name: "function",
          label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', 'choices.title'),
          autocomplete: "function",
          readonly: functionIsGesellschafter(f),
          "onUpdate:modelValue": function onUpdateModelValue(newValue) {
            return updateFunction(newValue, index);
          }
        }, {
          options: _withCtx(function () {
            return [_unref(groupFunctions) ? (_openBlock(true), _createElementBlock(_Fragment, {
              key: 0
            }, _renderList(Object.keys(_ctx.possibleFunctions), function (groupLabel) {
              return _openBlock(), _createElementBlock("optgroup", {
                key: groupLabel,
                label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', groupLabel)
              }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.possibleFunctions[groupLabel], function (functionText) {
                return _openBlock(), _createElementBlock("option", {
                  key: functionText,
                  value: functionText
                }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', "choices.".concat(functionText))), 9 /* TEXT, PROPS */, _hoisted_4);
              }), 128 /* KEYED_FRAGMENT */))], 8 /* PROPS */, _hoisted_3);
            }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(true), _createElementBlock(_Fragment, {
              key: 1
            }, _renderList(_ctx.possibleFunctions, function (functionText) {
              return _openBlock(), _createElementBlock("option", {
                key: functionText,
                value: functionText
              }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', "choices.".concat(functionText))), 9 /* TEXT, PROPS */, _hoisted_5);
            }), 128 /* KEYED_FRAGMENT */)), functionIsGesellschafter(f) ? (_openBlock(), _createElementBlock("option", {
              key: 2,
              value: _unref(ShabexFunction).GESELLSCHAFTER
            }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', "choices.".concat(_unref(ShabexFunction).GESELLSCHAFTER))), 9 /* TEXT, PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model-value", "error", "label", "readonly", "onUpdate:modelValue"])), index > 0 && !functionIsGesellschafter(f) ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          "class": "remove-collection-widget collection-widget delete ms-3",
          onClick: function onClick($event) {
            return removeFunction(index);
          }
        }, _cache[2] || (_cache[2] = [_createElementVNode("i", {
          "class": "far fa-trash-alt m-0 trash-icon"
        }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true)])]);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("a", {
        "class": "btn btn-primary mb-3",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return addFunction();
        })
      }, [_cache[3] || (_cache[3] = _createElementVNode("i", {
        "class": "far fa-plus"
      }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person_type', ((_unref2 = _unref(internalFunctions)) === null || _unref2 === void 0 ? void 0 : _unref2.length) > 0 ? 'fields.add_function' : 'fields.add_first_function')), 1 /* TEXT */)]), _createVNode(DropdownField, {
        modelValue: _unref(internalSignature),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _isRef(internalSignature) ? internalSignature.value = $event : null;
        }),
        name: "signature",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', 'signature.title'),
        error: _unref(getFirstError)((_ctx$errors2 = _ctx.errors) === null || _ctx$errors2 === void 0 ? void 0 : _ctx$errors2.signature),
        autocomplete: "signature"
      }, {
        options: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.possibleSignatures, function (signatureText) {
            return _openBlock(), _createElementBlock("option", {
              key: signatureText,
              value: signatureText
            }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', "signature.".concat(signatureText))), 9 /* TEXT, PROPS */, _hoisted_8);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label", "error"])]));
    };
  }
});