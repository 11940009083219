import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, isRef as _isRef, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  "class": "content"
};
var _hoisted_2 = {
  "class": "mb-4"
};
var _hoisted_3 = {
  id: "process-choices",
  "class": "options"
};
var _hoisted_4 = {
  "class": "flex justify-end"
};
var _hoisted_5 = ["href"];
var _hoisted_6 = {
  "class": "form-footer"
};
var _hoisted_7 = {
  id: "custom-process-choices",
  "class": "options start-custom-process"
};
var _hoisted_8 = {
  "class": "flex justify-end"
};
var _hoisted_9 = ["href"];
import ProcessOption from './ProcessOption.vue';
import OptionCategories from '@/enums/OptionCategories';
import ProcessType from '@/enums/ProcessType';
import { getLocalizedUrl } from '@/js/frontend/helpers';

import { ref, computed } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'FoundingOptions'
}), {}, {
  __name: 'FoundingOptions',
  props: {
    permissions: {}
  },
  emits: ['optionSelected'],
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose,
      __emit = _ref.emit;
    var emit = __emit;
    var creatingProcess = ref(false);
    var foundingOptions = [{
      label: 'founding.ag',
      name: 'founding',
      url: getLocalizedUrl('/company/process/founding_ag'),
      processType: ProcessType.foundingAg
    }, {
      label: 'founding.gmbh',
      name: 'founding',
      url: getLocalizedUrl('/company/process/founding_gmbh'),
      processType: ProcessType.foundingGmbh
    }, {
      label: 'founding.sole',
      name: 'founding',
      url: getLocalizedUrl('/company/process/founding_sole'),
      processType: ProcessType.foundingSole
    }, {
      label: 'founding.sole_changing_owner',
      name: 'founding',
      url: getLocalizedUrl('/company/process/founding_sole'),
      processType: ProcessType.foundingSole
    }, {
      label: 'founding.branch',
      name: 'founding',
      url: getLocalizedUrl('/process/founding/init'),
      processType: ProcessType.foundingBranch // Does not actually exists
    }];
    var foundingOptionsSpecial = [{
      label: 'founding.sacheinlage',
      name: ProcessType.foundingSpecial,
      processType: ProcessType.foundingSpecial
    }];
    var selectedFoundingOptions = ref('');
    var selectedFoundingOptionsSpecial = ref('');
    var foundingOptionUrl = computed(function () {
      var _foundingOptions$filt;
      return (_foundingOptions$filt = foundingOptions.filter(function (o) {
        return o.label === selectedFoundingOptions.value;
      })[0]) === null || _foundingOptions$filt === void 0 ? void 0 : _foundingOptions$filt.url;
    });
    var optionSelected = function optionSelected(category) {
      if (category === OptionCategories.FOUNDING_OPTIONS_SPECIAL) {
        selectedFoundingOptions.value = '';
      } else {
        selectedFoundingOptionsSpecial.value = '';
      }
      emit('optionSelected', category);
    };
    var resetFields = function resetFields() {
      selectedFoundingOptions.value = '';
      selectedFoundingOptionsSpecial.value = '';
    };
    __expose({
      resetFields: resetFields
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h4", _hoisted_2, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'view.new_registration')), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(foundingOptions, function (option) {
        return _createVNode(ProcessOption, {
          key: option,
          modelValue: _unref(selectedFoundingOptions),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
            return _isRef(selectedFoundingOptions) ? selectedFoundingOptions.value = $event : null;
          }),
          permissions: _ctx.permissions,
          option: option,
          category: "category",
          type: "founding",
          onOptionSelected: _cache[1] || (_cache[1] = function ($event) {
            return optionSelected(_unref(OptionCategories).FOUNDING_OPTIONS);
          })
        }, null, 8 /* PROPS */, ["modelValue", "permissions", "option"]);
      }), 64 /* STABLE_FRAGMENT */))]), _createElementVNode("div", _hoisted_4, [_createElementVNode("a", {
        id: "startProcess",
        "class": _normalizeClass([_unref(selectedFoundingOptions) && !_unref(creatingProcess) ? '' : 'disabled', "btn btn-primary founding-choices submit"]),
        href: _unref(foundingOptionUrl),
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return creatingProcess.value = true;
        })
      }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process_mutation', 'general.process_start')), 11 /* TEXT, CLASS, PROPS */, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'special_process.can_only_choose_one')), 1 /* TEXT */), _createElementVNode("div", _hoisted_7, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(foundingOptionsSpecial, function (option) {
        return _createVNode(ProcessOption, {
          key: option,
          modelValue: _unref(selectedFoundingOptionsSpecial),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
            return _isRef(selectedFoundingOptionsSpecial) ? selectedFoundingOptionsSpecial.value = $event : null;
          }),
          option: option,
          permissions: _ctx.permissions,
          category: "specialCategory",
          type: "founding",
          onOptionSelected: _cache[4] || (_cache[4] = function ($event) {
            return optionSelected(_unref(OptionCategories).FOUNDING_OPTIONS_SPECIAL);
          })
        }, null, 8 /* PROPS */, ["modelValue", "option", "permissions"]);
      }), 64 /* STABLE_FRAGMENT */))]), _createElementVNode("div", _hoisted_8, [_createElementVNode("a", {
        id: "startCustomProcess",
        "class": _normalizeClass([_unref(selectedFoundingOptionsSpecial) && !_unref(creatingProcess) ? '' : 'disabled', "btn btn-primary custom-process-choices submit"]),
        href: _unref(getLocalizedUrl)('trustee/custom/form?type=Sacheinlagegründung (inkl. Umwandlung einer Einzelunternehmung)'),
        target: "_blank",
        rel: "noopener noreferrer",
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return creatingProcess.value = true;
        })
      }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'actions.start_special_process')), 11 /* TEXT, CLASS, PROPS */, _hoisted_9)])])]);
    };
  }
}));