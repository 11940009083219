import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "form-group"
};
var _hoisted_2 = {
  "class": "col-form-label required",
  "for": "date"
};
var _hoisted_3 = {
  id: "date",
  "class": "date-fields"
};
var _hoisted_4 = {
  "class": "visually-hidden"
};
var _hoisted_5 = {
  "class": "required",
  "for": "year"
};
var _hoisted_6 = {
  "class": "required",
  "for": "month"
};
var _hoisted_7 = {
  "class": "required",
  "for": "day"
};
var _hoisted_8 = {
  "class": "input-group"
};
var _hoisted_9 = ["value"];
var _hoisted_10 = ["value"];
var _hoisted_11 = ["value"];
var _hoisted_12 = ["value"];
var _hoisted_13 = ["value"];
var _hoisted_14 = ["value"];
import { getDaysInMonth } from 'date-fns';

import { ref, computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'DateInput',
  props: {
    modelValue: {
      "default": ''
    },
    title: {},
    numberOfYears: {
      "default": -100
    },
    startYear: {
      "default": undefined
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var checkDaysInMonth = function checkDaysInMonth(m, y) {
      var dIM = 31;
      // eslint-disable-next-line no-use-before-define
      if (m && y) {
        // eslint-disable-next-line no-use-before-define
        dIM = getDaysInMonth(new Date(y, m - 1));
      }
      // eslint-disable-next-line no-use-before-define
      if (day.value && dIM < day.value) {
        // eslint-disable-next-line no-use-before-define
        return dIM;
      }
      // eslint-disable-next-line no-use-before-define
      return day.value;
    };
    var internalDay = ref(undefined);
    var day = computed({
      get: function get() {
        var _props$modelValue$get, _props$modelValue;
        if (typeof props.modelValue === 'string') {
          var val = props.modelValue.split('-')[2];
          return val ? parseInt(val, 10) : internalDay.value;
        }
        return (_props$modelValue$get = (_props$modelValue = props.modelValue) === null || _props$modelValue === void 0 ? void 0 : _props$modelValue.getDate()) !== null && _props$modelValue$get !== void 0 ? _props$modelValue$get : internalDay.value;
      },
      set: function set(newVal) {
        if (typeof props.modelValue === 'string') {
          // eslint-disable-next-line no-use-before-define
          if (month.value && year.value && newVal) {
            // eslint-disable-next-line no-use-before-define
            emit('update:modelValue', "".concat(year.value, "-").concat(month.value, "-").concat(newVal));
          }
        } else if (props.modelValue && newVal !== undefined) {
          var copy = new Date(props.modelValue);
          copy.setDate(newVal);
          emit('update:modelValue', copy);
        }
        internalDay.value = newVal;
      }
    });
    var internalMonth = ref(undefined);
    var month = computed({
      get: function get() {
        var _props$modelValue$get2, _props$modelValue2;
        if (typeof props.modelValue === 'string') {
          var val = props.modelValue.split('-')[1];
          return val ? parseInt(val, 10) : internalMonth.value;
        }
        var m = (_props$modelValue$get2 = (_props$modelValue2 = props.modelValue) === null || _props$modelValue2 === void 0 ? void 0 : _props$modelValue2.getMonth()) !== null && _props$modelValue$get2 !== void 0 ? _props$modelValue$get2 : undefined;
        return m !== undefined ? m + 1 : internalMonth.value;
      },
      set: function set(newVal) {
        // eslint-disable-next-line no-use-before-define
        var days = checkDaysInMonth(newVal, year.value);
        if (typeof props.modelValue === 'string') {
          // eslint-disable-next-line no-use-before-define
          if (days && year.value && newVal) {
            // eslint-disable-next-line no-use-before-define
            emit('update:modelValue', "".concat(year.value, "-").concat(newVal, "-").concat(days));
          }
        } else if (props.modelValue && newVal !== undefined) {
          var copy = new Date(props.modelValue);
          copy.setMonth(newVal - 1);
          emit('update:modelValue', copy);
        }
        internalMonth.value = newVal;
      }
    });
    var internalYear = ref(undefined);
    var year = computed({
      get: function get() {
        var _props$modelValue$get3, _props$modelValue3;
        if (typeof props.modelValue === 'string') {
          var val = props.modelValue.split('-')[0];
          return val ? parseInt(val, 10) : internalYear.value;
        }
        return (_props$modelValue$get3 = (_props$modelValue3 = props.modelValue) === null || _props$modelValue3 === void 0 ? void 0 : _props$modelValue3.getFullYear()) !== null && _props$modelValue$get3 !== void 0 ? _props$modelValue$get3 : internalYear.value;
      },
      set: function set(newVal) {
        var days = checkDaysInMonth(month.value, newVal);
        if (typeof props.modelValue === 'string') {
          if (month.value && days && newVal) {
            emit('update:modelValue', "".concat(newVal, "-").concat(month.value, "-").concat(days));
          }
        } else if (props.modelValue && newVal !== undefined) {
          var copy = new Date(props.modelValue);
          copy.setFullYear(newVal);
          emit('update:modelValue', copy);
        }
        internalYear.value = newVal;
      }
    });
    var daysInMonth = computed(function () {
      if (month.value && year.value) {
        return getDaysInMonth(new Date(year.value, month.value - 1));
      }
      return 31;
    });
    function currentYear() {
      return new Date().getFullYear();
    }
    function getNumberRange(number) {
      var factor = number < 0 ? -1 : 1;
      return Array.from({
        length: Math.abs(number)
      }, function (_, i) {
        return factor * (i + 1);
      });
    }
    function setModel(event) {
      var field = event.target;
      var fieldValue = parseInt(field.value, 10);
      if (field.id === 'day') {
        day.value = fieldValue;
      } else if (field.id === 'month') {
        month.value = fieldValue;
      } else if (field.id === 'year') {
        year.value = fieldValue;
      }
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("label", _hoisted_5, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'year')), 1 /* TEXT */), _createElementVNode("label", _hoisted_6, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'month')), 1 /* TEXT */), _createElementVNode("label", _hoisted_7, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'day')), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("select", {
        id: "day",
        value: _unref(day),
        name: "day",
        "class": "form-select",
        onChange: _cache[0] || (_cache[0] = function (d) {
          return setModel(d);
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getNumberRange(_unref(daysInMonth)), function (i) {
        return _openBlock(), _createElementBlock("option", {
          key: i,
          value: i
        }, _toDisplayString(i), 9 /* TEXT, PROPS */, _hoisted_10);
      }), 128 /* KEYED_FRAGMENT */))], 40 /* PROPS, NEED_HYDRATION */, _hoisted_9), _createElementVNode("select", {
        id: "month",
        value: _unref(month),
        name: "month",
        "class": "form-select",
        onChange: _cache[1] || (_cache[1] = function (d) {
          return setModel(d);
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getNumberRange(12), function (i) {
        return _openBlock(), _createElementBlock("option", {
          key: i,
          value: i
        }, _toDisplayString(i), 9 /* TEXT, PROPS */, _hoisted_12);
      }), 128 /* KEYED_FRAGMENT */))], 40 /* PROPS, NEED_HYDRATION */, _hoisted_11), _createElementVNode("select", {
        id: "year",
        value: _unref(year),
        name: "year",
        "class": "form-select",
        onChange: _cache[2] || (_cache[2] = function (d) {
          return setModel(d);
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getNumberRange(_ctx.numberOfYears), function (i) {
        var _ctx$startYear, _ctx$startYear2;
        return _openBlock(), _createElementBlock("option", {
          key: i,
          value: i + (((_ctx$startYear = _ctx.startYear) !== null && _ctx$startYear !== void 0 ? _ctx$startYear : currentYear()) - 1)
        }, _toDisplayString(i + (((_ctx$startYear2 = _ctx.startYear) !== null && _ctx$startYear2 !== void 0 ? _ctx$startYear2 : currentYear()) - 1)), 9 /* TEXT, PROPS */, _hoisted_14);
      }), 128 /* KEYED_FRAGMENT */))], 40 /* PROPS, NEED_HYDRATION */, _hoisted_13)])])]);
    };
  }
});