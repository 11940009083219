function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = ["data-toggle", "title"];
var _hoisted_2 = {
  "class": "form-check"
};
var _hoisted_3 = ["id", "checked", "type", "name", "disabled"];
var _hoisted_4 = ["id", "value", "checked", "name", "disabled"];
var _hoisted_5 = ["for"];
import { translate } from '@/js/frontend/filters';
import { VoterTypes } from '@/js/utils/permissions';
import CompanyType from '@/enums/CompanyType';
import ProcessType from '@/enums/ProcessType';

import { computed } from 'vue';
import { isGrantedPermissions } from '/var/node/assets/js/utils/permissions.ts';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'ProcessOption'
}), {}, {
  __name: 'ProcessOption',
  props: {
    category: {
      "default": ''
    },
    option: {
      "default": function _default() {
        return {};
      }
    },
    modelValue: {
      type: [Boolean, String],
      "default": false
    },
    permissions: {},
    companyType: {
      "default": null
    },
    type: {}
  },
  emits: ['update:modelValue', 'optionSelected'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var isOptionSelected = computed(function () {
      return props.option.value || props.modelValue === props.option.label;
    });
    var valueChanged = function valueChanged(value) {
      emit('update:modelValue', value);
      emit('optionSelected');
    };
    var optionDisabled = function optionDisabled(option) {
      if (option.customDisable) {
        return option.customDisable(props.type, props.companyType, props.permissions);
      }
      if (props.type === 'mutation') {
        if (!option.mutationType) {
          return true;
        }
        switch (props.companyType) {
          case CompanyType.BRANCH:
            return !isGrantedPermissions(VoterTypes.START_BRANCH_MUTATION, option.mutationType, props.permissions);
          case CompanyType.AG:
            return !isGrantedPermissions(VoterTypes.START_AG_MUTATION, option.mutationType, props.permissions);
          case CompanyType.GMBH:
            return !isGrantedPermissions(VoterTypes.START_GMBH_MUTATION, option.mutationType, props.permissions);
          case CompanyType.SOLE:
            return !isGrantedPermissions(VoterTypes.START_SOLE_MUTATION, option.mutationType, props.permissions);
          default:
            return true;
        }
      }
      if (option.processType === ProcessType.foundingBranch) {
        return !isGrantedPermissions(VoterTypes.START_BRANCH_FOUNDING, '', props.permissions);
      }
      if (option.processType === ProcessType.foundingSpecial) {
        return !isGrantedPermissions(VoterTypes.START_SPECIAL_FOUNDING, '', props.permissions);
      }
      return !option.processType || !isGrantedPermissions(VoterTypes.START_FOUNDING, option.processType, props.permissions);
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        "class": _normalizeClass(["option", {
          disabled: optionDisabled(props.option),
          selected: _unref(isOptionSelected)
        }]),
        "data-toggle": {
          disabled: optionDisabled(props.option) ? 'tooltip' : ''
        },
        title: optionDisabled(props.option) ? _unref(translate)('messages', 'permissions.available') : ''
      }, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        "class": "icon"
      }, [_createElementVNode("i", {
        "class": "fas fa-check-circle"
      })], -1 /* HOISTED */)), _createElementVNode("div", _hoisted_2, [props.option.type ? (_openBlock(), _createElementBlock("input", {
        key: 0,
        id: props.option.label,
        checked: _ctx.modelValue,
        type: props.option.type,
        name: props.option.name,
        "class": _normalizeClass(["form-check-input", {
          disabled: optionDisabled(props.option)
        }]),
        disabled: optionDisabled(props.option),
        onChange: _cache[0] || (_cache[0] = function ($event) {
          return valueChanged($event.target.checked);
        })
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3)) : (_openBlock(), _createElementBlock("input", {
        key: 1,
        id: props.option.label,
        value: _ctx.modelValue,
        checked: _ctx.modelValue === props.option.label,
        type: "radio",
        name: props.option.name,
        "class": _normalizeClass(["form-check-input", {
          disabled: optionDisabled(props.option)
        }]),
        disabled: optionDisabled(props.option),
        onChange: _cache[1] || (_cache[1] = function ($event) {
          return valueChanged(props.option.label);
        })
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4)), _createElementVNode("label", {
        "class": "form-check-label",
        "for": props.option.label
      }, _toDisplayString(_unref(translate)('messages', "offer.".concat(_ctx.option.customLabelRender ? _ctx.option.customLabelRender(_ctx.option.label, _ctx.type, _ctx.companyType, _ctx.permissions) : _ctx.option.label))), 9 /* TEXT, PROPS */, _hoisted_5)])], 10 /* CLASS, PROPS */, _hoisted_1);
    };
  }
}));