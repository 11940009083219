import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "form-footer form-group flex justify-end"
};
import BusyButton from '@/components/general/BusyButton.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'SaveAndNextButton',
  props: {
    busy: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    customText: {}
  },
  emits: ['click'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emits = __emit;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(BusyButton, {
        id: "form_saveAndNext",
        busy: _ctx.busy,
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return emits('click');
        })
      }, {
        "default": _withCtx(function () {
          var _ctx$customText;
          return [_createTextVNode(_toDisplayString((_ctx$customText = _ctx.customText) !== null && _ctx$customText !== void 0 ? _ctx$customText : ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'form.save_and_next')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["busy", "disabled"])]);
    };
  }
});