import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = ["type", "disabled"];
var _hoisted_2 = {
  key: 0,
  "class": "absolute left-1/2 -translate-x-1/2"
};
export default /*@__PURE__*/_defineComponent({
  __name: 'BusyButton',
  props: {
    busy: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    isSubmit: {
      type: Boolean
    }
  },
  emits: ['click'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emits = __emit;
    return function (_ctx, _cache) {
      var _ctx$disabled;
      return _openBlock(), _createElementBlock("button", {
        type: _ctx.isSubmit ? 'submit' : 'button',
        "class": _normalizeClass(["btn-primary btn relative", {
          disabled: !!_ctx.disabled
        }]),
        disabled: (_ctx$disabled = _ctx.disabled) !== null && _ctx$disabled !== void 0 ? _ctx$disabled : false,
        onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
          return emits('click');
        }, ["stop"]))
      }, [_ctx.busy ? (_openBlock(), _createElementBlock("span", _hoisted_2, _cache[1] || (_cache[1] = [_createElementVNode("i", {
        "class": "fa fa-spinner-third fa-spin"
      }, null, -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true), _createElementVNode("span", {
        "class": _normalizeClass({
          invisible: _ctx.busy
        })
      }, [_renderSlot(_ctx.$slots, "default")], 2 /* CLASS */)], 10 /* CLASS, PROPS */, _hoisted_1);
    };
  }
});