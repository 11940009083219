import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  "class": "flex justify-center"
};
var _hoisted_2 = {
  key: 0,
  "class": "loading fa fa-spinner-third fa-spin"
};
var _hoisted_3 = {
  key: 1,
  "class": "m-1"
};
export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingSpinner',
  props: {
    spinnerSmall: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.spinnerSmall ? (_openBlock(), _createElementBlock("i", _hoisted_2)) : (_openBlock(), _createElementBlock("h3", _hoisted_3, _cache[0] || (_cache[0] = [_createElementVNode("i", {
        "class": "loading fa fa-spinner-third fa-spin"
      }, null, -1 /* HOISTED */)])))]);
    };
  }
});