import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SearchField from '@/components/SearchField.vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'CompanySearchField'
}), {}, {
  __name: 'CompanySearchField',
  props: {
    additionalSearchParameters: {
      "default": null
    },
    initialCompanyName: {
      "default": undefined
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    name: {
      "default": undefined
    },
    label: {
      "default": undefined
    }
  },
  emits: ['companySelected'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(SearchField, {
        "search-url": "/de/shabex/search?legalform%5B0%5D=3&legalform%5B1%5D=4&legalform%5B2%5D=1&legalform%5B3%5D=9&query=",
        "additional-search-parameters": _ctx.additionalSearchParameters,
        placeholder: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'autocomplete.search_company_placeholder'),
        disabled: _ctx.disabled,
        "initial-value": _ctx.initialCompanyName,
        name: _ctx.name,
        label: _ctx.label,
        onSelected: _cache[0] || (_cache[0] = function (c) {
          return emit('companySelected', c);
        })
      }, null, 8 /* PROPS */, ["additional-search-parameters", "placeholder", "disabled", "initial-value", "name", "label"]);
    };
  }
}));