function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelSelect as _vModelSelect, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createStaticVNode as _createStaticVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "filter mt-1"
};
var _hoisted_2 = ["placeholder"];
var _hoisted_3 = {
  "class": "flex"
};
var _hoisted_4 = {
  "for": "select-pagesize"
};
var _hoisted_5 = {
  "class": "table table-striped table-sm table-hover"
};
var _hoisted_6 = {
  "class": ""
};
var _hoisted_7 = {
  "class": "position-sticky top-0"
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  key: 0,
  "class": "inline"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = {
  key: 0
};
var _hoisted_12 = {
  key: 1
};
var _hoisted_13 = {
  key: 1
};
var _hoisted_14 = {
  key: 0
};
var _hoisted_15 = ["colspan"];
var _hoisted_16 = {
  "class": "mb-0 text-start"
};
var _hoisted_17 = {
  key: 1
};
var _hoisted_18 = ["colspan"];
var _hoisted_19 = {
  key: 2
};
var _hoisted_20 = ["colspan"];
var _hoisted_21 = {
  "class": "mb-0 text-start"
};
var _hoisted_22 = {
  key: 1
};
var _hoisted_23 = {
  "class": "pagination"
};
var _hoisted_24 = ["onClick"];
var _hoisted_25 = {
  "class": "page-link"
};
import { translate } from '@/js/frontend/filters';
import { getVar, setVar } from '@/js/frontend/helpers';

import { ref, computed, onMounted, watch } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'PaginatedTable'
}), {}, {
  __name: 'PaginatedTable',
  props: {
    tableName: {
      "default": ''
    },
    translationFile: {
      "default": ''
    },
    isPaginated: {
      type: Boolean,
      "default": false
    },
    tableColumns: {
      "default": function _default() {
        return [];
      }
    },
    sortedByColumn: {
      "default": ''
    },
    isSortedAsc: {
      type: Boolean,
      "default": true
    },
    tableRows: {
      "default": function _default() {
        return [];
      }
    },
    rowsFetched: {
      type: [Boolean, Error],
      "default": false
    },
    initPageSize: {
      "default": 10
    },
    filterOn: {
      "default": function _default() {
        return [];
      }
    },
    placeHolderText: {
      "default": ''
    },
    limitHeight: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['pageSizeChanged'],
  setup: function setup(__props) {
    var props = __props;
    var filteredRows = ref([]);
    var paginatedRows = ref([]);
    var filterText = ref('');
    var currentPage = ref(0);
    var shouldSortAsc = ref(true);
    var sortedColumn = ref('');
    var pageSize = ref(10);
    var fetchError = computed(function () {
      return props.rowsFetched instanceof Error;
    });
    var totalPages = computed(function () {
      return Math.ceil(filteredRows.value.length / pageSize.value);
    });
    var tablePageSize = computed(function () {
      return "".concat(props.tableName, "TablePageSize");
    });
    var visiblePages = computed(function () {
      var result = [];
      var cp = currentPage.value;
      var tp = totalPages.value;
      if (tp > 6) {
        result.push(1);
        if (cp > 3) result.push(undefined);
        if (cp > 2) result.push(cp - 1);
        if (cp !== 1 && cp !== tp) result.push(cp);
        if (cp < tp - 1) result.push(cp + 1);
        if (cp < tp - 2) result.push(undefined);
        result.push(tp);
        return result;
      }
      return Array(tp).fill(0).map(function (_, i) {
        return i + 1;
      });
    });
    onMounted(function () {
      pageSize.value = getVar(tablePageSize.value) || props.initPageSize;
    });
    var changePage = function changePage(newPage) {
      if (!newPage) return;
      if (newPage > 0 && newPage <= totalPages.value) currentPage.value = newPage;
      paginatedRows.value = filteredRows.value.slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value);
    };
    var filterRows = function filterRows() {
      if (filterText.value === '') {
        filteredRows.value = props.tableRows;
      } else {
        filteredRows.value = props.tableRows.filter(function (row) {
          var _iterator = _createForOfIteratorHelper(props.filterOn),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var filterOn = _step.value;
              if (row[filterOn].toUpperCase().includes(filterText.value.toUpperCase())) return true;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          return false;
        });
      }
      changePage(1);
    };
    var sort = function sort(column) {
      sortedColumn.value = column;
      shouldSortAsc.value = !shouldSortAsc.value;
      filteredRows.value = filteredRows.value.sort(function (a, b) {
        if (shouldSortAsc.value) return a[column].toString().toLowerCase() > b[column].toString().toLowerCase() ? 1 : -1;
        return a[column].toString().toLowerCase() < b[column].toString().toLowerCase() ? 1 : -1;
      });
      changePage(1);
    };
    var pageSizeChanged = function pageSizeChanged() {
      setVar(tablePageSize.value, pageSize.value);
      changePage(1);
    };
    watch(computed(function () {
      return props.tableRows;
    }), function (newRows) {
      filteredRows.value = newRows;
      changePage(1);
    }, {
      immediate: true
    });
    watch(computed(function () {
      return props.sortedByColumn;
    }), function (column) {
      sortedColumn.value = column;
    });
    watch(computed(function () {
      return props.isSortedAsc;
    }), function (newVal) {
      shouldSortAsc.value = newVal;
    }, {
      immediate: true
    });
    watch(computed(function () {
      return props.initPageSize;
    }), function (newSize) {
      pageSize.value = newSize;
      changePage(1);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        "class": _normalizeClass([_ctx.limitHeight ? 'scrollbox' : '', "table-responsive"])
      }, [_ctx.isPaginated ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(filterText) ? filterText.value = $event : null;
        }),
        type: "text",
        "class": "form-control ms-4",
        placeholder: _ctx.placeHolderText,
        onInput: _cache[1] || (_cache[1] = function ($event) {
          return filterRows();
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [[_vModelText, _unref(filterText)]]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("select", {
        id: "select-pagesize",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return _isRef(pageSize) ? pageSize.value = $event : null;
        }),
        "class": "form-select",
        style: {
          "width": "fit-content"
        },
        onChange: _cache[3] || (_cache[3] = function ($event) {
          return pageSizeChanged();
        })
      }, _cache[6] || (_cache[6] = [_createStaticVNode("<option value=\"5\">5</option><option value=\"10\" selected>10</option><option value=\"25\">25</option><option value=\"50\">50</option><option value=\"100\">100</option>", 5)]), 544 /* NEED_HYDRATION, NEED_PATCH */), [[_vModelSelect, _unref(pageSize), void 0, {
        number: true
      }]]), _createElementVNode("label", _hoisted_4, _toDisplayString(_unref(translate)('messages', 'table.show_entries')), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("table", _hoisted_5, [_createElementVNode("thead", _hoisted_6, [_createElementVNode("tr", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, function (tableColumn) {
        return _openBlock(), _createElementBlock("th", {
          key: tableColumn,
          "class": _normalizeClass(tableColumn !== '' ? 'cursor-pointer' : ''),
          onClick: function onClick($event) {
            return tableColumn !== '' ? sort(tableColumn) : '';
          }
        }, [_createTextVNode(_toDisplayString(_unref(translate)(_ctx.translationFile, tableColumn)) + " ", 1 /* TEXT */), tableColumn !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_unref(sortedColumn) === tableColumn ? (_openBlock(), _createElementBlock("span", _hoisted_10, [!_unref(shouldSortAsc) ? (_openBlock(), _createElementBlock("span", _hoisted_11, _cache[7] || (_cache[7] = [_createElementVNode("i", {
          "class": "fas fa-sort-down"
        }, null, -1 /* HOISTED */)]))) : _unref(shouldSortAsc) ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[8] || (_cache[8] = [_createElementVNode("i", {
          "class": "fas fa-sort-up"
        }, null, -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("span", _hoisted_13, _cache[9] || (_cache[9] = [_createElementVNode("i", {
          "class": "fal fa-sort"
        }, null, -1 /* HOISTED */)])))])) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_8);
      }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [_unref(fetchError) ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [_createElementVNode("td", {
        colspan: _ctx.tableColumns.length
      }, [_createElementVNode("p", _hoisted_16, _toDisplayString(_unref(translate)('errors', 'page.error.title')), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_15)])) : !_ctx.rowsFetched ? (_openBlock(), _createElementBlock("tr", _hoisted_17, [_createElementVNode("td", {
        colspan: _ctx.tableColumns.length
      }, _cache[10] || (_cache[10] = [_createElementVNode("h4", {
        "class": "text-start mb-0"
      }, [_createElementVNode("i", {
        "class": "fa fa-spinner-third fa-spin"
      })], -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_18)])) : _unref(paginatedRows).length < 1 ? (_openBlock(), _createElementBlock("tr", _hoisted_19, [_createElementVNode("td", {
        colspan: _ctx.tableColumns.length
      }, [_createElementVNode("p", _hoisted_21, _toDisplayString(_unref(translate)('messages', 'table.no_entries')), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_20)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paginatedRows), function (tableRow) {
        return _openBlock(), _createElementBlock("tr", {
          key: tableRow
        }, [_renderSlot(_ctx.$slots, "tableRow", {
          tableRow: tableRow
        })]);
      }), 128 /* KEYED_FRAGMENT */))])]), _ctx.isPaginated ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createElementVNode("ul", _hoisted_23, [_createElementVNode("li", {
        "class": _normalizeClass([{
          disabled: _unref(currentPage) - 1 < 1
        }, "page-item"]),
        onClick: _cache[4] || (_cache[4] = function ($event) {
          return changePage(_unref(currentPage) - 1);
        })
      }, _cache[11] || (_cache[11] = [_createElementVNode("a", {
        "class": "page-link"
      }, [_createElementVNode("span", null, "«")], -1 /* HOISTED */)]), 2 /* CLASS */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visiblePages), function (page) {
        return _openBlock(), _createElementBlock("li", {
          key: page,
          "class": _normalizeClass([{
            active: page === _unref(currentPage)
          }, "page-item"]),
          onClick: function onClick($event) {
            return changePage(page);
          }
        }, [_createElementVNode("a", _hoisted_25, _toDisplayString(page || '...'), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_24);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("li", {
        "class": _normalizeClass([{
          disabled: _unref(currentPage) + 1 > _unref(totalPages)
        }, "page-item"]),
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return changePage(_unref(currentPage) + 1);
        })
      }, _cache[12] || (_cache[12] = [_createElementVNode("a", {
        "class": "page-link"
      }, [_createElementVNode("span", null, "»")], -1 /* HOISTED */)]), 2 /* CLASS */)])])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
    };
  }
}));