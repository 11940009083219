function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "flex flex-col"
};
var _hoisted_2 = {
  "class": "my-4 self-center"
};
var _hoisted_3 = {
  "class": "flex justify-center items-center"
};
var _hoisted_4 = {
  key: 1,
  "class": "mb-6"
};
var _hoisted_5 = {
  "class": "card bg-warning"
};
var _hoisted_6 = {
  "class": "alert alert-warning",
  role: "alert"
};
import QrCode from './QrCode.vue';
import ImageUpload from './ImageUpload.vue';
import { translate } from '@/js/frontend/filters';
import ImageUploadType from '@/enums/ImageUploadType';

import { ref, computed, onMounted } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'SignatureImage'
}), {}, {
  __name: 'SignatureImage',
  props: {
    fileToken: {
      "default": ''
    },
    eSignUrl: {},
    token: {
      "default": ''
    },
    signatureApproved: {
      type: [Boolean, null],
      "default": null
    },
    uploadOnly: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['imageUploadMissing', 'vueCropperOpen'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var shouldBeMounted = ref(true);
    var isImageUploadMissing = ref(true);
    var externalFormUrlWithParameters = computed(function () {
      return "".concat(props.eSignUrl).concat(props.eSignUrl.endsWith('/') ? '' : '/', "image/external/upload/").concat(props.token, "?imageType=").concat(ImageUploadType.SIGNATURE).concat(props.fileToken ? "&file_token=".concat(props.fileToken) : '');
    });
    var getUrl = function getUrl(action) {
      return "/de/person/public/image/".concat(action, "/").concat(props.token, "?imageType=").concat(ImageUploadType.SIGNATURE).concat(props.fileToken ? "&file_token=".concat(props.fileToken) : '');
    };
    var updateIsImageUploadMissing = function updateIsImageUploadMissing(m) {
      isImageUploadMissing.value = m;
      emit('imageUploadMissing', m);
    };
    onMounted(function () {
      $('#main_modal').one('hidden.bs.modal', function () {
        shouldBeMounted.value = false;
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h5", _hoisted_2, _toDisplayString(_unref(translate)('nat_person', 'info.signature_image')), 1 /* TEXT */), _unref(isImageUploadMissing) && !_ctx.uploadOnly ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(QrCode, {
        "external-form-url-with-parameters": _unref(externalFormUrlWithParameters)
      }, null, 8 /* PROPS */, ["external-form-url-with-parameters"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString(_unref(translate)('nat_person', 'info.or_upload_scan_here')), 1 /* TEXT */)])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), !_unref(isImageUploadMissing) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_unref(translate)('nat_person', 'info.reminder_crop_signature')), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _unref(shouldBeMounted) ? (_openBlock(), _createBlock(ImageUpload, {
        key: 2,
        title: _unref(translate)('nat_person', 'info.my_signature'),
        "file-token": _ctx.fileToken,
        "get-url-with-parameters": getUrl('get'),
        "upload-url-with-parameters": getUrl('upload'),
        "delete-url-with-parameters": getUrl('delete'),
        "can-be-deleted": !_ctx.signatureApproved,
        "upload-icon": "fa fa-file-signature",
        onVueCropperOpen: _cache[0] || (_cache[0] = function (cropperOpen) {
          return emit('vueCropperOpen', cropperOpen);
        }),
        onImageUploadMissing: _cache[1] || (_cache[1] = function (m) {
          return updateIsImageUploadMissing(m);
        })
      }, null, 8 /* PROPS */, ["title", "file-token", "get-url-with-parameters", "upload-url-with-parameters", "delete-url-with-parameters", "can-be-deleted"])) : _createCommentVNode("v-if", true)]);
    };
  }
}));