import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  "class": "content-wrapper"
};
var _hoisted_2 = {
  "class": "content-table"
};
var _hoisted_3 = ["title", "href"];
var _hoisted_4 = ["title", "href"];
var _hoisted_5 = ["title", "onClick"];
var _hoisted_6 = {
  "class": "content"
};
var _hoisted_7 = {
  "class": "btn btn-primary mt-4",
  "data-popup": "form",
  href: "/workers/new"
};
import axios from 'axios';
import PaginatedTable from '../PaginatedTable.vue';

import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';
import { translate } from '/var/node/assets/js/utils/translate.ts';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'EmployeesView'
}), {}, {
  __name: 'EmployeesView',
  setup: function setup(__props) {
    var tableColumns = ['table.first_name', 'table.family_name', 'table.email', 'table.role', ''];
    var isBeingDeleted = ref([]);
    var _useQuery = useQuery({
        queryKey: ['/de/workers/'],
        initialData: []
      }),
      isSuccess = _useQuery.isSuccess,
      tableRows = _useQuery.data,
      refetch = _useQuery.refetch;
    var rowsFetched = computed(function () {
      return isSuccess.value || new Error();
    });
    // Type-fixed version of refetch for use in event listeners
    var refetchWorkers = function refetchWorkers() {
      refetch();
    };
    onMounted(function () {
      var _document$getElementB;
      (_document$getElementB = document.getElementById('main_modal')) === null || _document$getElementB === void 0 || _document$getElementB.addEventListener('hidden.bs.modal', refetchWorkers);
    });
    onUnmounted(function () {
      var _document$getElementB2;
      (_document$getElementB2 = document.getElementById('main_modal')) === null || _document$getElementB2 === void 0 || _document$getElementB2.removeEventListener('hidden.bs.modal', refetchWorkers);
    });
    var queryClient = useQueryClient();
    var _useMutation = useMutation({
        mutationKey: ['/workers/delete'],
        mutationFn: function mutationFn(userId) {
          return axios.get("/workers/delete/".concat(userId));
        },
        onSuccess: function onSuccess(_, userId) {
          queryClient.invalidateQueries({
            queryKey: ['/de/workers/']
          });
          isBeingDeleted.value[userId] = true;
          setTimeout(function () {
            isBeingDeleted.value = [];
          }, 300);
        }
      }),
      deleteUserMutation = _useMutation.mutate;
    var deleteUser = function deleteUser(userId, email) {
      if (userId && window.confirm(translate('trustee', 'table.confirm_delete', {
        '(user.email)': email
      }))) {
        deleteUserMutation(userId);
      }
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(PaginatedTable, {
        "table-name": "employees",
        "translation-file": "trustee",
        "is-paginated": true,
        "table-rows": _unref(tableRows),
        "table-columns": tableColumns,
        "rows-fetched": _unref(rowsFetched),
        "init-page-size": 5,
        "filter-on": ['table.first_name', 'table.family_name'],
        "place-holder-text": "Filtern nach Vorname, Nachname"
      }, {
        tableRow: _withCtx(function (_ref) {
          var tableRow = _ref.tableRow;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableColumns.slice(0, 4), function (tableColumn, index) {
            var _Object$values, _Object$values2;
            return _openBlock(), _createElementBlock("td", {
              key: index,
              "class": _normalizeClass({
                isBeingDeleted: _unref(isBeingDeleted)[tableRow['id']]
              })
            }, _toDisplayString(index === 3 ? ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('admin', (_Object$values = Object.values(tableRow)[index + 1]) === null || _Object$values === void 0 ? void 0 : _Object$values.toString()) : (_Object$values2 = Object.values(tableRow)[index + 1]) !== null && _Object$values2 !== void 0 ? _Object$values2 : ''), 3 /* TEXT, CLASS */);
          }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("td", {
            "class": _normalizeClass({
              isBeingDeleted: _unref(isBeingDeleted)[tableRow['id']]
            })
          }, [tableRow['canEdit'] ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            "data-toggle": "tooltip",
            title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'action.edit'),
            "data-popup": "form",
            href: "/workers/edit/".concat(tableRow['id'])
          }, _cache[0] || (_cache[0] = [_createElementVNode("i", {
            "class": "fal fa-pencil"
          }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true), tableRow['canChangePassword'] ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            "data-toggle": "tooltip",
            title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('trustee', 'navigation.change_password'),
            "data-popup": "form",
            href: "/workers/reset/password/".concat(tableRow['id'])
          }, _cache[1] || (_cache[1] = [_createElementVNode("i", {
            "class": "fal fa-key"
          }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true), tableRow['canDelete'] ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            "class": "delete",
            "data-toggle": "tooltip",
            title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'action.delete'),
            onClick: function onClick($event) {
              return deleteUser(tableRow['id'], tableRow['table.email']);
            }
          }, _cache[2] || (_cache[2] = [_createElementVNode("i", {
            "class": "far fa-trash-alt"
          }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["table-rows", "rows-fetched"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("h4", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('trustee', 'create_new_account')), 1 /* TEXT */), _createElementVNode("a", _hoisted_7, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('trustee', 'create')), 1 /* TEXT */)])]);
    };
  }
}));