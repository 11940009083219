import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { useWizardStep } from '/var/node/assets/js/composables/useWizardStep.ts';
export default /*@__PURE__*/_defineComponent({
  __name: 'JurPersonFormNatPersonStep',
  props: {
    errors: {},
    signerAtId: {},
    fileToken: {},
    isESignature: {
      type: Boolean
    },
    eSignUrl: {}
  },
  setup: function setup(__props) {
    var _useWizardStep = useWizardStep(),
      data = _useWizardStep.data,
      save = _useWizardStep.save,
      back = _useWizardStep.back;
    var updatedSigner = function updatedSigner(signer) {
      var _data$value$jurPerson, _data$value$jurPerson2;
      var signers = (_data$value$jurPerson = (_data$value$jurPerson2 = data.value.jurPerson) === null || _data$value$jurPerson2 === void 0 ? void 0 : _data$value$jurPerson2.signers) !== null && _data$value$jurPerson !== void 0 ? _data$value$jurPerson : [];
      if (signers.find(function (s) {
        return s['@id'] === signer['@id'];
      })) {
        data.value.jurPerson.signers = signers.map(function (s) {
          return s['@id'] === signer['@id'] ? signer : s;
        });
      } else {
        data.value.jurPerson.signers = signers.concat(signer);
      }
      save();
    };
    return function (_ctx, _cache) {
      var _component_ProcessStepJurPersonSignerForm = _resolveComponent("ProcessStepJurPersonSignerForm");
      return _openBlock(), _createBlock(_component_ProcessStepJurPersonSignerForm, {
        "file-token": _ctx.fileToken,
        "jur-person-at-id": _unref(data).jurPerson['@id'],
        "is-e-signature": _ctx.isESignature,
        "jur-person-signer-at-id": _ctx.signerAtId,
        "e-sign-url": _ctx.eSignUrl,
        onUpdated: updatedSigner,
        onBack: _unref(back)
      }, null, 8 /* PROPS */, ["file-token", "jur-person-at-id", "is-e-signature", "jur-person-signer-at-id", "e-sign-url", "onBack"]);
    };
  }
});