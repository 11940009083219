import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'SortIcon',
  props: {
    order: {
      type: [Boolean, String],
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var icon = computed(function () {
      var _asc$desc$none;
      return (_asc$desc$none = {
        asc: ['far', 'sort-up'],
        desc: ['far', 'sort-down'],
        none: ['far', 'sort']
      }[props.order || 'none']) !== null && _asc$desc$none !== void 0 ? _asc$desc$none : ['far', 'sort'];
    });
    return function (_ctx, _cache) {
      var _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon");
      return _openBlock(), _createBlock(_component_FontAwesomeIcon, {
        icon: _unref(icon),
        size: "sm"
      }, null, 8 /* PROPS */, ["icon"]);
    };
  }
});