function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "me-1"
};
import CalloutType from '@/enums/CalloutType';

import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'Callout'
}), {}, {
  __name: 'Callout',
  props: {
    type: {
      "default": CalloutType.INFO
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var typeStyles = computed(function () {
      var _mapTypeToCss$props$t;
      var mapTypeToCss = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, CalloutType.DANGER, 'bg-red-light border-red text-red-dark'), CalloutType.WARNING, 'bg-yellow-light border-yellow text-yellow'), CalloutType.SUCCESS, 'bg-green-light border-green text-green'), CalloutType.INFO, 'bg-blue-light border-blue text-blue');
      return (_mapTypeToCss$props$t = mapTypeToCss[props.type]) !== null && _mapTypeToCss$props$t !== void 0 ? _mapTypeToCss$props$t : mapTypeToCss[CalloutType.INFO];
    });
    var faIcon = computed(function () {
      var _mapTypeToFaIcon$prop;
      var mapTypeToFaIcon = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, CalloutType.DANGER, 'far fa-exclamation-circle'), CalloutType.WARNING, 'far fa-exclamation-circle'), CalloutType.SUCCESS, 'far fa-check-circle'), CalloutType.INFO, 'far fa-info-circle');
      return (_mapTypeToFaIcon$prop = mapTypeToFaIcon[props.type]) !== null && _mapTypeToFaIcon$prop !== void 0 ? _mapTypeToFaIcon$prop : mapTypeToFaIcon[CalloutType.INFO];
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        "class": _normalizeClass(["p-4 mb-4 border rounded", _unref(typeStyles)])
      }, [_createElementVNode("span", _hoisted_1, [_createElementVNode("i", {
        "class": _normalizeClass(_unref(faIcon))
      }, null, 2 /* CLASS */)]), _renderSlot(_ctx.$slots, "body")], 2 /* CLASS */);
    };
  }
}));