function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  key: 1,
  "class": "invalid-feedback block"
};
var _hoisted_2 = {
  "class": "form-error-message"
};
import PersonOrigin from './PersonOrigin.vue';
import { translate } from '@/js/frontend/filters';
import Nationality from '@/enums/Nationality';

import { useVModel } from '@vueuse/core';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'PersonOrigins'
}), {}, {
  __name: 'PersonOrigins',
  props: {
    modelValue: {
      "default": function _default() {
        return [];
      }
    },
    personFormPrefix: {
      "default": ''
    },
    token: {},
    uploadOnly: {
      type: Boolean,
      "default": false
    },
    isESignature: {
      type: Boolean,
      "default": false
    },
    errors: {
      "default": function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var origins = useVModel(props, 'modelValue', emit, {
      deep: true,
      passive: true
    });
    var addOrigin = function addOrigin() {
      origins.value = origins.value.concat({
        nationality: Nationality.SCHWEIZ,
        hometown: undefined,
        identificationMethod: undefined
      });
    };
    var editOrigin = function editOrigin(newValue, index) {
      var copy = origins.value.slice();
      copy[index] = newValue;
      origins.value = copy;
    };
    var deleteOrigin = function deleteOrigin(index) {
      var copy = origins.value.slice();
      copy.splice(index, 1);
      origins.value = copy;
    };
    return function (_ctx, _cache) {
      var _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
      return _ctx.loading ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
        key: 0
      })) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createVNode(_TransitionGroup, {
        name: "list"
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(origins), function (origin, index) {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              "class": "bordered mb-4"
            }, [_createVNode(PersonOrigin, {
              index: index,
              "model-value": origin,
              token: _ctx.token,
              "person-form-prefix": _ctx.personFormPrefix,
              "upload-only": _ctx.uploadOnly,
              "is-e-signature": _ctx.isESignature,
              errors: typeof _ctx.errors !== 'string' ? _ctx.errors[index] : undefined,
              onDeleteOrigin: function onDeleteOrigin() {
                return deleteOrigin(index);
              },
              "onUpdate:modelValue": function onUpdateModelValue(newValue) {
                return editOrigin(newValue, index);
              }
            }, null, 8 /* PROPS */, ["index", "model-value", "token", "person-form-prefix", "upload-only", "is-e-signature", "errors", "onDeleteOrigin", "onUpdate:modelValue"])]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }), !_ctx.uploadOnly ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        "class": "btn btn-primary w-100",
        onClick: addOrigin
      }, [_cache[0] || (_cache[0] = _createElementVNode("i", {
        "class": "far fa-plus"
      }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString(_unref(translate)('nat_person', 'fields.add_more_origins')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), typeof _ctx.errors === 'string' ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.errors || _unref(translate)('messages', 'error_person_controller.hometown_invalid_format')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */));
    };
  }
}));