import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup, normalizeClass as _normalizeClass, isRef as _isRef, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  "class": "content-wrapper"
};
var _hoisted_2 = {
  "class": "content"
};
var _hoisted_3 = {
  "class": "header"
};
var _hoisted_4 = {
  "class": "documents-list"
};
var _hoisted_5 = ["href", "aria-label"];
var _hoisted_6 = {
  "class": "flex flex-row mb-12 gap-2"
};
var _hoisted_7 = ["href"];
var _hoisted_8 = ["title"];
var _hoisted_9 = {
  "class": "form-check-label",
  "for": "documents-approved-checkbox"
};
import axios from 'axios';
import Callout from '@/components/general/Callout.vue';
import CalloutType from '@/enums/CalloutType';
import useProcessReopen from '@/ts/VueCompositions/UseProcessReopen';
import useButtonState from '@/ts/VueCompositions/UseButtonState';
import { getLocalizedUrl } from '@/js/frontend/helpers';

import { toRefs, ref, watch } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
export default /*@__PURE__*/_defineComponent({
  __name: 'TrusteeApproval',
  props: {
    processId: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var _toRefs = toRefs(props),
      processId = _toRefs.processId;
    var documentList = ref([]);
    var canBeReopened = ref(true);
    var confirmationCheckbox = ref(false);
    var errorMessage = ref('');
    var _useProcessReopen = useProcessReopen(processId.value),
      reopenProcess = _useProcessReopen.reopenProcess;
    var _useQuery = useQuery({
        queryKey: ["/api/company_processes/".concat(processId.value)]
      }),
      isLoadingDocuments = _useQuery.isLoading,
      companyProcessQueryData = _useQuery.data;
    watch(companyProcessQueryData, function (process) {
      if (process !== undefined) {
        documentList.value = process.latestDocuments;
        canBeReopened.value = process.canBeReopened;
      }
    });
    var _useMutation = useMutation({
        mutationFn: function mutationFn() {
          return axios.post(getLocalizedUrl("/process/".concat(processId.value, "/e_signature/trustee_approve_action/json")), {
            confirmationCheckbox: confirmationCheckbox.value
          });
        },
        onError: function onError(err) {
          var _err$response, _err$response2;
          errorMessage.value = ((_err$response = err.response) === null || _err$response === void 0 || (_err$response = _err$response.data) === null || _err$response === void 0 ? void 0 : _err$response.detail) !== undefined ? (_err$response2 = err.response) === null || _err$response2 === void 0 || (_err$response2 = _err$response2.data) === null || _err$response2 === void 0 ? void 0 : _err$response2.detail : err.message;
        },
        onSuccess: function onSuccess(response) {
          var redirectUrl = response.data.redirectUrl;
          if (redirectUrl !== undefined) {
            setTimeout(function () {
              window.location = redirectUrl;
            }, 1000);
          }
        }
      }),
      isMarkDocumentsAsCheckedError = _useMutation.isError,
      isMarkingDocumentsAsChecked = _useMutation.isPending,
      isMarkDocumentsAsCheckedSuccess = _useMutation.isSuccess,
      markDocumentsAsChecked = _useMutation.mutate;
    var _useButtonState = useButtonState(isMarkingDocumentsAsChecked, isMarkDocumentsAsCheckedError, isMarkDocumentsAsCheckedSuccess),
      buttonState = _useButtonState.buttonState;
    return function (_ctx, _cache) {
      var _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
      var _component_ButtonBootstrap = _resolveComponent("ButtonBootstrap");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h3", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.approve_documents')), 1 /* TEXT */)]), _createElementVNode("p", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.step_one')), 1 /* TEXT */), _createVNode(Callout, {
        type: _unref(CalloutType).WARNING
      }, {
        body: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.do_not_sign_manually')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"]), _unref(isLoadingDocuments) ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
        key: 0,
        "spinner-small": false,
        "class": "m-12"
      })) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createElementVNode("ul", _hoisted_4, [_createVNode(_TransitionGroup, {
        name: "list"
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(documentList), function (document) {
            return _openBlock(), _createElementBlock("li", {
              key: document.id
            }, [_createElementVNode("a", {
              download: "",
              target: "_blank",
              rel: "noopener noreferrer",
              href: "/document/".concat(document.publicId, "/").concat(document.translatedName, ".pdf"),
              "aria-label": "".concat(document.translatedName, " herunterladen")
            }, [_createTextVNode(" [" + _toDisplayString(document.nr) + "] " + _toDisplayString(document.translatedName) + " ", 1 /* TEXT */), _cache[2] || (_cache[2] = _createElementVNode("i", {
              "class": "fa fa-file-pdf"
            }, null, -1 /* HOISTED */))], 8 /* PROPS */, _hoisted_5)]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      })]), _createElementVNode("div", _hoisted_6, [_createElementVNode("a", {
        download: "",
        href: "/process/".concat(_unref(processId), "/documents_combined"),
        target: "_blank",
        rel: "noopener noreferrer",
        "class": "btn btn-light"
      }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.download_docs')), 9 /* TEXT, PROPS */, _hoisted_7), _createElementVNode("button", {
        type: "button",
        "class": _normalizeClass([_unref(canBeReopened) ? '' : 'disabled', "btn btn-light"]),
        title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'action.reopen'),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _unref(canBeReopened) ? _unref(reopenProcess)() : '';
        })
      }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'action.reopen')), 11 /* TEXT, CLASS, PROPS */, _hoisted_8)]), _withDirectives(_createElementVNode("input", {
        id: "documents-approved-checkbox",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _isRef(confirmationCheckbox) ? confirmationCheckbox.value = $event : null;
        }),
        name: "documents-approved-checkbox",
        "class": "form-check-input",
        type: "checkbox",
        required: "required"
      }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, _unref(confirmationCheckbox)]]), _createElementVNode("label", _hoisted_9, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.confirm_docs_checkbox')), 1 /* TEXT */), _unref(isMarkDocumentsAsCheckedError) && _unref(errorMessage).length > 0 ? (_openBlock(), _createBlock(Callout, {
        key: 0,
        "class": "mt-4",
        type: _unref(CalloutType).DANGER
      }, {
        body: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_unref(errorMessage)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createVNode(_component_ButtonBootstrap, {
        id: "approve-documents",
        "class": "mt-4",
        disabled: !_unref(confirmationCheckbox),
        "on-click": _unref(markDocumentsAsChecked),
        "button-state": _unref(buttonState),
        "success-text": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.approve_docs.button_success'),
        "default-text": ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'e_signature.approve_docs.button')
      }, null, 8 /* PROPS */, ["disabled", "on-click", "button-state", "success-text", "default-text"])], 64 /* STABLE_FRAGMENT */))])]);
    };
  }
});