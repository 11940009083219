function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "content-wrapper"
};
var _hoisted_2 = {
  "class": "services"
};
var _hoisted_3 = {
  "class": "mt-12 flex justify-center"
};
var _hoisted_4 = {
  "class": "content col-sm-12"
};
var _hoisted_5 = ["href"];
import MutationOptions from './MutationOptions.vue';
import FoundingOptions from './FoundingOptions.vue';
import { translate } from '@/js/frontend/filters';
import OptionCategories from '@/enums/OptionCategories';
import { getLocalizedUrl } from '@/js/frontend/helpers';

import { ref, onMounted } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'Services'
}), {}, {
  __name: 'ServicesView',
  props: {
    permissions: {}
  },
  setup: function setup(__props) {
    var foundingOptions = ref();
    var mutationOptions = ref();
    onMounted(function () {
      var _foundingOptions$valu, _mutationOptions$valu;
      (_foundingOptions$valu = foundingOptions.value) === null || _foundingOptions$valu === void 0 || _foundingOptions$valu.resetFields();
      (_mutationOptions$valu = mutationOptions.value) === null || _mutationOptions$valu === void 0 || _mutationOptions$valu.resetFields();
    });
    var optionSelected = function optionSelected(category) {
      if (category === OptionCategories.MUTATION_OPTIONS || category === OptionCategories.MUTATION_OPTIONS_SPECIAL) {
        var _foundingOptions$valu2;
        (_foundingOptions$valu2 = foundingOptions.value) === null || _foundingOptions$valu2 === void 0 || _foundingOptions$valu2.resetFields();
      } else {
        var _mutationOptions$valu2;
        (_mutationOptions$valu2 = mutationOptions.value) === null || _mutationOptions$valu2 === void 0 || _mutationOptions$valu2.resetFields();
      }
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(MutationOptions, {
        ref_key: "mutationOptions",
        ref: mutationOptions,
        permissions: _ctx.permissions,
        onOptionSelected: _cache[0] || (_cache[0] = function (c) {
          return optionSelected(c);
        })
      }, null, 8 /* PROPS */, ["permissions"]), _createVNode(FoundingOptions, {
        ref_key: "foundingOptions",
        ref: foundingOptions,
        "class": "mt-12",
        permissions: _ctx.permissions,
        onOptionSelected: _cache[1] || (_cache[1] = function (c) {
          return optionSelected(c);
        })
      }, null, 8 /* PROPS */, ["permissions"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h4", null, _toDisplayString(_unref(translate)('messages', 'actions.individual_offer')), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString(_unref(translate)('messages', 'individual.offer')), 1 /* TEXT */), _createElementVNode("a", {
        "class": "btn btn-light",
        href: _unref(getLocalizedUrl)('/trustee/custom/form?type=Ich%20suche%20etwas%20anderes...'),
        target: "_blank",
        rel: "noopener noreferrer"
      }, _toDisplayString(_unref(translate)('messages', 'actions.individual_offer')), 9 /* TEXT, PROPS */, _hoisted_5)])])])]);
    };
  }
}));