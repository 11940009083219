import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";

import { toRefs, computed } from 'vue';
var _hoisted_1 = {
  "class": "break-words border border-1 border-gray-300 rounded"
};
var _hoisted_2 = {
  "class": "flex flex-row justify-between items-center flex-auto p-6"
};
var _hoisted_3 = {
  "class": "flex flex-col flex-no-wrap"
};
var _hoisted_4 = {
  key: 0
};
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'DomicileHolder'
}), {}, {
  __name: 'DomicileHolder',
  props: {
    domicileHolder: {},
    fileToken: {},
    processId: {},
    isESignature: {
      type: Boolean
    },
    stepId: {},
    eSignUrl: {},
    initialErrors: {
      "default": undefined
    }
  },
  emits: ['removeDomicileHolder', 'domicileHolderChanged'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var _toRefs = toRefs(props),
      domicileHolder = _toRefs.domicileHolder;
    var emit = __emit;
    var signerNamesList = computed(function () {
      return !domicileHolder.value.isNatPerson ? domicileHolder.value.activeSigners.map(function (s) {
        var _s$person;
        return (_s$person = s.person) === null || _s$person === void 0 ? void 0 : _s$person.fullName;
      }).join(', ') : '';
    });
    return function (_ctx, _cache) {
      var _unref$companyName, _unref$activeSigners$, _unref$activeSigners;
      var _component_EditPersonButton = _resolveComponent("EditPersonButton");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString((_unref$companyName = _unref(domicileHolder).companyName) !== null && _unref$companyName !== void 0 ? _unref$companyName : "".concat(_unref(domicileHolder).firstname, " ").concat(_unref(domicileHolder).surname)), 1 /* TEXT */), !_unref(domicileHolder).isNatPerson && 0 < ((_unref$activeSigners$ = (_unref$activeSigners = _unref(domicileHolder).activeSigners) === null || _unref$activeSigners === void 0 ? void 0 : _unref$activeSigners.length) !== null && _unref$activeSigners$ !== void 0 ? _unref$activeSigners$ : 0) ? (_openBlock(), _createElementBlock("small", _hoisted_4, [_createTextVNode(_toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'general.jur_person.represented_by')) + " ", 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(_unref(signerNamesList)), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [_createVNode(_component_EditPersonButton, {
        person: _unref(domicileHolder),
        "file-token": _ctx.fileToken,
        "process-id": _ctx.processId,
        "is-e-signature": _ctx.isESignature,
        "step-id": _ctx.stepId,
        "e-sign-url": _ctx.eSignUrl,
        "initial-errors": _ctx.initialErrors,
        onUpdated: _cache[0] || (_cache[0] = function (newPerson) {
          emit('domicileHolderChanged', newPerson);
        })
      }, null, 8 /* PROPS */, ["person", "file-token", "process-id", "is-e-signature", "step-id", "e-sign-url", "initial-errors"]), _createElementVNode("a", {
        "class": "card-layout delete",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return emit('removeDomicileHolder');
        })
      }, _cache[2] || (_cache[2] = [_createElementVNode("i", {
        "class": "far fa-trash-alt"
      }, null, -1 /* HOISTED */)]))])])]);
    };
  }
}));