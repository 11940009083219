import { readonly as __unimport_readonly, unref as __unimport_unref_ } from 'vue';import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives, unref as _unref, vShow as _vShow, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "form-group"
};
var _hoisted_2 = ["for"];
var _hoisted_3 = ["name"];
import Tooltip from '@/components/general/Tooltip.vue';

import { useFormErrorMessage } from '/var/node/assets/js/composables/useFormErrorMessage.ts';
import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownField',
  props: /*@__PURE__*/_mergeModels({
    name: {},
    label: {},
    error: {},
    readonly: {
      type: Boolean
    },
    tooltip: {}
  }, {
    "modelValue": {},
    "modelModifiers": {}
  }),
  emits: ["update:modelValue"],
  setup: function setup(__props) {
    var props = __props;
    var modelValue = _useModel(__props, "modelValue");
    var _useFormErrorMessage = useFormErrorMessage({
        name: computed(function () {
          return props.name;
        }),
        error: computed(function () {
          return props.error;
        }),
        watch: modelValue
      }),
      errorMessage = _useFormErrorMessage.message;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", {
        "for": _ctx.name
      }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_2), _ctx.tooltip ? (_openBlock(), _createBlock(Tooltip, {
        key: 0,
        title: _ctx.tooltip.title,
        text: _ctx.tooltip.text
      }, null, 8 /* PROPS */, ["title", "text"])) : _createCommentVNode("v-if", true), _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return modelValue.value = $event;
        }),
        name: _ctx.name,
        "class": _normalizeClass(["form-select", {
          errorMessage: 'is-invalid',
          'pointer-events-none bg-gray-light': !!("readonly" in _ctx ? _ctx.readonly : __unimport_unref_(__unimport_readonly))
        }])
      }, [_renderSlot(_ctx.$slots, "options")], 10 /* CLASS, PROPS */, _hoisted_3), [[_vModelSelect, modelValue.value]]), _createVNode(_Transition, {
        name: "appear",
        persisted: ""
      }, {
        "default": _withCtx(function () {
          return [_withDirectives(_createElementVNode("div", {
            "class": "invalid-feedback block"
          }, _toDisplayString(_unref(errorMessage)), 513 /* TEXT, NEED_PATCH */), [[_vShow, _unref(errorMessage)]])];
        }),
        _: 1 /* STABLE */
      })]);
    };
  }
});