function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

import { ref, onMounted } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'LanguageSelector'
}), {}, {
  __name: 'LanguageSelectorFancy',
  emits: ['languageChanged'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    var initialLanguage = ref('');
    var language = ref('');
    var open = ref(false);
    var availableLanguages = ['de', 'en'];
    var languageChanged = function languageChanged(newLanguage) {
      emit('languageChanged', initialLanguage.value, newLanguage);
      window.location.replace(window.location.href.replace("/".concat(initialLanguage.value, "/"), "/".concat(newLanguage, "/")));
    };
    onMounted(function () {
      var _window$location$path = window.location.pathname.split('/');
      var _window$location$path2 = _slicedToArray(_window$location$path, 2);
      initialLanguage.value = _window$location$path2[1];
      language.value = initialLanguage.value;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(RadioGroup), {
        "model-value": _unref(language),
        "onUpdate:modelValue": languageChanged,
        "class": "flex rounded-lg cursor-default bg-white text-blue-dark border-1.5 border-blue-dark mb-[5px] mr-[5px] !p-0"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", {
            style: _normalizeStyle({
              '--locale-count': availableLanguages.length
            }),
            "class": _normalizeClass(['flex max-w-0 overflow-hidden items-center transition-[max-width] duration-[0.3s] ease-in-out', _unref(open) && "max-w-[calc(var(--locale-count)_*_(2ch_+_16px)_+_8px)]"])
          }, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(availableLanguages, function (locale) {
            return _createVNode(_unref(RadioGroupOption), {
              key: locale,
              value: locale,
              "class": "uppercase font-bold text-gray py-[10px] px-2 first-of-type:ml-2 cursor-pointer hover:!text-blue"
            }, {
              "default": _withCtx(function (_ref2) {
                var checked = _ref2.checked;
                return [_createElementVNode("span", {
                  "class": _normalizeClass(checked ? 'text-blue-dark' : '')
                }, _toDisplayString(locale), 3 /* TEXT, CLASS */)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
          }), 64 /* STABLE_FRAGMENT */))], 6 /* CLASS, STYLE */), _createVNode(_unref(RadioGroupLabel), {
            as: "button",
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return open.value = !_unref(open);
            }),
            "class": "rounded-lg px-[15px] py-[10px] cursor-pointer hover:bg-blue-dark hover:text-white"
          }, {
            "default": _withCtx(function () {
              return _cache[1] || (_cache[1] = [_createElementVNode("i", {
                "class": "far fa-globe"
              }, null, -1 /* HOISTED */)]);
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model-value"]);
    };
  }
}));