function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { cloneDeep } from 'lodash-es';
import FunctionAndSignatureForm from '@/components/personForm/FunctionAndSignatureForm.vue';
import ShabexFunction from '@/enums/ShabexFunction';

import { useVModel } from '@vueuse/core';
import { computed, toRefs, unref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
export default /*@__PURE__*/_defineComponent({
  __name: 'InvolvedPersonForm',
  props: {
    modelValue: {},
    processId: {},
    stepId: {},
    errors: {
      "default": undefined
    },
    isHeadquarterPerson: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var internalInvolvedPerson = useVModel(props, 'modelValue', emit, {
      clone: cloneDeep,
      deep: true,
      passive: true
    });
    var currentFunctions = computed({
      get: function get() {
        var _internalInvolvedPers, _internalInvolvedPers2;
        return (_internalInvolvedPers = (_internalInvolvedPers2 = internalInvolvedPerson.value.functions) === null || _internalInvolvedPers2 === void 0 ? void 0 : _internalInvolvedPers2.map(function (f) {
          return f["function"];
        })) !== null && _internalInvolvedPers !== void 0 ? _internalInvolvedPers : [];
      },
      set: function set(value) {
        internalInvolvedPerson.value.functions = value.map(function (functionText) {
          return {
            "function": functionText
          };
        });
      }
    });
    var _toRefs = toRefs(props),
      processId = _toRefs.processId,
      stepId = _toRefs.stepId;
    var _useQuery = useQuery({
        queryKey: [computed(function () {
          var _props$isHeadquarterP;
          return "/api/company_processes/".concat(unref(processId), "/step/").concat(unref(stepId), "/function_and_signature_choices?isHeadquarterPerson=").concat((_props$isHeadquarterP = props.isHeadquarterPerson) !== null && _props$isHeadquarterP !== void 0 ? _props$isHeadquarterP : false);
        })]
      }),
      functionAndSignatureChoices = _useQuery.data,
      isLoading = _useQuery.isLoading;
    watch(functionAndSignatureChoices, function (newValue) {
      if (newValue !== undefined) {
        if (internalInvolvedPerson.value.functions === undefined) {
          internalInvolvedPerson.value.functions = newValue.defaultFunctions.map(function (f) {
            return {
              "function": f
            };
          });
        }
        if (internalInvolvedPerson.value.signature === undefined) {
          internalInvolvedPerson.value.signature = newValue.defaultSignature;
        }
      }
    }, {
      immediate: true
    });
    // make sure that function from the loaded invPerson can be chosen (Shabex might have delivered wrong stuff)
    var possibleFunctions = computed(function () {
      var _functionAndSignature, _functionAndSignature2, _internalInvolvedPers3, _internalInvolvedPers4;
      return _toConsumableArray(new Set(((_functionAndSignature = (_functionAndSignature2 = functionAndSignatureChoices.value) === null || _functionAndSignature2 === void 0 ? void 0 : _functionAndSignature2.functionChoices) !== null && _functionAndSignature !== void 0 ? _functionAndSignature : []).concat((_internalInvolvedPers3 = (_internalInvolvedPers4 = internalInvolvedPerson.value.functions) === null || _internalInvolvedPers4 === void 0 ? void 0 : _internalInvolvedPers4.map(function (f) {
        return f["function"];
      }).filter(function (f) {
        return f !== undefined && f !== ShabexFunction.GESELLSCHAFTER;
      })) !== null && _internalInvolvedPers3 !== void 0 ? _internalInvolvedPers3 : [])));
    });
    // make sure that the signature of the loaded invPerson can be chosen (Shabex might have delivered wrong stuff)
    var possibleSignatures = computed(function () {
      var _functionAndSignature3, _functionAndSignature6, _functionAndSignature7;
      if (internalInvolvedPerson.value.signature !== undefined && ((_functionAndSignature3 = functionAndSignatureChoices.value) === null || _functionAndSignature3 === void 0 || (_functionAndSignature3 = _functionAndSignature3.signatureChoices) === null || _functionAndSignature3 === void 0 ? void 0 : _functionAndSignature3.find(function (s) {
        return s === internalInvolvedPerson.value.signature;
      })) === undefined) {
        var _functionAndSignature4, _functionAndSignature5;
        return (_functionAndSignature4 = (_functionAndSignature5 = functionAndSignatureChoices.value) === null || _functionAndSignature5 === void 0 || (_functionAndSignature5 = _functionAndSignature5.signatureChoices) === null || _functionAndSignature5 === void 0 ? void 0 : _functionAndSignature5.concat(internalInvolvedPerson.value.signature)) !== null && _functionAndSignature4 !== void 0 ? _functionAndSignature4 : [];
      }
      return (_functionAndSignature6 = (_functionAndSignature7 = functionAndSignatureChoices.value) === null || _functionAndSignature7 === void 0 ? void 0 : _functionAndSignature7.signatureChoices) !== null && _functionAndSignature6 !== void 0 ? _functionAndSignature6 : [];
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(FunctionAndSignatureForm, {
        functions: _unref(currentFunctions),
        "onUpdate:functions": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(currentFunctions) ? currentFunctions.value = $event : null;
        }),
        signature: _unref(internalInvolvedPerson).signature,
        "onUpdate:signature": _cache[1] || (_cache[1] = function ($event) {
          return _unref(internalInvolvedPerson).signature = $event;
        }),
        "possible-functions": _unref(possibleFunctions),
        "possible-signatures": _unref(possibleSignatures),
        errors: _ctx.errors,
        loading: _unref(isLoading)
      }, null, 8 /* PROPS */, ["functions", "signature", "possible-functions", "possible-signatures", "errors", "loading"]);
    };
  }
});