import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "content-wrapper"
};
var _hoisted_2 = {
  "class": "content"
};
var _hoisted_3 = {
  "class": "header flex flex-row justify-between flex-wrap-reverse"
};
import FormEditTrustee from '@/components/companyView/FormEditTrustee.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'InfoView',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h3", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('trustee', 'navigation.details')), 1 /* TEXT */)]), _createVNode(FormEditTrustee)])]);
    };
  }
});