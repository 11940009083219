import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";

import { isObject } from '/var/node/assets/js/utils/helpers.ts';
import { ref, computed } from 'vue';
import { useFormErrorMessage } from '/var/node/assets/js/composables/useFormErrorMessage.ts';
var _hoisted_1 = {
  "class": "block truncate font-normal ui-selected:font-bold"
};
var _hoisted_2 = {
  "class": "hidden ui-selected:flex absolute inset-y-0 right-0 items-center pr-3 text-blue"
};
export default /*@__PURE__*/_defineComponent({
  __name: 'Combobox',
  props: /*@__PURE__*/_mergeModels({
    options: {
      "default": function _default() {
        return [];
      }
    },
    name: {
      "default": undefined
    },
    label: {
      "default": undefined
    },
    size: {
      "default": 'medium'
    },
    placeholder: {
      "default": ''
    },
    error: {
      "default": undefined
    },
    getOptionKey: {
      type: Function,
      "default": function _default(option) {
        var _option$id;
        return isObject(option) ? (_option$id = option.id) !== null && _option$id !== void 0 ? _option$id : JSON.stringify(option) : option;
      }
    },
    getOptionLabel: {
      type: Function,
      "default": function _default(option) {
        var _option$label;
        return isObject(option) ? (_option$label = option.label) !== null && _option$label !== void 0 ? _option$label : JSON.stringify(option) : option !== null && option !== void 0 ? option : '';
      }
    },
    getOptionIsDisabled: {
      type: Function,
      "default": function _default(option) {
        var _option$disabled;
        return isObject(option) ? (_option$disabled = option.disabled) !== null && _option$disabled !== void 0 ? _option$disabled : false : false;
      }
    },
    nullable: {
      type: Boolean,
      "default": false
    },
    manualFiltering: {
      type: Boolean,
      "default": false
    }
  }, {
    "modelValue": {
      "default": null
    },
    "modelModifiers": {},
    "query": {
      local: true,
      "default": ''
    },
    "queryModifiers": {}
  }),
  emits: ["update:modelValue", "update:query"],
  setup: function setup(__props) {
    var props = __props;
    var modelValue = _useModel(__props, "modelValue");
    var query = _useModel(__props, 'query');
    var element = ref();
    var closestParent = computed(function () {
      var _element$value$$el$pa, _element$value;
      return (_element$value$$el$pa = (_element$value = element.value) === null || _element$value === void 0 ? void 0 : _element$value.$el.parentElement.closest('body, dialog')) !== null && _element$value$$el$pa !== void 0 ? _element$value$$el$pa : 'body';
    });
    var filteredOptions = computed(function () {
      return props.manualFiltering || query.value === '' ? props.options : props.options.filter(function (option) {
        return props.getOptionLabel(option).toString().toLowerCase().includes(query.value.toLowerCase());
      });
    });
    var _useFormErrorMessage = useFormErrorMessage({
        name: computed(function () {
          return props.name;
        }),
        error: computed(function () {
          return props.error;
        }),
        watch: modelValue
      }),
      errorMessage = _useFormErrorMessage.message;
    function compareOptions(a, b) {
      return a && b && props.getOptionKey(a) === props.getOptionKey(b);
    }
    return function (_ctx, _cache) {
      var _component_HeadlessComboboxLabel = _resolveComponent("HeadlessComboboxLabel");
      var _component_HeadlessComboboxInput = _resolveComponent("HeadlessComboboxInput");
      var _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon");
      var _component_HeadlessComboboxButton = _resolveComponent("HeadlessComboboxButton");
      var _component_HeadlessComboboxOption = _resolveComponent("HeadlessComboboxOption");
      var _component_HeadlessComboboxOptions = _resolveComponent("HeadlessComboboxOptions");
      var _component_VDropdown = _resolveComponent("VDropdown");
      var _component_HeadlessCombobox = _resolveComponent("HeadlessCombobox");
      return _openBlock(), _createBlock(_component_HeadlessCombobox, {
        ref_key: "element",
        ref: element,
        modelValue: modelValue.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return modelValue.value = $event;
        }),
        nullable: _ctx.nullable,
        by: compareOptions
      }, {
        "default": _withCtx(function (_ref) {
          var open = _ref.open;
          return [_createVNode(_component_VDropdown, {
            triggers: [],
            shown: open,
            "auto-hide": false,
            distance: 4,
            placement: "bottom-start",
            "auto-size": "min",
            container: _unref(closestParent)
          }, {
            popper: _withCtx(function () {
              return [_createVNode(_component_HeadlessComboboxOptions, {
                "static": "",
                "class": _normalizeClass(["max-h-60 overflow-auto rounded-md bg-white py-1 drop-shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none", {
                  'text-base sm:text-sm': _ctx.size === 'small',
                  'text-xl sm:text-base': _ctx.size === 'medium'
                }])
              }, {
                "default": _withCtx(function () {
                  return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredOptions), function (option) {
                    return _openBlock(), _createBlock(_component_HeadlessComboboxOption, {
                      key: _ctx.getOptionKey(option).toString(),
                      value: option,
                      disabled: _ctx.getOptionIsDisabled(option),
                      "class": "relative cursor-default select-none py-2 px-4 ui-selected:pr-8 text-blue-dark ui-active:bg-blue-dark/5 ui-disabled:opacity-30"
                    }, {
                      "default": _withCtx(function () {
                        return [_createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.getOptionLabel(option)), 1 /* TEXT */), _createElementVNode("span", _hoisted_2, [_createVNode(_component_FontAwesomeIcon, {
                          icon: ['far', 'check']
                        })])];
                      }),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "disabled"]);
                  }), 128 /* KEYED_FRAGMENT */))];
                }),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["class"])];
            }),
            "default": _withCtx(function () {
              return [_ctx.label ? (_openBlock(), _createBlock(_component_HeadlessComboboxLabel, {
                key: 0,
                "class": _normalizeClass(["block font-bold", {
                  'mb-1 text-sm': _ctx.size === 'small',
                  'mb-2 text-base': _ctx.size === 'medium'
                }])
              }, {
                "default": _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)];
                }),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
                "class": _normalizeClass(["relative w-full cursor-default overflow-hidden bg-white border-1.5 rounded-lg transition", [_unref(errorMessage) ? 'border-red' : 'border-blue-dark']])
              }, [_createVNode(_component_HeadlessComboboxInput, {
                "class": _normalizeClass(["w-full border-none pl-4 pr-8 rounded-[calc(theme(borderRadius.lg)-2px)] text-left font-semibold focus-visible:-outline-offset-2 placeholder:text-blue-dark/50 transition-all", [{
                  'text-base': _ctx.size === 'small',
                  'text-xl': _ctx.size === 'medium'
                }, _unref(errorMessage) ? 'pt-2.5 pb-4.5' : 'py-3.5']]),
                placeholder: _ctx.placeholder,
                "display-value": function displayValue(option) {
                  return _ctx.getOptionLabel(option).toString();
                },
                onChange: _cache[0] || (_cache[0] = function ($event) {
                  return query.value = $event.target.value;
                })
              }, null, 8 /* PROPS */, ["class", "placeholder", "display-value"]), _createVNode(_component_HeadlessComboboxButton, {
                "class": "absolute inset-y-0 right-0 flex items-center pl-2 pr-2.5"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_FontAwesomeIcon, {
                    icon: ['far', 'chevron-down'],
                    "class": "transition-transform ui-open:rotate-180"
                  })];
                }),
                _: 1 /* STABLE */
              }), _createVNode(_Transition, {
                name: "appear",
                persisted: ""
              }, {
                "default": _withCtx(function () {
                  return [_withDirectives(_createElementVNode("p", {
                    "class": "pointer-events-none absolute bottom-1 left-4 text-xs text-red duration-150"
                  }, _toDisplayString(_unref(errorMessage)), 513 /* TEXT, NEED_PATCH */), [[_vShow, _unref(errorMessage)]])];
                }),
                _: 1 /* STABLE */
              })], 2 /* CLASS */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["shown", "container"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "nullable"]);
    };
  }
});