function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelSelect as _vModelSelect, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue";
var _hoisted_1 = {
  "class": "filter"
};
var _hoisted_2 = ["placeholder"];
var _hoisted_3 = {
  "class": "flex"
};
var _hoisted_4 = {
  "class": "pagination"
};
var _hoisted_5 = {
  key: 0,
  "class": "page-item"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  "class": "page-link"
};
var _hoisted_8 = {
  key: 1,
  "class": "page-item"
};
var _hoisted_9 = {
  "class": "page-link"
};
import { get } from 'lodash-es';

import { ref, computed, watch } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'Pagination'
}), {}, {
  __name: 'FilteredPagination',
  props: {
    placeHolderText: {
      "default": ''
    },
    items: {
      "default": function _default() {
        return [];
      }
    },
    filterOn: {
      "default": function _default() {
        return [];
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var currentPage = ref(1);
    var pageSize = ref(10);
    var pageBoundarySize = ref(2);
    var filterText = ref('');
    var filteredItems = computed(function () {
      if (filterText.value === '' || !filterText.value) {
        return props.items;
      }
      return props.items.filter(function (element) {
        var _iterator = _createForOfIteratorHelper(props.filterOn),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var filterOn = _step.value;
            var prop = get(element, filterOn);
            if (prop !== null && prop !== void 0 && prop.toString().toUpperCase().includes(filterText.value.toUpperCase())) {
              return true;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        return false;
      });
    });
    var totalPages = computed(function () {
      return Math.ceil(filteredItems.value.length / pageSize.value);
    });
    var pagesToShow = computed(function () {
      var startPage = Math.max(currentPage.value - pageBoundarySize.value, 2);
      var endPage = Math.min(currentPage.value + pageBoundarySize.value, totalPages.value - 1);
      var pages = [];
      for (var i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    });
    var shownItems = computed(function () {
      return filteredItems.value.slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value);
    });
    var changePage = function changePage(newPage) {
      if (newPage > 0 && newPage <= totalPages.value) {
        currentPage.value = newPage;
      } else {
        currentPage.value = 1;
      }
    };
    var filterRows = function filterRows() {
      changePage(1);
    };
    watch(pageSize, function () {
      changePage(1);
    });
    watch(function () {
      return props.items;
    }, function () {
      changePage(currentPage.value);
    }, {
      immediate: true
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(filterText) ? filterText.value = $event : null;
        }),
        type: "text",
        "class": "form-control",
        placeholder: _ctx.placeHolderText,
        onInput: filterRows
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [[_vModelText, _unref(filterText)]]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("select", {
        id: "select-pagesize",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _isRef(pageSize) ? pageSize.value = $event : null;
        }),
        "class": "form-control"
      }, _cache[6] || (_cache[6] = [_createStaticVNode("<option value=\"5\">5</option><option value=\"10\" selected>10</option><option value=\"25\">25</option><option value=\"50\">50</option><option value=\"100\">100</option>", 5)]), 512 /* NEED_PATCH */), [[_vModelSelect, _unref(pageSize)]]), _cache[7] || (_cache[7] = _createElementVNode("label", {
        "for": "select-pagesize"
      }, "Einträge anzeigen", -1 /* HOISTED */))])]), _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps({
        shownItems: _unref(shownItems)
      }))), _createElementVNode("ul", _hoisted_4, [_createElementVNode("li", {
        "class": _normalizeClass([{
          disabled: _unref(currentPage) - 1 < 1
        }, "page-item"]),
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return changePage(_unref(currentPage) - 1);
        })
      }, _cache[8] || (_cache[8] = [_createElementVNode("a", {
        "class": "page-link"
      }, [_createElementVNode("span", null, "«")], -1 /* HOISTED */)]), 2 /* CLASS */), _createElementVNode("li", {
        "class": _normalizeClass([{
          active: 1 === _unref(currentPage)
        }, "page-item"]),
        onClick: _cache[3] || (_cache[3] = function ($event) {
          return changePage(1);
        })
      }, _cache[9] || (_cache[9] = [_createElementVNode("a", {
        "class": "page-link"
      }, "1", -1 /* HOISTED */)]), 2 /* CLASS */), _unref(currentPage) > _unref(pageBoundarySize) + 2 ? (_openBlock(), _createElementBlock("li", _hoisted_5, _cache[10] || (_cache[10] = [_createElementVNode("a", {
        "class": "page-link disabled"
      }, "...", -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pagesToShow), function (index) {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          "class": _normalizeClass([{
            active: index === _unref(currentPage)
          }, "page-item"]),
          onClick: function onClick($event) {
            return changePage(index);
          }
        }, [_createElementVNode("a", _hoisted_7, _toDisplayString(index), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_6);
      }), 128 /* KEYED_FRAGMENT */)), _unref(currentPage) < _unref(totalPages) - _unref(pageBoundarySize) - 1 ? (_openBlock(), _createElementBlock("li", _hoisted_8, _cache[11] || (_cache[11] = [_createElementVNode("a", {
        "class": "page-link disabled"
      }, "...", -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true), _unref(totalPages) > 1 ? (_openBlock(), _createElementBlock("li", {
        key: 2,
        "class": _normalizeClass([{
          active: _unref(totalPages) === _unref(currentPage)
        }, "page-item"]),
        onClick: _cache[4] || (_cache[4] = function ($event) {
          return changePage(_unref(totalPages));
        })
      }, [_createElementVNode("a", _hoisted_9, _toDisplayString(_unref(totalPages)), 1 /* TEXT */)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("li", {
        "class": _normalizeClass([{
          disabled: _unref(currentPage) + 1 > _unref(totalPages)
        }, "page-item"]),
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return changePage(_unref(currentPage) + 1);
        })
      }, _cache[12] || (_cache[12] = [_createElementVNode("a", {
        "class": "page-link"
      }, [_createElementVNode("span", null, "»")], -1 /* HOISTED */)]), 2 /* CLASS */)])]);
    };
  }
}));