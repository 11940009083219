import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
import { translate } from '/var/node/assets/js/utils/translate.ts';
var _hoisted_1 = ["for"];
var _hoisted_2 = ["id", "name"];
var _hoisted_3 = {
  "class": "flex flex-col flex-nowrap items-center"
};
var _hoisted_4 = {
  "class": "flex gap-x-1"
};
export default /*@__PURE__*/_defineComponent({
  __name: 'JurPersonSignerTile',
  props: {
    signer: {},
    isSigning: {
      type: Boolean
    }
  },
  emits: ['update:isSigning', 'editSigner', 'removeSigner'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var checked = useVModel(props, 'isSigning', emit);
    var functionsString = computed(function () {
      var _props$signer$functio, _props$signer$functio2;
      return (_props$signer$functio = (_props$signer$functio2 = props.signer.functions) === null || _props$signer$functio2 === void 0 ? void 0 : _props$signer$functio2.map(function (f) {
        return translate('involved_person', "choices.".concat(f["function"]));
      }).join(' + ')) !== null && _props$signer$functio !== void 0 ? _props$signer$functio : '';
    });
    return function (_ctx, _cache) {
      var _ctx$signer$person;
      return _openBlock(), _createElementBlock("label", {
        "class": "flex flex-row flex-nowrap items-center justify-between p-4 mb-4 rounded-corners form-children-no-margin jur-person-signer",
        "for": _ctx.signer['@id']
      }, [_withDirectives(_createElementVNode("input", {
        id: _ctx.signer['@id'],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(checked) ? checked.value = $event : null;
        }),
        type: "checkbox",
        name: _ctx.signer['@id'],
        "class": "form-check-input"
      }, null, 8 /* PROPS */, _hoisted_2), [[_vModelCheckbox, _unref(checked)]]), _cache[5] || (_cache[5] = _createElementVNode("span", {
        "class": "form-check-label"
      }, null, -1 /* HOISTED */)), _createElementVNode("span", _hoisted_3, [_createElementVNode("span", null, _toDisplayString((_ctx$signer$person = _ctx.signer.person) === null || _ctx$signer$person === void 0 ? void 0 : _ctx$signer$person.fullName), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(_unref(functionsString)), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', "signature.".concat(_ctx.signer.signature))), 1 /* TEXT */)]), _createElementVNode("span", _hoisted_4, [_createElementVNode("button", {
        "class": "inline-flex items-center justify-center w-8 h-8 px-2.5 rounded border border-blue bg-blue-light text-blue hover:bg-blue hover:text-blue-light",
        type: "button",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return emit('editSigner', _ctx.signer);
        })
      }, _cache[3] || (_cache[3] = [_createElementVNode("i", {
        "class": "fa fa-user"
      }, null, -1 /* HOISTED */)])), _createElementVNode("button", {
        "class": "inline-flex items-center justify-center w-8 h-8 px-2.5 rounded border border-red bg-red-light text-red hover:bg-red hover:text-red-light",
        type: "button",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return emit('removeSigner', _ctx.signer);
        })
      }, _cache[4] || (_cache[4] = [_createElementVNode("i", {
        "class": "far fa-trash-alt"
      }, null, -1 /* HOISTED */)]))])], 8 /* PROPS */, _hoisted_1);
    };
  }
});