import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "flex flex-col items-stretch"
};
var _hoisted_2 = {
  "class": "self-center"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  "class": "flex justify-center items-center"
};
var _hoisted_5 = {
  key: 1,
  "class": "flex flex-col items-stretch"
};
var _hoisted_6 = {
  "class": "inline-block"
};
import IdentificationCardFrontImage from '@images/identification/identification_card_front.png';
import IdentificationCardBackImage from '@images/identification/identification_card_back.png';
import PassportImage from '@images/identification/passport_icon.png';
import QrCode from './QrCode.vue';
import ImageUpload from './ImageUpload.vue';
import ImageUploadType from '@/enums/ImageUploadType';
import HttpCode from '@/enums/HttpCode';
import { getLocalizedUrl } from '@/js/frontend/helpers';

import { reactive, computed } from 'vue';
import { absoluteUrl } from '/var/node/assets/js/composables/useAbsoluteUrl.ts';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'IdentityImage'
}), {}, {
  __name: 'IdentityImage',
  props: {
    fileToken: {
      "default": ''
    },
    nationality: {
      "default": 'CH'
    },
    hometown: {
      "default": undefined
    },
    identificationMethod: {
      "default": undefined
    },
    token: {
      "default": ''
    },
    uploadOnly: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var identificationMethods = {
      identity_card: ['data', 'signature'],
      foreigner_identification: ['data', 'signature'],
      passport: ['data']
    };
    var identificationIcons = {
      identity_card: {
        data: IdentificationCardFrontImage,
        signature: IdentificationCardBackImage
      },
      foreigner_identification: {
        data: IdentificationCardFrontImage,
        signature: IdentificationCardBackImage
      },
      passport: {
        data: PassportImage
      }
    };
    var imageUploadMissing = reactive({});
    var getParameters = computed(function () {
      var result = "?imageType=".concat(ImageUploadType.IDENTIFICATION, "&nationality=").concat(props.nationality, "&side={side}");
      if (props.identificationMethod) {
        result += "&identificationMethod=".concat(props.identificationMethod);
      }
      if (props.hometown) {
        result += "&hometown=".concat(encodeURIComponent(props.hometown));
      }
      if (props.fileToken !== '') {
        result += "&file_token=".concat(props.fileToken);
      }
      return result;
    });
    var externalFormUrlWithParameters = computed(function () {
      return absoluteUrl(getLocalizedUrl("/person/public/image/".concat(props.token).concat(getParameters.value)));
    });
    var isOriginSet = computed(function () {
      return !!props.identificationMethod && (props.nationality === 'CH' ? props.hometown !== undefined : true);
    });
    function getUrl(action) {
      return "/de/person/public/image/".concat(action, "/").concat(props.token).concat(getParameters.value).concat(props.fileToken ? "&file_token=".concat(props.fileToken) : '');
    }
    var isImageUploadMissing = computed(function () {
      return props.identificationMethod ? identificationMethods[props.identificationMethod] && Object.values(imageUploadMissing).some(function (elem) {
        return elem;
      }) : true;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_Transition, {
        name: "appear",
        mode: "out-in"
      }, {
        "default": _withCtx(function () {
          return [_unref(isOriginSet) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h5", _hoisted_2, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'info.image_of')) + " " + _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', "choices.identification_method.".concat(_ctx.identificationMethod))), 1 /* TEXT */), _createVNode(_Transition, {
            name: "appear",
            mode: "out-in"
          }, {
            "default": _withCtx(function () {
              return [_unref(isImageUploadMissing) && !_ctx.uploadOnly ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(QrCode, {
                "class": "mb-12",
                "external-form-url-with-parameters": _unref(externalFormUrlWithParameters)
              }, null, 8 /* PROPS */, ["external-form-url-with-parameters"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'info.or_upload_scan_here')), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)];
            }),
            _: 1 /* STABLE */
          }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(identificationMethods[_ctx.identificationMethod], function (side) {
            return _openBlock(), _createBlock(ImageUpload, {
              key: side,
              title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', "info.".concat(side, "_side")),
              "get-url-with-parameters": getUrl(_unref(HttpCode).GET).replace('{side}', side),
              "upload-url-with-parameters": getUrl(_unref(HttpCode).UPLOAD).replace('{side}', side),
              "delete-url-with-parameters": getUrl(_unref(HttpCode).DELETE).replace('{side}', side),
              "identification-icon": identificationIcons[_ctx.identificationMethod] !== undefined ? identificationIcons[_ctx.identificationMethod][side] : '',
              onImageUploadMissing: function onImageUploadMissing(m) {
                return _unref(imageUploadMissing)[side] = m;
              }
            }, null, 8 /* PROPS */, ["title", "get-url-with-parameters", "upload-url-with-parameters", "delete-url-with-parameters", "identification-icon", "onImageUploadMissing"]);
          }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'info.generate_qr')), 1 /* TEXT */)]))];
        }),
        _: 1 /* STABLE */
      });
    };
  }
}));