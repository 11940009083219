function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";

import { computed } from 'vue';
import { useDebounceFn } from '@vueuse/core';
var _hoisted_1 = ["value"];
export default /*@__PURE__*/_defineComponent({
  __name: 'ColumnFilter',
  props: {
    header: {},
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    var props = __props;
    var emit = __emit;
    var type = computed(function () {
      var _props$header$column$, _props$header$column$2;
      return (_props$header$column$ = (_props$header$column$2 = props.header.column.columnDef.meta) === null || _props$header$column$2 === void 0 || (_props$header$column$2 = _props$header$column$2.api) === null || _props$header$column$2 === void 0 || (_props$header$column$2 = _props$header$column$2.filterConfig) === null || _props$header$column$2 === void 0 ? void 0 : _props$header$column$2.type) !== null && _props$header$column$ !== void 0 ? _props$header$column$ : 'text';
    });
    var isCombobox = computed(function () {
      return type.value === 'combobox';
    });
    var usesApi = computed(function () {
      var _props$header$column$3, _props$header$column$4;
      return !!((_props$header$column$3 = props.header.column.columnDef.meta) !== null && _props$header$column$3 !== void 0 && (_props$header$column$3 = _props$header$column$3.api) !== null && _props$header$column$3 !== void 0 && (_props$header$column$3 = _props$header$column$3.filterConfig) !== null && _props$header$column$3 !== void 0 && _props$header$column$3.queryKey) || !!((_props$header$column$4 = props.header.column.columnDef.meta) !== null && _props$header$column$4 !== void 0 && (_props$header$column$4 = _props$header$column$4.api) !== null && _props$header$column$4 !== void 0 && (_props$header$column$4 = _props$header$column$4.filterConfig) !== null && _props$header$column$4 !== void 0 && _props$header$column$4.url);
    });
    var options = computed(function () {
      var _props$header$column$5, _props$header$column$6, _props$header$column$7;
      if (usesApi.value) {
        return [];
      }
      if (((_props$header$column$5 = props.header.column.columnDef.meta) === null || _props$header$column$5 === void 0 || (_props$header$column$5 = _props$header$column$5.api) === null || _props$header$column$5 === void 0 || (_props$header$column$5 = _props$header$column$5.filterConfig) === null || _props$header$column$5 === void 0 ? void 0 : _props$header$column$5.options) === 'boolean') {
        return [{
          id: 'true'
        }, {
          id: 'false'
        }];
      }
      return (_props$header$column$6 = (_props$header$column$7 = props.header.column.columnDef.meta) === null || _props$header$column$7 === void 0 || (_props$header$column$7 = _props$header$column$7.api) === null || _props$header$column$7 === void 0 || (_props$header$column$7 = _props$header$column$7.filterConfig) === null || _props$header$column$7 === void 0 ? void 0 : _props$header$column$7.options) !== null && _props$header$column$6 !== void 0 ? _props$header$column$6 : [];
    });
    var getOptionLabel = computed(function () {
      var _props$header$column$8, _props$header$column$10;
      if ((_props$header$column$8 = props.header.column.columnDef.meta) !== null && _props$header$column$8 !== void 0 && (_props$header$column$8 = _props$header$column$8.api) !== null && _props$header$column$8 !== void 0 && (_props$header$column$8 = _props$header$column$8.filterConfig) !== null && _props$header$column$8 !== void 0 && _props$header$column$8.getOptionLabel) {
        var _props$header$column$9;
        return (_props$header$column$9 = props.header.column.columnDef.meta) === null || _props$header$column$9 === void 0 || (_props$header$column$9 = _props$header$column$9.api) === null || _props$header$column$9 === void 0 || (_props$header$column$9 = _props$header$column$9.filterConfig) === null || _props$header$column$9 === void 0 ? void 0 : _props$header$column$9.getOptionLabel;
      }
      if (((_props$header$column$10 = props.header.column.columnDef.meta) === null || _props$header$column$10 === void 0 || (_props$header$column$10 = _props$header$column$10.api) === null || _props$header$column$10 === void 0 || (_props$header$column$10 = _props$header$column$10.filterConfig) === null || _props$header$column$10 === void 0 ? void 0 : _props$header$column$10.options) === 'boolean') {
        return function (option) {
          return (
            // eslint-disable-next-line no-nested-ternary
            (option === null || option === void 0 ? void 0 : option.id) === 'true' ? 'Ja' : (option === null || option === void 0 ? void 0 : option.id) === 'false' ? 'Nein' : ''
          );
        };
      }
      return undefined;
    });
    var value = computed({
      get: function get() {
        if (isCombobox.value) {
          var _props$header$column$11;
          if ((_props$header$column$11 = props.header.column.columnDef.meta) !== null && _props$header$column$11 !== void 0 && (_props$header$column$11 = _props$header$column$11.api) !== null && _props$header$column$11 !== void 0 && (_props$header$column$11 = _props$header$column$11.filterConfig) !== null && _props$header$column$11 !== void 0 && _props$header$column$11.filterValueToOption) {
            return props.header.column.columnDef.meta.api.filterConfig.filterValueToOption(props.modelValue);
          }
          return props.modelValue ? {
            id: props.modelValue
          } : null;
        }
        return props.modelValue;
      },
      set: function set(newValue) {
        if (isCombobox.value) {
          var _props$header$column$12;
          if ((_props$header$column$12 = props.header.column.columnDef.meta) !== null && _props$header$column$12 !== void 0 && (_props$header$column$12 = _props$header$column$12.api) !== null && _props$header$column$12 !== void 0 && (_props$header$column$12 = _props$header$column$12.filterConfig) !== null && _props$header$column$12 !== void 0 && _props$header$column$12.optionToFilterValue) {
            emit('update:modelValue', props.header.column.columnDef.meta.api.filterConfig.optionToFilterValue(newValue));
            return;
          }
          emit('update:modelValue', newValue && _typeof(newValue) === 'object' && 'id' in newValue && newValue.id ? newValue.id : null);
          return;
        }
        emit('update:modelValue', newValue);
      }
    });
    var debounceSetValue = useDebounceFn(function (newValue) {
      value.value = newValue;
    }, 400);
    return function (_ctx, _cache) {
      var _component_ApiCombobox = _resolveComponent("ApiCombobox");
      var _component_Combobox = _resolveComponent("Combobox");
      return _unref(isCombobox) && _unref(usesApi) ? (_openBlock(), _createBlock(_component_ApiCombobox, _mergeProps({
        key: 0,
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(value) ? value.value = $event : null;
        }),
        nullable: "",
        size: "small"
      }, {
        queryKey: _ctx.header.column.columnDef.meta.api.filterConfig.queryKey,
        url: _ctx.header.column.columnDef.meta.api.filterConfig.url,
        getOptionLabel: _unref(getOptionLabel)
      }), null, 16 /* FULL_PROPS */, ["modelValue"])) : _unref(isCombobox) && !_unref(usesApi) ? (_openBlock(), _createBlock(_component_Combobox, _mergeProps({
        key: 1,
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _isRef(value) ? value.value = $event : null;
        }),
        nullable: "",
        size: "small"
      }, {
        options: _unref(options),
        getOptionLabel: _unref(getOptionLabel)
      }), null, 16 /* FULL_PROPS */, ["modelValue"])) : (_openBlock(), _createElementBlock("input", {
        key: 2,
        value: _unref(value),
        type: "text",
        "class": "block w-full border-1.5 border-blue-dark rounded-lg text-base font-bold py-3.5 px-4",
        size: "1",
        onInput: _cache[2] || (_cache[2] = function (event) {
          var _event$target;
          _unref(debounceSetValue)((_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.value);
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1));
    };
  }
});