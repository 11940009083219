import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { onMounted } from 'vue';
var _hoisted_1 = ["data-bs-content", "data-bs-title"];
export default /*@__PURE__*/_defineComponent({
  __name: 'Tooltip',
  props: {
    text: {},
    title: {}
  },
  setup: function setup(__props) {
    onMounted(function () {
      $('[data-bs-toggle="popover"]').each(function () {
        $(this).popover();
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("a", {
        "class": "help-label",
        "data-bs-toggle": "popover",
        "data-bs-html": "true",
        "data-bs-content": _ctx.text,
        "data-bs-title": _ctx.title
      }, _cache[0] || (_cache[0] = [_createElementVNode("i", {
        "class": "fa fa-info-circle"
      }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_1);
    };
  }
});