import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "signature_options mt-4"
};
var _hoisted_2 = {
  "class": "mb-4 flex flex-row items-center justify-between"
};
var _hoisted_3 = {
  "class": "m-0"
};
var _hoisted_4 = ["href"];
import SigningPerson from '../SigningPerson.vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'JurPersonRepresentedBySigners'
}), {}, {
  __name: 'JurPersonRepresentedBySigners',
  props: {
    jurPerson: {
      "default": function _default() {
        return {};
      }
    },
    isCheckQesDone: {
      type: [Boolean, Error],
      "default": false
    },
    isESignature: {
      type: Boolean,
      "default": false
    },
    processId: {
      "default": 0
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _ctx.jurPerson.name ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.jurPerson.name), 1 /* TEXT */), _createElementVNode("a", {
        "data-popup": "form",
        "class": "edit-jur-person ms-4 card-layout",
        href: "/person/jur_person/new/".concat(_ctx.jurPerson.id, "/signers?processId=").concat(_ctx.processId, "&stepId=signature_substitutes")
      }, _cache[0] || (_cache[0] = [_createElementVNode("i", {
        "class": "fa fa-pencil"
      }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_4)]), _createElementVNode("p", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'general.jur_person.represented_by')), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jurPerson.signers, function (signer) {
        return _openBlock(), _createBlock(SigningPerson, {
          key: signer.id,
          signer: signer,
          "is-e-signature": _ctx.isESignature,
          "is-check-qes-done": _ctx.isCheckQesDone,
          "process-id": _ctx.processId
        }, null, 8 /* PROPS */, ["signer", "is-e-signature", "is-check-qes-done", "process-id"]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true);
    };
  }
}));