import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  "class": "mb-0"
};
import CalloutType from '@/enums/CalloutType';
import Callout from '@/components/general/Callout.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorBox',
  props: {
    errors: {}
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _ctx$errors;
      return (_ctx$errors = _ctx.errors) !== null && _ctx$errors !== void 0 && _ctx$errors.length ? (_openBlock(), _createBlock(Callout, {
        key: 0,
        "class": "my-6",
        type: _unref(CalloutType).DANGER
      }, {
        body: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'still_some_errors')) + " ", 1 /* TEXT */), _createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, function (error) {
            return _openBlock(), _createElementBlock("li", {
              key: error
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true);
    };
  }
});