import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "compensate-old-modal"
};
var _hoisted_2 = ["href"];
import modal from '@/js/shared/modal';

import { ref } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'TwigJurPersonSignerForm',
  props: {
    fileToken: {},
    jurPersonId: {},
    isESignature: {
      type: Boolean
    },
    jurPersonSignerId: {},
    eSignUrl: {},
    returnTo: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var returnButton = ref(null);
    var modalHandling = function modalHandling() {
      // if returnTo is set, redirect there
      if (props.returnTo) {
        var _returnButton$value;
        (_returnButton$value = returnButton.value) === null || _returnButton$value === void 0 || _returnButton$value.click();
      }
    };
    var contentChanged = function contentChanged(newValue) {
      modal.setNoConfirmationNeeded(!newValue);
    };
    return function (_ctx, _cache) {
      var _component_ProcessStepJurPersonSignerForm = _resolveComponent("ProcessStepJurPersonSignerForm");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ProcessStepJurPersonSignerForm, {
        "file-token": _ctx.fileToken,
        "jur-person-at-id": "/api/jur_people/".concat(_ctx.jurPersonId),
        "is-e-signature": _ctx.isESignature,
        "jur-person-signer-at-id": "/api/jur_person_signers/".concat(_ctx.jurPersonSignerId),
        "e-sign-url": _ctx.eSignUrl,
        onUpdated: modalHandling,
        onBack: modalHandling,
        onChanged: _cache[0] || (_cache[0] = function (newValue) {
          return contentChanged(newValue);
        })
      }, null, 8 /* PROPS */, ["file-token", "jur-person-at-id", "is-e-signature", "jur-person-signer-at-id", "e-sign-url"]), _createElementVNode("a", {
        ref_key: "returnButton",
        ref: returnButton,
        "data-popup": "form",
        "class": "hidden",
        href: _ctx.returnTo
      }, null, 8 /* PROPS */, _hoisted_2)]);
    };
  }
});