function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "flex flex-col"
};
var _hoisted_2 = {
  "class": "self-center"
};
var _hoisted_3 = {
  "class": "flex justify-center items-center"
};
import QrCode from './QrCode.vue';
import ImageUpload from './ImageUpload.vue';
import { translate } from '@/js/frontend/filters';
import ImageUploadType from '@/enums/ImageUploadType';
import HttpCode from '@/enums/HttpCode';

import { ref, computed, onMounted } from 'vue';
import { absoluteUrl } from '/var/node/assets/js/composables/useAbsoluteUrl.ts';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'AcademicTitleImage'
}), {}, {
  __name: 'AcademicTitleImage',
  props: {
    token: {
      "default": ''
    },
    uploadOnly: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var shouldBeMounted = ref(true);
    var isImageUploadMissing = ref(true);
    var externalFormUrlWithParameters = computed(function () {
      return absoluteUrl("/de/person/public/image/".concat(props.token, "?imageType=").concat(ImageUploadType.ACADEMIC_TITLE));
    });
    function getUrl(action) {
      return "/de/person/public/image/".concat(action, "/").concat(props.token, "?imageType=").concat(ImageUploadType.ACADEMIC_TITLE);
    }
    onMounted(function () {
      $('#main_modal').one('hidden.bs.modal', function () {
        shouldBeMounted.value = false;
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h5", _hoisted_2, _toDisplayString(_unref(translate)('nat_person', 'info.academic_title_document')), 1 /* TEXT */), _unref(isImageUploadMissing) && !_ctx.uploadOnly ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(QrCode, {
        "external-form-url-with-parameters": _unref(externalFormUrlWithParameters)
      }, null, 8 /* PROPS */, ["external-form-url-with-parameters"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString(_unref(translate)('nat_person', 'info.or_upload_scan_here')), 1 /* TEXT */)])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _unref(shouldBeMounted) ? (_openBlock(), _createBlock(ImageUpload, {
        key: 1,
        title: _unref(translate)('process', 'step_process.general_steps.person_mutation.form.fields.academicTitle'),
        "get-url-with-parameters": getUrl(_unref(HttpCode).GET),
        "upload-url-with-parameters": getUrl(_unref(HttpCode).UPLOAD),
        "delete-url-with-parameters": getUrl(_unref(HttpCode).DELETE),
        onUploadMissing: _cache[0] || (_cache[0] = function (m) {
          return isImageUploadMissing.value = m;
        })
      }, null, 8 /* PROPS */, ["title", "get-url-with-parameters", "upload-url-with-parameters", "delete-url-with-parameters"])) : _createCommentVNode("v-if", true)]);
    };
  }
}));