import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { set } from 'lodash-es';
import modal from '@/js/shared/modal';
import ProcessStepNatPersonForm from '@/components/natPersonForm/ProcessStepNatPersonForm';

import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'TwigProcessStepNatPersonForm',
  props: {
    fileToken: {},
    processId: {},
    isESignature: {
      type: Boolean
    },
    stepId: {},
    natPersonId: {},
    eSignUrl: {},
    furtherPayload: {},
    violations: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var initialErrors = computed(function () {
      if (!props.violations || Object.keys(props.violations).length === 0) {
        return undefined;
      }
      var output = {};
      Object.keys(props.violations).forEach(function (key) {
        set(output, key, props.violations[key]);
      });
      return output;
    });
    var modalHandling = function modalHandling() {
      // update step in background without page reload
      modal.updatePage();
      modal.closeModal(true);
    };
    var contentChanged = function contentChanged(newValue) {
      modal.setNoConfirmationNeeded(!newValue);
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(ProcessStepNatPersonForm), {
        "file-token": _ctx.fileToken,
        "process-id": _ctx.processId,
        "is-e-signature": _ctx.isESignature,
        "step-id": _ctx.stepId,
        "nat-person-id": _ctx.natPersonId,
        "e-sign-url": _ctx.eSignUrl,
        "further-payload": _ctx.furtherPayload,
        "initial-errors": _unref(initialErrors),
        "class": "compensate-old-modal",
        onUpdated: modalHandling,
        onChanged: _cache[0] || (_cache[0] = function (newValue) {
          return contentChanged(newValue);
        })
      }, null, 8 /* PROPS */, ["file-token", "process-id", "is-e-signature", "step-id", "nat-person-id", "e-sign-url", "further-payload", "initial-errors"]);
    };
  }
});