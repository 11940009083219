import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  "class": "content-wrapper"
};
var _hoisted_2 = {
  "class": "content"
};
var _hoisted_3 = {
  "class": "header"
};
var _hoisted_4 = {
  key: 0,
  "class": "alert alert-warning mb-6"
};
var _hoisted_5 = {
  "class": "card-body"
};
var _hoisted_6 = {
  "class": "mb-0"
};
var _hoisted_7 = {
  "class": "my-6"
};
var _hoisted_8 = {
  "class": "flex items-center"
};
var _hoisted_9 = {
  "class": "form-check-label mt-1",
  "for": "emailCopy"
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  "class": "p-6"
};
var _hoisted_12 = {
  "class": "form-group mb-0"
};
var _hoisted_13 = {
  "for": "edit_email"
};
var _hoisted_14 = {
  "class": "invalid-feedback"
};
var _hoisted_15 = ["disabled", "onClick"];
import axios from 'axios';
import { translate } from '@/js/frontend/filters';
import SignerToInvite from '@/components/SignerToInvite.vue';
import Modal from '@/components/Modal.vue';
import validators from '@/js/frontend/validators';
import CalloutType from '@/enums/CalloutType';
import useButtonState from '@/ts/VueCompositions/UseButtonState';
import { getLocalizedUrl } from '@/js/frontend/helpers';

import { toRefs, ref, computed } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { useCloned } from '@vueuse/core';
export default /*@__PURE__*/_defineComponent({
  __name: 'TrusteeRelease',
  props: {
    processId: {},
    initialTrusteeEmail: {},
    delayedDocsWithDateJson: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var _toRefs = toRefs(props),
      processId = _toRefs.processId,
      initialTrusteeEmail = _toRefs.initialTrusteeEmail;
    var trusteeEmail = ref(initialTrusteeEmail.value);
    var trusteeWantsEmailCopy = ref(true);
    var isModalShown = ref(false);
    var isEmailValid = computed(function () {
      return validators.validateEmail(trusteeEmail.value);
    });
    var delayedDocsWithDate = computed(function () {
      return JSON.parse(props.delayedDocsWithDateJson);
    });
    var signerInviteUrl = computed(function () {
      return getLocalizedUrl("/process/".concat(processId.value, "/e_signature/trustee_release/json"));
    });
    var _useQuery = useQuery({
        queryKey: [signerInviteUrl],
        initialData: []
      }),
      signersToInviteData = _useQuery.data,
      isFetching = _useQuery.isFetching;
    // using cloned data, since signersToInviteData is readonly
    var _useCloned = useCloned(signersToInviteData),
      signersToInvite = _useCloned.cloned;
    var _useMutation = useMutation({
        mutationFn: function mutationFn() {
          return axios.post(signerInviteUrl.value, {
            trusteeWantsEmailCopy: trusteeWantsEmailCopy.value,
            trusteeEmailCC: trusteeEmail.value,
            signersToInvite: signersToInvite.value
          });
        },
        onSuccess: function onSuccess(response) {
          var redirectUrl = response.data.redirectUrl;
          if (redirectUrl !== undefined) {
            setTimeout(function () {
              window.location = redirectUrl;
            }, 1000);
          }
        }
      }),
      isSendingMailsError = _useMutation.isError,
      isSendingMails = _useMutation.isPending,
      isSendingMailsSuccess = _useMutation.isSuccess,
      sendMailsMutation = _useMutation.mutate;
    var _useButtonState = useButtonState(isSendingMails, isSendingMailsError, isSendingMailsSuccess),
      buttonState = _useButtonState.buttonState;
    return function (_ctx, _cache) {
      var _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
      var _component_Callout = _resolveComponent("Callout");
      var _component_ButtonBootstrap = _resolveComponent("ButtonBootstrap");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h3", null, _toDisplayString(_unref(translate)('messages', 'e_signature.release')), 1 /* TEXT */)]), _unref(delayedDocsWithDate).length !== 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString(_unref(translate)('messages', 'e_signature.release_delayed_info')), 1 /* TEXT */), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(delayedDocsWithDate), function (d) {
        return _openBlock(), _createElementBlock("li", {
          key: d
        }, _toDisplayString(d), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), _createElementVNode("p", null, _toDisplayString(_unref(translate)('messages', 'e_signature.step_two')), 1 /* TEXT */), _createElementVNode("div", _hoisted_7, [_unref(isFetching) ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
        key: 0,
        "class": "m-12"
      })) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(signersToInvite), function (signer) {
        return _openBlock(), _createBlock(SignerToInvite, {
          key: signer,
          signer: signer
        }, null, 8 /* PROPS */, ["signer"]);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_8, [_withDirectives(_createElementVNode("input", {
        id: "emailCopy",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(trusteeWantsEmailCopy) ? trusteeWantsEmailCopy.value = $event : null;
        }),
        "class": "form-check-input mt-2",
        type: "checkbox"
      }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, _unref(trusteeWantsEmailCopy)]]), _createElementVNode("label", _hoisted_9, [_createElementVNode("span", {
        innerHTML: _unref(translate)('messages', 'e_signature.send_email_copy', {
          '%mailto%': _unref(trusteeEmail),
          '%email%': _unref(trusteeEmail)
        })
      }, null, 8 /* PROPS */, _hoisted_10)]), _createElementVNode("a", {
        "class": "show",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return isModalShown.value = true;
        })
      }, _cache[4] || (_cache[4] = [_createElementVNode("i", {
        "class": "fa fa-pencil ms-2"
      }, null, -1 /* HOISTED */)]))]), _createVNode(_Transition, {
        name: "appear",
        mode: "out-in"
      }, {
        "default": _withCtx(function () {
          return [_unref(isSendingMailsError) ? (_openBlock(), _createBlock(_component_Callout, {
            key: 0,
            "class": "mt-4",
            type: _unref(CalloutType).DANGER
          }, {
            body: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_unref(translate)('messages', 'e_signature.send_esig_mail.mail_error')), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_ButtonBootstrap, {
        id: "send-mails",
        "class": "mt-4",
        disabled: _unref(isFetching),
        "on-click": _unref(sendMailsMutation),
        "button-state": _unref(buttonState),
        "success-text": _unref(translate)('messages', 'e_signature.send_esig_mail.button_success'),
        "default-text": _unref(translate)('messages', 'e_signature.send_esig_mail.button')
      }, null, 8 /* PROPS */, ["disabled", "on-click", "button-state", "success-text", "default-text"])])]), _createVNode(Modal, {
        modelValue: _unref(isModalShown),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return _isRef(isModalShown) ? isModalShown.value = $event : null;
        }),
        title: _unref(translate)('messages', 'e_signature.edit_email'),
        "hide-close-button": true
      }, {
        footer: _withCtx(function (_ref) {
          var close = _ref.close;
          return [_createElementVNode("button", {
            type: "button",
            "class": _normalizeClass(["btn btn-primary", !_unref(isEmailValid) ? 'disabled' : '']),
            disabled: !_unref(isEmailValid),
            onClick: close
          }, _toDisplayString(_unref(translate)('messages', 'action.save')), 11 /* TEXT, CLASS, PROPS */, _hoisted_15)];
        }),
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("label", _hoisted_13, _toDisplayString(_unref(translate)('messages', 'e_signature.edit_email_info')), 1 /* TEXT */), _withDirectives(_createElementVNode("input", {
            id: "edit_email",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return _isRef(trusteeEmail) ? trusteeEmail.value = $event : null;
            }),
            type: "email",
            "class": _normalizeClass([!_unref(isEmailValid) ? 'is-invalid' : '', "form-control"])
          }, null, 2 /* CLASS */), [[_vModelText, _unref(trusteeEmail)]]), _createElementVNode("div", _hoisted_14, [_createElementVNode("span", null, _toDisplayString(_unref(translate)('process_mutation', 'general.error.email_invalid_format')), 1 /* TEXT */)])])])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "title"])], 64 /* STABLE_FRAGMENT */);
    };
  }
});