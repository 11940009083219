import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { getFirstError as __unimport_getFirstError } from '/var/node/assets/js/utils/validation.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1,
  "class": "pt-2 max-w-min min-w-[40em] nat-person-form"
};
var _hoisted_2 = {
  "class": "px-6 py-4"
};
var _hoisted_3 = {
  "class": "mb-3"
};
var _hoisted_4 = {
  "class": "form-group"
};
var _hoisted_5 = {
  "class": "px-6 py-6"
};
var _hoisted_6 = {
  "class": "px-6 py-4"
};
var _hoisted_7 = {
  "class": "row"
};
var _hoisted_8 = {
  "class": "col"
};
var _hoisted_9 = {
  "class": "px-6 py-4"
};
var _hoisted_10 = {
  "class": "bordered mb-3"
};
var _hoisted_11 = {
  value: "m"
};
var _hoisted_12 = {
  value: "w"
};
import { cloneDeep, difference } from 'lodash-es';
import TextField from '@/components/TextField.vue';
import AcademicTitleImage from '@/components/natPersonForm/AcademicTitleImage.vue';
import Seat from '@/components/natPersonForm/Seat.vue';
import SignatureImage from '@/components/natPersonForm/SignatureImage.vue';
import Countries from '@/components/Countries.vue';
import PersonOrigins from '@/components/natPersonForm/PersonOrigins.vue';
import DropdownField from '@/components/DropdownField.vue';
import DateInput from '@/components/DateInput.vue';

import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
import { translate } from '/var/node/assets/js/utils/translate.ts';
export default /*@__PURE__*/_defineComponent({
  __name: 'NatPersonForm',
  props: {
    modelValue: {},
    errors: {},
    isESignature: {
      type: Boolean
    },
    eSignUrl: {},
    loading: {
      type: Boolean
    },
    fileToken: {}
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var internalNatPerson = useVModel(props, 'modelValue', emit, {
      clone: cloneDeep,
      deep: true,
      passive: true
    });
    var optionalDataValid = computed(function () {
      var _props$errors;
      var optionalFields = ['academicTitle', 'streetAddress'];
      return difference(optionalFields, Object.keys((_props$errors = props.errors) !== null && _props$errors !== void 0 ? _props$errors : {})).length === optionalFields.length;
    });
    var academicTitleRegex = /^(.*Dr\.)|(.*Prof\.).*$/;
    var firstnameError = computed(function () {
      var _internalNatPerson$va, _internalNatPerson$va2;
      return academicTitleRegex.test((_internalNatPerson$va = (_internalNatPerson$va2 = internalNatPerson.value) === null || _internalNatPerson$va2 === void 0 ? void 0 : _internalNatPerson$va2.firstname) !== null && _internalNatPerson$va !== void 0 ? _internalNatPerson$va : '') ? translate('validators', 'surname.contains_academic_title') : undefined;
    });
    var surnameError = computed(function () {
      var _internalNatPerson$va3, _internalNatPerson$va4;
      return academicTitleRegex.test((_internalNatPerson$va3 = (_internalNatPerson$va4 = internalNatPerson.value) === null || _internalNatPerson$va4 === void 0 ? void 0 : _internalNatPerson$va4.surname) !== null && _internalNatPerson$va3 !== void 0 ? _internalNatPerson$va3 : '') ? translate('validators', 'surname.contains_academic_title') : undefined;
    });
    return function (_ctx, _cache) {
      var _unref2, _ctx$errors, _unref3, _ctx$errors2, _ctx$errors3, _ctx$errors4, _ctx$errors5, _ctx$errors6, _ctx$errors7, _ctx$errors8, _ctx$errors9, _ctx$errors10, _ctx$errors11;
      var _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
      return _ctx.loading ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h5", _hoisted_3, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'sections.personal_data')), 1 /* TEXT */), _createVNode(TextField, {
        modelValue: _unref(internalNatPerson).surname,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(internalNatPerson).surname = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_unref2 = _unref(surnameError)) !== null && _unref2 !== void 0 ? _unref2 : (_ctx$errors = _ctx.errors) === null || _ctx$errors === void 0 ? void 0 : _ctx$errors.surname),
        "always-show-error": !!_unref(surnameError),
        name: "surname",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.surname'),
        autocomplete: "surname"
      }, null, 8 /* PROPS */, ["modelValue", "error", "always-show-error", "label"]), _createVNode(TextField, {
        modelValue: _unref(internalNatPerson).firstname,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _unref(internalNatPerson).firstname = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_unref3 = _unref(firstnameError)) !== null && _unref3 !== void 0 ? _unref3 : (_ctx$errors2 = _ctx.errors) === null || _ctx$errors2 === void 0 ? void 0 : _ctx$errors2.firstname),
        "always-show-error": !!_unref(firstnameError),
        name: "firstname",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.first_name'),
        autocomplete: "first-name"
      }, null, 8 /* PROPS */, ["modelValue", "error", "always-show-error", "label"]), _createElementVNode("div", _hoisted_4, [_createVNode(Countries, {
        id: "nationality",
        modelValue: _unref(internalNatPerson).country,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return _unref(internalNatPerson).country = $event;
        }),
        name: "nationality",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.country'),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors3 = _ctx.errors) === null || _ctx$errors3 === void 0 ? void 0 : _ctx$errors3.country)
      }, null, 8 /* PROPS */, ["modelValue", "label", "error"])]), _createVNode(Seat, {
        modelValue: _unref(internalNatPerson).seat,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return _unref(internalNatPerson).seat = $event;
        }),
        "is-switzerland": _unref(internalNatPerson).country === 'CH',
        errors: (_ctx$errors4 = _ctx.errors) === null || _ctx$errors4 === void 0 ? void 0 : _ctx$errors4.seat
      }, null, 8 /* PROPS */, ["modelValue", "is-switzerland", "errors"])]), _cache[14] || (_cache[14] = _createElementVNode("hr", {
        "class": "m-0"
      }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_5, [_createVNode(PersonOrigins, {
        modelValue: _unref(internalNatPerson).origins,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return _unref(internalNatPerson).origins = $event;
        }),
        "person-form-prefix": "nat_person_form",
        token: _unref(internalNatPerson).token,
        "is-e-signature": _ctx.isESignature,
        errors: (_ctx$errors5 = _ctx.errors) === null || _ctx$errors5 === void 0 ? void 0 : _ctx$errors5.origins
      }, null, 8 /* PROPS */, ["modelValue", "token", "is-e-signature", "errors"])]), _cache[15] || (_cache[15] = _createElementVNode("hr", {
        "class": "m-0"
      }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_6, [_createElementVNode("h5", {
        "class": _normalizeClass(["collapsible my-4", {
          collapsed: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors6 = _ctx.errors) === null || _ctx$errors6 === void 0 ? void 0 : _ctx$errors6.phoneNumber) !== ''
        }]),
        "data-bs-toggle": "collapse",
        "data-bs-target": "#collapseESignature"
      }, [_createTextVNode(_toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'sections.e_signature_data')) + " ", 1 /* TEXT */), _cache[11] || (_cache[11] = _createElementVNode("i", {
        "class": "fas fa-chevron-up"
      }, null, -1 /* HOISTED */))], 2 /* CLASS */), _createElementVNode("div", {
        id: "collapseESignature",
        "class": _normalizeClass(["collapse py-2", {
          show: !!((_ctx$errors7 = _ctx.errors) !== null && _ctx$errors7 !== void 0 && _ctx$errors7.phoneNumber)
        }])
      }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(TextField, {
        modelValue: _unref(internalNatPerson).phoneNumber,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return _unref(internalNatPerson).phoneNumber = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors8 = _ctx.errors) === null || _ctx$errors8 === void 0 ? void 0 : _ctx$errors8.phoneNumber),
        name: "phoneNumber",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.phone_number'),
        autocomplete: "phone-number"
      }, null, 8 /* PROPS */, ["modelValue", "error", "label"]), _cache[12] || (_cache[12] = _createElementVNode("div", {
        "class": "d-flex justify-content-end mt-n3 mb-3"
      }, [_createElementVNode("span", {
        "class": "is-qes-certified"
      })], -1 /* HOISTED */))])]), _createVNode(TextField, {
        modelValue: _unref(internalNatPerson).email,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
          return _unref(internalNatPerson).email = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors9 = _ctx.errors) === null || _ctx$errors9 === void 0 ? void 0 : _ctx$errors9.email),
        name: "email",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.email'),
        autocomplete: "email"
      }, null, 8 /* PROPS */, ["modelValue", "error", "label"]), _createVNode(SignatureImage, {
        token: _unref(internalNatPerson).token,
        "e-sign-url": _ctx.eSignUrl,
        "file-token": _ctx.fileToken
      }, null, 8 /* PROPS */, ["token", "e-sign-url", "file-token"])], 2 /* CLASS */)]), _cache[16] || (_cache[16] = _createElementVNode("hr", {
        "class": "m-0"
      }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_9, [_createElementVNode("h5", {
        "class": _normalizeClass(["collapsible my-4", {
          collapsed: _unref(optionalDataValid)
        }]),
        "data-bs-toggle": "collapse",
        "data-bs-target": "#collapseOptionalFields"
      }, [_createTextVNode(_toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'sections.optional_data')) + " ", 1 /* TEXT */), _cache[13] || (_cache[13] = _createElementVNode("i", {
        "class": "fas fa-chevron-up"
      }, null, -1 /* HOISTED */))], 2 /* CLASS */), _createElementVNode("div", {
        id: "collapseOptionalFields",
        "class": _normalizeClass(["collapse py-2", {
          show: !_unref(optionalDataValid)
        }])
      }, [_createVNode(TextField, {
        modelValue: _unref(internalNatPerson).academicTitle,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return _unref(internalNatPerson).academicTitle = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors10 = _ctx.errors) === null || _ctx$errors10 === void 0 ? void 0 : _ctx$errors10.academicTitle),
        name: "academicTitle",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.academic_title'),
        autocomplete: "academic-title"
      }, null, 8 /* PROPS */, ["modelValue", "error", "label"]), _createElementVNode("div", _hoisted_10, [_createVNode(AcademicTitleImage, {
        token: _unref(internalNatPerson).token
      }, null, 8 /* PROPS */, ["token"])]), _createVNode(DateInput, {
        modelValue: _unref(internalNatPerson).birthdayDate,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
          return _unref(internalNatPerson).birthdayDate = $event;
        }),
        title: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.birthday_date')
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(DropdownField, {
        modelValue: _unref(internalNatPerson).sex,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
          return _unref(internalNatPerson).sex = $event;
        }),
        name: "country",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.sex'),
        autocomplete: "sex"
      }, {
        options: _withCtx(function () {
          return [_createElementVNode("option", _hoisted_11, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'choices.sex.male')), 1 /* TEXT */), _createElementVNode("option", _hoisted_12, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'choices.sex.female')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "label"]), _createVNode(TextField, {
        modelValue: _unref(internalNatPerson).streetAddress,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
          return _unref(internalNatPerson).streetAddress = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors11 = _ctx.errors) === null || _ctx$errors11 === void 0 ? void 0 : _ctx$errors11.streetAddress),
        name: "streetAddress",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('nat_person', 'fields.street_address'),
        autocomplete: "street-address"
      }, null, 8 /* PROPS */, ["modelValue", "error", "label"])], 2 /* CLASS */)])]));
    };
  }
});