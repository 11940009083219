function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import EmployeesView from './EmployeesView.vue';
import TabNav from '../TabNav.vue';
import InvoicesView from './InvoicesView.vue';
import BalanceView from './BalanceView.vue';
import InfoView from '@/components/companyView/InfoView.vue';
import ApiKeysView from '@/components/companyView/ApiKeysView.vue';

import { ref, computed, unref } from 'vue';
import { translate } from '/var/node/assets/js/utils/translate.ts';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'CompanyView'
}), {}, {
  __name: 'CompanyView',
  props: {
    hasFreeProcess: {
      type: Boolean,
      "default": false
    },
    permissions: {
      "default": function _default() {
        return [];
      }
    },
    isAdmin: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var activeNavItemIndex = ref(0);
    var shouldDisplayApiKeyTab = computed(function () {
      var _props$permissions;
      return ((_props$permissions = props.permissions) === null || _props$permissions === void 0 ? void 0 : _props$permissions.includes('create_api_users')) && props.isAdmin;
    });
    var navItems = computed(function () {
      return [{
        component: EmployeesView,
        label: translate('trustee', 'navigation.employees')
      }].concat(_toConsumableArray(unref(shouldDisplayApiKeyTab) ? [{
        component: ApiKeysView,
        label: translate('trustee.navigation.api')
      }] : []), [{
        component: InvoicesView,
        label: translate('admin', 'invoice.label')
      }, {
        component: BalanceView,
        label: translate('admin', 'money.balance')
      }, {
        component: InfoView,
        label: translate('trustee', 'navigation.details')
      }]);
    });
    var navItemTitles = computed(function () {
      return navItems.value.map(function (item) {
        return item.label;
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(TabNav, {
        "nav-items": _unref(navItemTitles),
        onSelectedItemIndex: _cache[0] || (_cache[0] = function (index) {
          return activeNavItemIndex.value = index;
        })
      }, null, 8 /* PROPS */, ["nav-items"]), (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navItems)[_unref(activeNavItemIndex)].component), {
        "has-free-process": _ctx.hasFreeProcess
      }, null, 8 /* PROPS */, ["has-free-process"]))], 64 /* STABLE_FRAGMENT */);
    };
  }
}));