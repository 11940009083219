import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue";
var _hoisted_1 = {
  "class": "content-wrapper"
};
var _hoisted_2 = {
  "class": "content col-md-16"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  "class": "my-4"
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  key: 0,
  "class": "px-2"
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  "class": "mt-4"
};
var _hoisted_9 = {
  "class": "form-footer"
};
var _hoisted_10 = {
  "class": "mb-2"
};
var _hoisted_11 = ["href"];
var _hoisted_12 = ["innerHTML"];
import axios from 'axios';
import { getLocalizedUrl } from '@/js/frontend/helpers';
import { currencyCHF } from '@/js/frontend/filters';

import { ref, onMounted } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceView',
  props: {
    hasFreeProcess: {
      type: Boolean
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var companyName = ref('');
    var balance = ref(0);
    onMounted(function () {
      if (!props.hasFreeProcess) {
        axios.get('/trustee/balance').then(function (response) {
          companyName.value = response.data.company_name;
          balance.value = response.data.balance;
        });
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.hasFreeProcess ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("h4", _hoisted_4, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('trustee', 'view_disabled')), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("h4", null, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'payment.right_text', {
        '%trustee_name%': _unref(companyName)
      })), 1 /* TEXT */), _unref(companyName) === '' ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _cache[0] || (_cache[0] = [_createElementVNode("i", {
        "class": "fa fa-spinner-third fa-spin"
      }, null, -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true), _unref(companyName) !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("h3", _hoisted_8, _toDisplayString(_unref(currencyCHF)(_unref(balance))), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'payment.increase_credit.title')), 1 /* TEXT */), _createElementVNode("a", {
        "class": "btn btn-primary",
        target: "_blank",
        rel: "noopener noreferrer",
        href: _unref(getLocalizedUrl)('trustee/custom/balance/form')
      }, _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'payment.increase_credit.activate_button')), 9 /* TEXT, PROPS */, _hoisted_11), _createElementVNode("p", {
        "class": "mt-6 mb-0",
        innerHTML: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('process', 'payment.increase_credit.info')
      }, null, 8 /* PROPS */, _hoisted_12)])]))])]);
    };
  }
});