function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";

import { ref, onMounted } from 'vue';
var _hoisted_1 = {
  "class": "tab-nav"
};
var _hoisted_2 = ["onClick"];
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'TabNav'
}), {}, {
  __name: 'TabNav',
  props: {
    navItems: {
      "default": function _default() {
        return [];
      }
    },
    startIndex: {
      "default": 0
    }
  },
  emits: ['selectedItemIndex'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var activeItemIndex = ref(0);
    onMounted(function () {
      activeItemIndex.value = props.startIndex;
    });
    var setActiveItemIndex = function setActiveItemIndex(newIndex) {
      activeItemIndex.value = newIndex;
      emit('selectedItemIndex', newIndex);
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, function (navItem, index) {
        return _openBlock(), _createElementBlock("h4", {
          key: index,
          "class": _normalizeClass({
            active: index === _unref(activeItemIndex)
          })
        }, [_createElementVNode("a", {
          onClick: function onClick($event) {
            return setActiveItemIndex(index);
          }
        }, _toDisplayString(navItem), 9 /* TEXT, PROPS */, _hoisted_2)], 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */))]);
    };
  }
}));