import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'TableActionButton',
  props: {
    value: {
      type: Boolean
    },
    cell: {},
    meta: {}
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _ctx$meta, _ctx$meta2;
      var _component_DynLink = _resolveComponent("DynLink");
      var _directive_tooltip = _resolveDirective("tooltip");
      return _ctx.meta.to || _ctx.meta.onClick ? _withDirectives((_openBlock(), _createBlock(_component_DynLink, {
        key: 0,
        to: _ctx.meta.to,
        "aria-label": _ctx.meta.ariaLabel,
        download: _ctx.meta.download,
        type: "button",
        "class": _normalizeClass(["inline-flex justify-center items-center aspect-square shrink-0 w-8 rounded transition-colors", {
          'bg-blue-light hover:bg-blue-hover': !((_ctx$meta = _ctx.meta) !== null && _ctx$meta !== void 0 && _ctx$meta.level) || _ctx.meta.level === 'primary',
          'bg-red-light hover:bg-red-hover': ((_ctx$meta2 = _ctx.meta) === null || _ctx$meta2 === void 0 ? void 0 : _ctx$meta2.level) === 'danger'
        }]),
        onClick: _ctx.meta.onClick
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("i", {
            "class": _normalizeClass(_ctx.meta.icon)
          }, null, 2 /* CLASS */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to", "aria-label", "download", "class", "onClick"])), [[_directive_tooltip, _ctx.meta.tooltip]]) : _createCommentVNode("v-if", true);
    };
  }
});