import { readonly as __unimport_readonly, unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, unref as _unref, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  "class": "form-group"
};
var _hoisted_2 = ["for"];
var _hoisted_3 = ["name", "type", "readonly", "disabled", "placeholder", "required"];
var _hoisted_4 = {
  key: 0,
  "class": "invalid-feedback block"
};
import Tooltip from '@/components/general/Tooltip.vue';

import { useFormErrorMessage } from '/var/node/assets/js/composables/useFormErrorMessage.ts';
import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  inheritAttrs: false
}), {}, {
  __name: 'TextField',
  props: /*@__PURE__*/_mergeModels({
    name: {},
    label: {},
    error: {},
    readonly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    alwaysShowError: {
      type: Boolean
    },
    placeholder: {},
    tooltip: {},
    type: {},
    required: {
      type: Boolean
    }
  }, {
    "modelValue": {},
    "modelModifiers": {}
  }),
  emits: ["update:modelValue"],
  setup: function setup(__props) {
    var props = __props;
    var modelValue = _useModel(__props, "modelValue");
    var _useFormErrorMessage = useFormErrorMessage({
        name: computed(function () {
          return props.name;
        }),
        error: computed(function () {
          return props.error;
        }),
        watch: modelValue,
        disableHide: computed(function () {
          return props.alwaysShowError;
        })
      }),
      errorMessage = _useFormErrorMessage.message;
    return function (_ctx, _cache) {
      var _ctx$type, _ctx$required;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", {
        "for": _ctx.name
      }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_2), _ctx.tooltip ? (_openBlock(), _createBlock(Tooltip, {
        key: 0,
        title: _ctx.tooltip.title,
        text: _ctx.tooltip.text
      }, null, 8 /* PROPS */, ["title", "text"])) : _createCommentVNode("v-if", true), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return modelValue.value = $event;
        }),
        name: _ctx.name,
        type: (_ctx$type = _ctx.type) !== null && _ctx$type !== void 0 ? _ctx$type : 'text',
        "class": _normalizeClass(["form-control", {
          errorMessage: 'is-invalid'
        }]),
        readonly: ("readonly" in _ctx ? _ctx.readonly : __unimport_unref_(__unimport_readonly)),
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        required: (_ctx$required = _ctx.required) !== null && _ctx$required !== void 0 ? _ctx$required : false
      }, null, 8 /* PROPS */, _hoisted_3), [[_vModelDynamic, modelValue.value]]), _createVNode(_Transition, {
        name: "appear"
      }, {
        "default": _withCtx(function () {
          return [_unref(errorMessage) ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(errorMessage)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      })]);
    };
  }
}));