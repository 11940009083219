import { update, get } from 'lodash-es';
import { Violation } from '@/interfaces/Violation';
import { ValidationErrors } from '@/interfaces/ValidationError';

export function createNestedErrors<TEntity extends object>(
  violations: Violation[],
  key: keyof Violation = 'propertyPath'
): ValidationErrors<TEntity> {
  return violations.reduce((acc, violation) => {
    update(acc, violation[key] ? `${violation[key]}[@general]` : '@general', (v) =>
      v ? [...v, violation.message] : [violation.message]
    );

    return acc;
  }, {} as NonNullable<ValidationErrors<TEntity>>);
}

export function getErrors<TEntity extends object>(
  errors?: ValidationErrors<TEntity>,
  path?: string
): string[] {
  if (!errors) {
    return [];
  }

  const errs = path ? get(errors, path) : errors;

  if (typeof errs === 'string') {
    return [errs];
  }

  const generalErrors =
    typeof errs['@general'] === 'string' ? [errs['@general']] : errs['@general'];

  return generalErrors ?? [];
}

export function getAllErrorsFlat<TEntity extends object>(
  errors?: ValidationErrors<TEntity>
): string[] {
  if (!errors) {
    return [];
  }

  return Object.values(errors).reduce<string[]>((acc, value) => {
    if (typeof value === 'string') {
      return [...acc, value];
    }

    return [...acc, ...getAllErrorsFlat(value)];
  }, []);
}

export function getFirstError<TEntity extends object>(
  errors?: ValidationErrors<TEntity> | string,
  path?: string
) {
  if (typeof errors === 'string') {
    return errors;
  }

  const error = getErrors(errors, path);
  return error?.[0];
}
