function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { reactive, onMounted } from 'vue';
import { translate } from '/var/node/assets/js/utils/translate.ts';
import { useBrowserLocation } from '@vueuse/core';
var _hoisted_1 = {
  "class": "h-screen flex flex-column justify-center"
};
var _hoisted_2 = {
  "class": "text-2xl font-bold"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  "class": "mt-4 w-48"
};
var _hoisted_5 = ["href"];
export default /*@__PURE__*/_defineComponent(_objectSpread(_objectSpread({}, {
  name: 'ErrorPage'
}), {}, {
  __name: 'ErrorPage',
  props: {
    httpCode: {},
    returnUrl: {}
  },
  setup: function setup(__props) {
    var props = __props;
    var pageContent = reactive({
      title: '',
      content: ''
    });
    var btnToDashboard = translate('errors.elements.buttons.to_dashboard');
    var getHttpCodeErrorString = function getHttpCodeErrorString(key) {
      var translationKey = "errors.page.error".concat(props.httpCode, ".").concat(key);
      var translated = translate(translationKey);
      // If the translated string is different from the key, it means a translated
      // string was found. Otherwise, we display a generic message as fallback.
      return translated !== translationKey ? translated : translate("errors.page.error.".concat(key));
    };
    var browserLocation = useBrowserLocation();
    onMounted(function () {
      var _browserLocation$valu;
      pageContent.title = getHttpCodeErrorString('title');
      pageContent.content = getHttpCodeErrorString('message').replace('%url%', props.returnUrl).replace('%target%', (_browserLocation$valu = browserLocation.value.pathname) !== null && _browserLocation$valu !== void 0 ? _browserLocation$valu : '');
    });
    return function (_ctx, _cache) {
      var _component_AppButton = _resolveComponent("AppButton");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.httpCode), 1 /* TEXT */), _createElementVNode("h1", null, _toDisplayString(_unref(pageContent).title), 1 /* TEXT */), _cache[0] || (_cache[0] = _createElementVNode("div", {
        "class": "bg-blue-dark h-1 w-10 rounded mt-4 mb-4"
      }, null, -1 /* HOISTED */)), _createElementVNode("p", {
        innerHTML: _unref(pageContent).content
      }, null, 8 /* PROPS */, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("a", {
        href: _ctx.returnUrl
      }, [_createVNode(_component_AppButton, {
        size: "medium"
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_unref(btnToDashboard)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })], 8 /* PROPS */, _hoisted_5)])]);
    };
  }
}));